import { Injectable } from '@angular/core';
import { WebhookPayloadDefaultParams as DefaultParams } from '@app/models/webhook-payload-default-params.class';
import { WebhookPayload } from '@app/models/webhook-payload.interface';
import { WebhookPayloadsService } from '@lib/core';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WebhookPayloadHelperService {

  constructor(
    private service: WebhookPayloadsService,
  ) { }
  getByWebhookId(webhookId: number): Observable<WebhookPayload[]> {
    let params = <any>new DefaultParams();
    params.webhookId = 'eq.' + webhookId;
    params.limit = 25;
    params.order = 'created_at.desc';
    return this.service.webhookPayloadsGet(params)
      .pipe(map(x => <WebhookPayload[]>x));
  }
}
