<head-meta title="Login"></head-meta>
<div class="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
  <div class="sm:mx-auto sm:w-full sm:max-w-md">
    <img class="mx-auto h-20 w-auto" src="/assets/arborsense.png"
      alt="Arborsense">
  </div>
  <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
    <div class="card w-full bg-base-100 shadow-xl">
      <div class="card-body">
        <form class="space-y-6" [formGroup]="this.loginForm">
          <div>
            <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Email address</label>
            <div class="mt-2">
              <input formControlName="email" id="email" name="email" type="email" autocomplete="email"
                class="input input-bordered input-secondary w-full">
            </div>
          </div>
          <div>
            <label for="password" class="block text-sm font-medium leading-6 text-gray-900">Password</label>
            <div class="mt-2">
              <input formControlName="password" id="password" name="password" type="password" autocomplete="current-password"
                class="input input-bordered input-secondary w-full">
            </div>
          </div>
          <div>
            <button type="submit" [disabled]="!this.loginForm.valid" (click)="this.submit()" class="flex w-full justify-center btn btn-primary">Sign in</button>
          </div>
          <div class="mt-4 text-center">
            <div class="text-sm leading-6">
              <a (click)="this.navtoForgot()" class="link link-accent">Forgot password?</a>
            </div>
          </div>
        </form>
      </div>
    </div>
    <p class="mt-10 text-center text-sm text-gray-500">
      &copy;{{ this.date }} <a href="https://arborsense.com/" class="link link-primary link-hover" target="_blank">Arborsense, Inc</a>. All rights reserved.
      <br />
      <small class="my-5 block text-[#ccc]">v{{this.version}}</small>
    </p>
  </div>
</div>
<app-toast #messageToast type="success" />