import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LoadingErrorBlockComponent } from '@app/components/loading-error-block/loading-error-block.component';
import { HeadMetaComponent } from '@app/components/ui.component';
import { SequenceEvent } from '@app/models/sequence-event.interface';
import { AuthService } from '@app/services/auth.service';
import { SequenceEventHelperService } from '@app/services/helpers/sequence-event-helper.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { 
  faCirclePlus,
  faArrowLeft 
 } from '@fortawesome/free-solid-svg-icons';
import { LetDirective } from '@ngrx/component';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-sequence-events',
  standalone: true,
  imports: [
    HeadMetaComponent,
    LetDirective,
    LoadingErrorBlockComponent,
    DatePipe,
    FontAwesomeModule,
    RouterModule,
  ],
  templateUrl: './listing.page.html',
  styleUrl: './listing.page.scss'
})
export class SequenceEventsPage {
  public title = 'Sequence Events';
  sequenceEvents$: Observable<SequenceEvent[]>;

  faCirclePlus = faCirclePlus;
  faArrowLeft = faArrowLeft;

  constructor(
    private sequenceEventHelper: SequenceEventHelperService,
    public auth: AuthService,
  ) {
    this.sequenceEvents$ = this.sequenceEventHelper.getAll();
  }
}
