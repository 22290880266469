import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LoadingErrorBlockComponent } from '@app/components/loading-error-block/loading-error-block.component';
import { HeadMetaComponent } from '@app/components/ui.component';
import { User } from '@app/models/user.interface';
import { AuthService } from '@app/services/auth.service';
import { UserHelperService } from '@app/services/helpers/user-helper.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { 
  faCirclePlus,
  faArrowLeft 
 } from '@fortawesome/free-solid-svg-icons';
import { LetDirective } from '@ngrx/component';
import { Observable } from 'rxjs';
import { HelpButtonComponent } from "@app/components/help-button/help-button.component";

@Component({
  selector: 'app-users',
  standalone: true,
  imports: [
    HeadMetaComponent,
    LetDirective,
    LoadingErrorBlockComponent,
    DatePipe,
    FontAwesomeModule,
    RouterModule,
    HelpButtonComponent
],
  templateUrl: './listing.page.html',
  styleUrl: './listing.page.scss'
})
export class UsersPage {
  public title = 'Users';
  users$: Observable<User[]>;

  faCirclePlus = faCirclePlus;
  faArrowLeft = faArrowLeft;

  constructor(
    private userHelper: UserHelperService,
    public auth: AuthService,
  ) {
    this.users$ = this.userHelper.getAll();
  }
}
