

<div class="overflow-hidden bg-white shadow sm:rounded-lg mb-4">
    <div class="border-t border-gray-100">
        <form [formGroup]="firmwareForm" (input)="onChange()" (change)="onChange()">
            <div class="divide-y divide-gray-100">
                <div>
                    <app-input-field
                        controlName="name"
                        [form]="firmwareForm"
                        label="Firmware Name"
                        [errorMessages]="{ required: 'Firmware name is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="firmware_version"
                        [form]="firmwareForm"
                        label="Firmware Version"
                        [errorMessages]="{ required: 'Firmware Version is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="s3_path"
                        [form]="firmwareForm"
                        label="S3 Path"
                        [errorMessages]="{ required: 'S3 Path is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="version_id"
                        type="select|versions"
                        [form]="firmwareForm"
                        label="Version"
                        [errorMessages]="{ required: 'Version is required'}"
                    />
                </div>
            </div>
        </form>
    </div>
</div>