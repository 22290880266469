import { DatePipe } from '@angular/common';
import { Component, input } from '@angular/core';
import { DetailDateDisplayComponent } from "../detail-date-display/detail-date-display.component";

@Component({
  selector: 'app-created-updated-display',
  standalone: true,
  imports: [
    DetailDateDisplayComponent
],
  templateUrl: './created-updated-display.component.html',
  styleUrl: './created-updated-display.component.scss'
})
export class CreatedUpdatedDisplayComponent {
  entity = input.required<{created_at?: string, updated_at?: string}>();

}
