

<div class="overflow-hidden bg-white shadow sm:rounded-lg mb-4">
    <div class="border-t border-gray-100">
        <form [formGroup]="versionForm" (input)="onChange()" (change)="onChange()">
            <div class="divide-y divide-gray-100">
                <div>
                    <app-input-field
                        controlName="name"
                        [form]="versionForm"
                        label="Version Name"
                        [errorMessages]="{ required: 'Version name is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="algorithm_id"
                        type="select|algorithms"
                        [form]="versionForm"
                        label="Algorithm"
                        [errorMessages]="{ required: 'Algorithm is required'}"
                    />
                </div>
                <!--
                <div>
                    <app-input-field
                        controlName="latest_firmware_id"
                        type="select|firmware"
                        [form]="versionForm"
                        label="Latest Firmware"
                        [errorMessages]="{ required: 'Latest Firmware is required'}"
                    />
                </div>
                -->
                <div>
                    <app-input-field
                        controlName="minimum_version"
                        type="number"
                        step="1"
                        [form]="versionForm"
                        label="Minimum Version"
                        [errorMessages]="{ required: 'Minimum Version is required'}"
                    />
                </div>
            </div>
        </form>
    </div>
</div>