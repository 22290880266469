<head-meta title="Login"></head-meta>
<div class="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
  <div class="sm:mx-auto sm:w-full sm:max-w-md">
    <img class="mx-auto h-20 w-auto" src="/assets/arborsense.png"
      alt="Arborsense">
  </div>
  <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
    <div class="card w-full bg-base-100 shadow-xl">
      <div class="card-body">
        <form class="space-y-6" [formGroup]="this.forgotForm">
          <div>
            <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Email address</label>
            <div class="mt-2">
              <input formControlName="email" id="email" name="email" type="email" autocomplete="email"
                class="input input-bordered input-secondary w-full">
            </div>
          </div>
          <div>
            <button type="submit" [disabled]="!this.forgotForm.valid" (click)="this.submit()" class="flex w-full justify-center btn btn-primary">Send Email</button>
          </div>
          <div class="mt-4 text-center">
            <div class="text-sm leading-6">
              <a (click)="this.navToLogin()" class="link link-accent">Back to Login</a>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<app-toast #messageToast type="success" />