import { Component, input, output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormBuilder } from '@angular/forms';
import { InputFieldComponent } from '@app/components/input-field/input-field.component';
import { UserRoleSelection } from '@app/models/user.interface';
import { toObservable } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-user-role-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    InputFieldComponent,
    CommonModule,
  ],
  templateUrl: './form.component.html',
  styleUrl: './form.component.scss'
})
export class UserRoleFormComponent {
  initialValue = input<any>();
  formChange = output<any>();
  formValid = output<boolean>();
  options:UserRoleSelection[] = [];

  userRoleFormBuilder = new FormBuilder();
  userRoleForm = this.userRoleFormBuilder.group({
    role_ids: this.userRoleFormBuilder.array([])
  });

  private selectedRoles: number[] = [];

  constructor() {
    toObservable(this.initialValue).subscribe((initial) => {
      if(initial) {
        this.options = initial;
        this.options.forEach(o => {
          if(o.enabled){
            this.selectedRoles.push(o.id);
          }
        })
      }
      this.onChange();
    });
  }

  onCheck(e:any, id:number){
    if(e.target.checked){
      this.selectedRoles.push(id);
    } else {
      this.selectedRoles = this.selectedRoles.filter(item => item !== id)
    }
  }

  onChange() {
    this.formValid.emit(this.userRoleForm.valid);
    this.formChange.emit({role_ids: this.selectedRoles});
  }
}