<head-meta [title]="this.title" />
<article>
    <div class="card w-full bg-base-100 shadow-xl">
        <div class="card-body">
            <ng-container *ngrxLet="tamperEvent$ as tamperEvent; error as e; complete as c">
                @if(tamperEvent) {
                    <div class="navbar bg-base-100">
                        <div class="flex-none">
                          <a routerLink="/tamper-events" routerLinkActive="active" class="btn btn-square btn-ghost text-primary"><fa-icon [icon]="faArrowLeft" class="w-5"></fa-icon></a>
                        </div>
                        <div class="flex-1">
                          <h1 class="h1 ml-4">Tamper Event Detail</h1>
                        </div>
                        <div class="flex-none">
                            @if(this.auth.has('device_status_update')) {
                                <button [routerLink]="'/tamper-events/' + this.id + '/edit'" routerLinkActive="active" class="btn btn-ghost text-primary">
                                    <fa-icon [icon]="faPenToSquare" class="w-5"></fa-icon> Edit
                                </button>
                            }
                        </div>
                    </div>
                    <hr class="hr" />
                    <div class="overflow-hidden bg-white shadow sm:rounded-lg">
                        <div class="border-t border-gray-100">
                            <dl class="divide-y divide-gray-100">
                                <div>
                                    <app-detail-display title="Sequence Id" [value]="tamperEvent.sequence_id.toString()" />
                                </div>
                                <!--
                                    event_id
                                -->
                                <!--
                                    sensor_type_id
                                -->
                                <div>
                                    <app-detail-display title="Event number" [value]="tamperEvent.event_number?.toString() ?? ''" />
                                </div>
                                <div>
                                    <app-detail-display title="Started at" [value]="(tamperEvent.started_at | date: 'medium') ?? ''" />
                                </div>
                                <div>
                                    @if(tamperEvent.ended_at != null){
                                        <app-detail-display title="Ended at" [value]="(tamperEvent.ended_at | date: 'medium') ?? ''" />
                                    } @else {
                                        <app-detail-display title="Ended at" value="" />
                                    }
                                </div>
                                <div>
                                    <app-detail-display title="Baseline data value" [value]="tamperEvent.baseline_data_value?.toString() ?? ''" />
                                </div>
                                <div>
                                    <app-detail-display title="Average delta change" [value]="tamperEvent.average_delta_change?.toString() ?? ''" />
                                </div>
                                <div>
                                    <app-detail-display title="Duration minutes" [value]="tamperEvent.duration_minutes?.toString() ?? ''" />
                                </div>
                                <div>
                                    <app-detail-display title="Out of range?" [value]="tamperEvent.out_of_range ? 'Yes' : 'No'" />
                                </div>
                                <div>
                                    <app-detail-display title="Standard dev" [value]="tamperEvent.standard_dev?.toString() ?? ''" />
                                </div>
                                <div>
                                    <app-detail-display title="Battery decay slope" [value]="tamperEvent.battery_decay_slope?.toString() ?? ''" />
                                </div>
                                <div>
                                    <app-detail-display title="Battery percentage" [value]="tamperEvent.battery_percentage?.toString() ?? ''" />
                                </div>
                                <div>
                                    <app-created-updated-display [entity]="tamperEvent" />
                                </div>
                            </dl>
                        </div>
                    </div>
                }
                <app-loading-error-block [error]="e?.error" [complete]="c" />
            </ng-container>
        </div>
    </div>
</article>