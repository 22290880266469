import { Injectable } from '@angular/core';
import { DeviceStatusDefaultParams as DefaultParams } from '@app/models/device-status-default-params.class';
import { DeviceStatus, DeviceStatusCreate } from '@app/models/device-status.interface';
import { DeviceStatusesService } from '@lib/core';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DeviceStatusHelperService {

  constructor(
    private service: DeviceStatusesService,
  ) { }

  getAll(): Observable<DeviceStatus[]> {
    let params = new DefaultParams();
    return this.service.deviceStatusesGet(params)
      .pipe(map(x => <DeviceStatus[]>x));
  }
  getById(id: number): Observable<DeviceStatus> {
    let params = <any>new DefaultParams();
    params.id = 'eq.' + id;
    return this.service.deviceStatusesGet(params)
      .pipe(map(x => <DeviceStatus>x[0]));
  }
  create(input: DeviceStatusCreate) {
    return this.service.deviceStatusesPost({
      deviceStatuses: <any>input,
      prefer: "return=representation"
    }, 'response')
    .pipe(map(response => response.body[0]))
    .pipe(map(body => {
      //console.log(body)
      return (<DeviceStatus>body).id;
    }));
  }
  update(id: number, input: DeviceStatusCreate) {
    return this.service.deviceStatusesPatch({
      id: 'eq.'+id,
      deviceStatuses: <any>input
    }, 'response')
    .pipe(map(response => response.ok));
  }
}
