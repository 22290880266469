

<div class="overflow-hidden bg-white shadow sm:rounded-lg mb-4">
    <div class="border-t border-gray-100">
        <form [formGroup]="deviceForm" (input)="onChange()" (change)="onChange()">
            <div class="divide-y divide-gray-100">
                <div>
                    <app-input-field
                        controlName="serial_number"
                        [form]="deviceForm"
                        label="Serial Number"
                        [errorMessages]="{ required: 'Serial Number is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="device_status_id"
                        type="select|device_statuses"
                        [form]="deviceForm"
                        label="Status"
                        [errorMessages]="{ required: 'Status is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="provider_id"
                        type="select|providers"
                        [form]="deviceForm"
                        label="Provider"
                        [errorMessages]="{ required: 'Provider is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="sensor"
                        [form]="deviceForm"
                        label="Sensor"
                        [errorMessages]="{ required: 'Sensor is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="primary_pcb"
                        [form]="deviceForm"
                        label="Primary PCB"
                        [errorMessages]="{ required: 'Primary PCB is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="pin"
                        [form]="deviceForm"
                        label="Pin"
                        [errorMessages]="{ required: 'Pin is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="version_id"
                        type="select|versions"
                        [form]="deviceForm"
                        label="Version"
                        [errorMessages]="{ required: 'Version is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="slope_e_test"
                        [form]="deviceForm"
                        type="number"
                        step="1"
                        label="Slope E Test"
                        [errorMessages]="{ required: 'Slope E Test is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="baseline_voltage"
                        [form]="deviceForm"
                        type="number"
                        step="0.000001"
                        label="Baseline Voltage"
                        [errorMessages]="{ required: 'Baseline Voltage is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="active"
                        type="toggle"
                        [form]="deviceForm"
                        label="Active?"
                    />
                </div>
            </div>
        </form>
    </div>
</div>