import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VersionService {

  constructor() { }

  getVersion() {
    return '2.2';
  }

  getYear() {
    let currentYear = new Date().getFullYear().toString();
    return '2021-'+currentYear.substring(currentYear.length - 2);
  }
}
