<head-meta title="Arborsense API Documentation"></head-meta>
<article>
    <div class="card w-full bg-base-100 shadow-xl">
        <div class="card-body">
            <h1 class="h1">
                Arborsense API Documentation
                <span><api-version current_selection="v1"></api-version></span>
            </h1>
            <hr class="hr" />
        
            <div class="join join-vertical w-full">
                <div class="collapse collapse-arrow join-item border-base-300 border">
                    <input type="radio" name="docs" checked="checked" />
                    <div class="collapse-title text-xl font-medium">Authentication</div>
                    <div class="collapse-content">
                        <p>Arborsense uses <a href="https://github.com/supabase/auth" target="_blank" class="text-primary">Supabase Auth</a> <span class="ml-0.5 badge badge-primary badge-outline">v2.x</span> that is compatible with Postgres and the row-level security model.</p>
                        <div class="mockup-code bg-primary mt-2">
                            <pre data-prefix=">" class="text-white"><code>POST {{authUrl}}/token?grant_type=password</code></pre>
                            <pre class="text-white"><code>&#123;</code></pre>
                            <pre class="text-white"><code>"email": "user&#64;example.com",</code></pre>
                            <pre class="text-white"><code>"password": "..."</code></pre>
                            <pre class="text-white"><code>&#125;</code></pre>
                            <pre data-prefix="//" class="text-accent"><code>Return JSON payload</code></pre>
                            <pre class="text-gray-400"><code>&#123;</code></pre>
                            <pre class="text-gray-400"><code>...</code></pre>
                            <pre class="text-gray-400"><code>"access_token": "...",</code></pre>
                            <pre class="text-gray-400"><code>"refresh_token": "...",</code></pre>
                            <pre class="text-gray-400"><code>"expires_at": "{{this.now.getTime()}}",</code></pre>
                            <pre class="text-gray-400"><code>...</code></pre>
                            <pre class="text-gray-400"><code>&#125;</code></pre>
                        </div>
                        <p class="mt-2">An `access_token` expires at `expires_at` (unix timestamp), so a `refresh_token` should be used to continue making authenticated requests.</p>
                        <div class="mockup-code bg-primary mt-2">
                            <pre data-prefix=">" class="text-white"><code>POST {{authUrl}}/token?grant_type=refresh_token</code></pre>
                            <pre class="text-white"><code>&#123;</code></pre>
                            <pre class="text-white"><code>"refresh_token": "...",</code></pre>
                            <pre class="text-white"><code>&#125;</code></pre>
                            <pre data-prefix="//" class="text-accent"><code>Return JSON payload</code></pre>
                            <pre class="text-gray-400"><code>&#123;</code></pre>
                            <pre class="text-gray-400"><code>...</code></pre>
                            <pre class="text-gray-400"><code>"access_token": "new",</code></pre>
                            <pre class="text-gray-400"><code>"refresh_token": "new",</code></pre>
                            <pre class="text-gray-400"><code>"expires_at": "{{this.nowNext.getTime()}}",</code></pre>
                            <pre class="text-gray-400"><code>...</code></pre>
                            <pre class="text-gray-400"><code>&#125;</code></pre>
                        </div>
                        <div role="alert" class="alert alert-warning mt-2">
                            <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-6 w-6 shrink-0 stroke-current"
                            fill="none"
                            viewBox="0 0 24 24">
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                            </svg>
                            <span>New users &amp; password recovery/reset must be done using the Arborsense Cloud UI/API directly, not the Auth API.</span>
                        </div>
                        <div role="alert" class="alert mt-2">
                            <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            class="stroke-current h-6 w-6 shrink-0">
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                            </svg>
                            <span>For more details reference official documentation</span>
                            <div>
                                <a href="https://github.com/supabase/auth" target="_blank" class="btn btn-sm btn-primary">Go to docs</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="collapse collapse-arrow join-item border-base-300 border">
                    <input type="radio" name="docs" />
                    <div class="collapse-title text-xl font-medium">API &amp; Models</div>
                    <div class="collapse-content">
                        <div id="swagger-ui" class="mt-2"></div>
                    </div>
                </div>
                <div class="collapse collapse-arrow join-item border-base-300 border">
                    <input type="radio" name="docs" />
                    <div class="collapse-title text-xl font-medium">Push Tokens <span class="badge badge-neutral">internal</span></div>
                    <div class="collapse-content">
                        <p class="mb-3">Arborsense has a utility API for registering push tokens. Currently this is supported for internal use only. The `unique_id` provided to this endpoint should match `phones.unique_id`.</p>
                        <p class="mb-3">As with other endpoints in Arborsense, you must provide a valid access_token in the Authorization header to make requests.</p>
                        <div class="mockup-code bg-primary mt-2">
                            <pre data-prefix=">" class="text-white"><code>POST {{utilityUrl}}/push-tokens/register</code></pre>
                            <pre class="text-white"><code>&#123;</code></pre>
                            <pre class="text-white"><code>"unique_id": "...", <span class="text-accent">// max 36 chars</span></code></pre>
                            <pre class="text-white"><code>"push_token": "..." <span class="text-accent">// max 512 chars</span></code></pre>
                            <pre class="text-white"><code>&#125;</code></pre>
                        </div>
                    </div>
                </div>
                <div class="collapse collapse-arrow join-item border-base-300 border">
                    <input type="radio" name="docs" />
                    <div class="collapse-title text-xl font-medium">Webhooks</div>
                    <div class="collapse-content">
                        <div role="alert" class="alert mb-3">
                            <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            class="stroke-current h-6 w-6 shrink-0">
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                            </svg>
                            <span>TLDR: calculate signature using HMAC SHA256 on payload contents with shared secret to validate payload header `Signature`.</span>                            
                        </div>
                        <p class="mb-3">When setting up webhooks, Arborsense will facilitate sharing a secret between Arborsense and the app that wants to receive webhooks. The shared secret is stored in Arborsense when creating a webhook, and can be updated if needed.</p>
                        <p class="mb-3">Arborsense webhooks will add a header called `Signature` to every webhook request it makes. That header contains a signature the receiving app can use to ensure the payload hasn't been tampered with. The value of the signature is also computed by recieving party for comparison.</p>
                        <p class="mb-3">Signing a header makes it impossible for outsiders, who don't have access to the shared secret, to compute the right value of the signature.</p>
                        <p class="mb-3">You can compute the signature using HMAC with SHA256 passing in the JSON payload and shared secret to validate the payload contents, comparing the signature header to your computed signature to see if they are matching.</p>
                        <p class="mb-3">If your computed signature is same as the signature in the header the payload is trusted. If the computed signature does not match the header signature it should be rejected.</p>
                        <div role="alert" class="alert alert-warning mt-2">
                            <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-6 w-6 shrink-0 stroke-current"
                            fill="none"
                            viewBox="0 0 24 24">
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                            </svg>
                            <span>We strongly recommend implementing this validation, otherwise data could be submitted/processed from illegitimate sources.</span>
                        </div>
                    </div>
                </div>
            </div>            
        </div>
    </div>
</article>