import { Component, signal } from '@angular/core';
import { DatePipe, formatDate } from '@angular/common';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { Sequence, SequenceBase } from '@app/models/sequence.interface';
import { SequenceHelperService } from '@app/services/helpers/sequence-helper.service';
import { Observable, tap } from 'rxjs';
import { HeadMetaComponent } from "@app/components/head-meta/head-meta.component";
import { LoadingErrorBlockComponent } from "@app/components/loading-error-block/loading-error-block.component";
import { LetDirective } from '@ngrx/component';
import { CreatedUpdatedDisplayComponent } from "@app/components/created-updated-display/created-updated-display.component";
import { DetailDisplayComponent } from "@app/components/detail-display/detail-display.component";
import { faCheck, faPenToSquare, faXmark, faArrowLeft, faFileCsv, faCircleStop } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AuthService } from '@app/services/auth.service';
import { BaseChartDirective } from 'ng2-charts';
import { ChartData, ChartOptions } from 'chart.js';
import { ProcessedSequenceItem } from '@app/models/processed-sequence-item.interface';
import { RawSequenceItem } from '@app/models/raw-sequence-item.interface';
import { PeakEvent } from '@app/models/peak-event.interface';
import { TamperEvent } from '@app/models/tamper-event.interface';
import { SequenceEvent } from '@app/models/sequence-event.interface';
import { SequenceExportType } from '@app/enums/SequenceExportType';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DateRangeComponent } from '@app/components/date-range/date-range.component';
import { DateRange } from '@app/interfaces/date-range.interface';
import { ToastService } from '@app/services/toast.service';
import { AppHttpError } from '@app/interfaces/pg-models.interface';
import { HelpButtonComponent } from "@app/components/help-button/help-button.component";
import { ProcessedSequenceItemHelperService } from '@app/services/helpers/processed-sequence-item-helper.service';
@Component({
  selector: 'app-sequence-detail',
  standalone: true,
  templateUrl: './detail.page.html',
  styleUrl: './detail.page.scss',
  imports: [
    HeadMetaComponent,
    LoadingErrorBlockComponent,
    LetDirective,
    CreatedUpdatedDisplayComponent,
    DetailDisplayComponent,
    FontAwesomeModule,
    RouterModule,
    DatePipe,
    BaseChartDirective,
    ReactiveFormsModule,
    DateRangeComponent,
    HelpButtonComponent
]
})
export class SequenceDetailPage {
  title = "Sequence Detail";
  id: number;
  searchForm: FormGroup = new FormGroup({
    date_range: new FormControl<number>(1),
    start: new FormControl<Date>(new Date(new Date().getTime() - (1 * 24 * 60 * 60 * 1000))),
    end: new FormControl<Date>(new Date()),
  });
  dateRanges:DateRange = {
    start: new Date(new Date().getTime() - (1 * 24 * 60 * 60 * 1000)),
    end: new Date,
  };
  formValid = signal(false);
  formValue = signal<DateRange | null>(null);
  sequence$?: Observable<Sequence>;
  sequenceBase$: Observable<SequenceBase>;
  base?: SequenceBase;

  faCheck = faCheck;
  faXmark = faXmark;
  faPenToSquare = faPenToSquare;
  faArrowLeft = faArrowLeft;
  faFileCsv = faFileCsv;
  faCircleStop = faCircleStop;

  sequenceExportType: typeof SequenceExportType = SequenceExportType

  public chartData:ChartData = {
    datasets: []
  };
  public chartOptions:ChartOptions = {
    scales: {
      'alcohol': {
        type: 'linear',
        position: 'left'
      },
      'default': {
        type: 'linear',
        position: 'right'
      },
      'secondary': {
        type: 'linear',
        position: 'right'
      }
    }
  }

  constructor(
    private route: ActivatedRoute,
    private sequenceHelper: SequenceHelperService,
    public auth: AuthService,
    private toast: ToastService,
    private processedItemsHelper: ProcessedSequenceItemHelperService,
  ) {
    this.id = this.route.snapshot.params['id'];
    this.sequenceBase$ = this.sequenceHelper.getBaseById(this.id)
      .pipe(tap((sequenceBase) => this.base = sequenceBase))
      .pipe(tap((sequenceBase) => {
        let dt = sequenceBase.last_activity_at ? new Date(sequenceBase.last_activity_at) : new Date();
        this.sequence$ = this.loadSequence(dt);
      }));
  }

  public loadSequence(recentDate: Date) {
    return this.sequenceHelper.getByIdFiltered(this.id, new Date(recentDate.getTime() - (1 * 24 * 60 * 60 * 1000)), recentDate)
    .pipe(tap((sequence) => this.initGraphs(sequence)));
  }

  public endSequence() {
    if(confirm("Are you sure you want to end this sequence?")) {
      this.sequenceHelper.end(this.id)
      .subscribe({
        next: () => {
          this.sequence$ = this.loadSequence(new Date());
          this.toast.message({ message: 'Ended Sequence', type: 'success' });
        },
        error: (err: AppHttpError) => {
          console.log(err.error)
          this.toast.message({ message: err.error.code + ": " + err.error.message, type: 'error'});
        }
      });
    }
  }

  public submit(){
    const form = this.searchForm.value;
    if(form) {
      switch(parseInt(form.date_range)){
        case 1: // Last 24 hours
          form.start = this.base?.last_activity_at ? new Date(this.base.last_activity_at) : new Date();
          form.end = new Date();
        break;
        case 2: // Past 2 Days
          form.start = new Date(new Date().getTime() - (2 * 24 * 60 * 60 * 1000));
          form.end = new Date();
        break;
        case 3: // Past 7 Days
          form.start = new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000));
          form.end = new Date();
        break;
        case 4: // Past 30 Days
          form.start = new Date(new Date().getTime() - (30 * 24 * 60 * 60 * 1000));
          form.end = new Date();
        break;
        case 5:
          // values set by user
          let val = this.formValue();
          if(val){
            form.start = val.start;
            form.end = val.end;
          }
        break;
        case 0: // All
        default:
          form.start = null;
          form.end = null;
        break;
      }
      this.dateRanges = {
        start: form.start,
        end: form.end,
      };
      this.sequence$ = this.sequenceHelper.getByIdFiltered(this.id, form.start == null ? null : new Date(form.start), form.end == null ? null : new Date(form.end))
        .pipe(tap((sequence) => this.initGraphs(sequence)));
    }
  }

  public exportToCsv(export_type:SequenceExportType){
    this.sequence$?.subscribe(sequence => {
      switch(export_type){
        case SequenceExportType.RawSequenceItem:
          if(sequence.raw_sequence_items){
            this.RawSequenceItemsToCsv(sequence.raw_sequence_items)
          }
        break;
        case SequenceExportType.ProcessedSequenceItem:
          if(sequence.processed_sequence_items){
            this.ProcessedSequenceItemsToCsv(sequence.processed_sequence_items)
          }
        break;
        case SequenceExportType.AdminProcessedSequenceItem:
          this.processedItemsHelper.exportBySequenceId(this.id).subscribe(sequenceItems => {
            this.createCsv(sequenceItems, 'sequence-'+this.id+'-full-processed.csv');
          })
        break;
        case SequenceExportType.PeakEvent:
          if(sequence.peak_events){
            this.PeakEventsToCsv(sequence.peak_events)
          }
        break;
        case SequenceExportType.TamperEvent:
          if(sequence.tamper_events){
            this.TamperEventsToCsv(sequence.tamper_events)
          }
        break;
        case SequenceExportType.SequenceEvent:
          if(sequence.sequence_events){
            this.SequenceEventsToCsv(sequence.sequence_events)
          }
        break;
      }
    });
  }

  private ProcessedSequenceItemsToCsv(items: ProcessedSequenceItem[]):string{
    return this.jsonToCsv(items, [
      'activity_at',
      'set_to_zero_threshold',
      'smoothed_temp_1',
      'smoothed_humidity_1',
      'ir_sensor_1',
      'ir_sensor_2',
      // 'ambient_sensor_1',
      // 'ambient_sensor_2',
      // 'is_tamper',
      // 'battery_level',
    ],
    this.createCsvFilename('processed-sequence-items'));
  }

  private RawSequenceItemsToCsv(items: RawSequenceItem[]):string{
    return this.jsonToCsv(items, [
      'activity_at',
      'time_of_reading',
      'readable_timestamp',
      'flash_timestamp',
      'delivered_at',
      'vapor_concentration',
      'temperature_1',
      'temperature_2',
      'relative_humidity_1',
      'relative_humidity_2',
      'ambient_raw_1',
      'ambient_raw_2',
      'bio_raw_1',
      'bio_raw_2',
      'is_tamper',
      'battery_level',
    ],
    this.createCsvFilename('raw-sequence-items'));
  }

  private PeakEventsToCsv(items: PeakEvent[]):string{
    return this.jsonToCsv(items, [
      'event_id',
      'event_number',
      'started_at',
      'ended_at',
      'peak_tac_at',
      'peak_tac_value',
      'peak_prominence_value',
      'duration_minutes',
      'hour_threshold_duration',
      'half_hour_threshold_duration',
      'event_rise_slope',
      'event_fall_slope',
      'area_under_curve',
    ],
    this.createCsvFilename('peak-events'));
  }

  private TamperEventsToCsv(items: TamperEvent[]):string{
    return this.jsonToCsv(items, [
      'event_id',
      'sensor_type_id',
      'event_number',
      'started_at',
      'ended_at',
      'baseline_data_value',
      'average_delta_change',
      'duration_minutes',
      'out_of_range',
      'standard_dev',
      'battery_decay_slope',
      'battery_percentage',
    ],
    this.createCsvFilename('tamper-events'));
  }

  private SequenceEventsToCsv(items: SequenceEvent[]):string{
    return this.jsonToCsv(items, [
      'event_id',
      'created_at',
    ],
    this.createCsvFilename('sequence-events'));
  }

  private jsonToCsv(items: any, headers: string[], filename?: string){
    var replacer = function(key:any, value:any) { return value === null ? '' : value } 
    var csv = items.map(function(row:any){
      return headers.map(function(fieldName){
        return JSON.stringify(row[fieldName], replacer)
      }).join(',')
    });
    csv.unshift(headers.join(','));
    csv = csv.join('\r\n');
    return this.createCsv(csv, filename);
  }

  private createCsvFilename(type: string): string {
    let base: string = 'sequence-'+this.id+'-'+type;
    let dtPart = this.getDateString(this.dateRanges.start);
    if(this.dateRanges.end) {
      dtPart = dtPart + '-to-'+this.getDateString(this.dateRanges.end);
    }
    return base+'-'+dtPart+'.csv';
  }
  private getDateString(theDate: Date) {
      let year = theDate.getFullYear();
      let month = theDate.getMonth() + 1;
      let date = theDate.getDate();
      return year + '-' + (month < 10 ? '0' : '') + month + '-' + (date < 10 ? '0' : '') + date;
  }

  private createCsv(csv: any, filename?: string) {
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    const anchorElement = document.createElement('a');
    document.body.appendChild(anchorElement);
    anchorElement.style.display = 'none';
    anchorElement.href = url;
    if(filename) {
      anchorElement.download = filename;
    }
    anchorElement.click();

    window.URL.revokeObjectURL(url);
    return csv;
  }

  private initGraphs(sequence: Sequence){
    if(!sequence.processed_sequence_items){
      return
    }

    let activity_at:Array<string> = [];
    // Always plotted by default for all users:
    let set_to_zero_threshold:Array<number> = [];
    let smoothed_temp_1:Array<number> = [];
    let smoothed_humidity_1:Array<number> = [];
    let ir_sensor_1:Array<number> = [];
    let ir_sensor_2:Array<number> = [];

    if(sequence.processed_sequence_items.length > 0) {
      activity_at = sequence.processed_sequence_items.map<string>(o => (o.activity_at ? formatDate(o.activity_at, 'short', 'en-US') : '')).filter(n => n);
      // Always plotted by default for all users:
      set_to_zero_threshold = sequence.processed_sequence_items.map<number>(o => o.set_to_zero_threshold ?? 0);
      smoothed_temp_1 = sequence.processed_sequence_items.map<number>(o => o.smoothed_temp_1 ?? 0);
      smoothed_humidity_1 = sequence.processed_sequence_items.map<number>(o => o.smoothed_humidity_1 ?? 0);
      ir_sensor_1 = sequence.processed_sequence_items.map<number>(o => o.ir_sensor_1 ?? 0);
      ir_sensor_2 = sequence.processed_sequence_items.map<number>(o => o.ir_sensor_2 ?? 0);
    }

    this.chartData = {
      labels: activity_at,
      datasets: [
        {
          label: 'Alcohol',
          data: set_to_zero_threshold,
          yAxisID:'alcohol',
          backgroundColor: 'rgba(17, 75, 95, 0.25)',
          borderColor: 'rgba(17, 75, 95, 1)',
        },
        {
          label: 'Temperature',
          data: smoothed_temp_1,
          yAxisID:'default',
          backgroundColor: 'rgba(201, 37, 0, 0.25)',
          borderColor: 'rgba(201, 37, 0, 1)',
        },
        {
          label: 'Humidity',
          data: smoothed_humidity_1,
          yAxisID:'default',
          backgroundColor: 'rgba(23, 0, 201, 0.25)',
          borderColor: 'rgba(23, 0, 201, 1)',
        },
        {
          label: 'Infrared 1',
          data: ir_sensor_1,
          yAxisID:'secondary',
          backgroundColor: 'rgba(109, 21, 21, 0.25)',
          borderColor: 'rgba(109, 21, 21, 1)',
        },
        {
          label: 'Infrared 2',
          data: ir_sensor_2,
          yAxisID:'secondary',
          backgroundColor: 'rgba(249, 113, 240, 0.25)',
          borderColor: 'rgba(249, 113, 240, 1)',
        },
      ]
    };
  }
}
