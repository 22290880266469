import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '@app/services/auth.service';

export const authGuard: CanActivateFn = (route, state) => {
  const auth = inject(AuthService);
  let urlCallback = auth.checkAuthCallback(state.url);
  if (auth.isLoggedIn()) {
    if(urlCallback) {
      inject(Router).navigate(['users', auth.getUserId(), 'edit'], { queryParams: { messageType: urlCallback.success ? 'success' : 'error', message: urlCallback.message } });
    }
    return true;
  }

  // Store the attempted URL for redirecting later
  auth.setRedirectUrl(state.url);
  inject(Router).navigate(['/login']);
  return false;
};
