import { Component, input, output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { InputFieldComponent } from '@app/components/input-field/input-field.component';
import { AutoForm } from '@app/interfaces/auto-form.interface';
import { TamperEventCreate } from '@app/models/tamper-event.interface';
import { toObservable } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-tamper-event-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    InputFieldComponent,
  ],
  templateUrl: './form.component.html',
  styleUrl: './form.component.scss'
})
export class TamperEventFormComponent {
  initialValue = input<TamperEventCreate>();
  formChange = output<TamperEventCreate>();
  formValid = output<boolean>();

  tamperEventForm = new FormGroup<AutoForm<TamperEventCreate>>({
    sequence_id: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true}),
    event_id: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true}),
    sensor_type_id: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true}),
    event_number: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true}),
    started_at: new FormControl<string>('', { validators: [Validators.required], nonNullable: true}),
    ended_at: new FormControl<string>('', { validators: [Validators.required], nonNullable: true}),
    baseline_data_value: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true}),
    average_delta_change: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true}),
    duration_minutes: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true}),
    out_of_range: new FormControl<boolean>(false, { validators: [Validators.required], nonNullable: true}),
    standard_dev: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true}),
    battery_decay_slope: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true}),
    battery_percentage: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true}),
  });

  constructor() {
    toObservable(this.initialValue).subscribe((initial) => {
      if(initial) {
        Object.keys(initial).forEach(key => {
          // https://stackoverflow.com/a/62438434/3341745
          let prop = key as keyof typeof initial;
          if(initial[prop] != null) {
            this.tamperEventForm.get(key)?.setValue(initial[prop]);
          }
        });
      }
      this.onChange();
    });
  }

  onChange() {
    this.formValid.emit(this.tamperEventForm.valid);
    this.formChange.emit(<TamperEventCreate>this.tamperEventForm.value);
  }
}