

<div class="overflow-hidden bg-white shadow sm:rounded-lg mb-4">
    <div class="border-t border-gray-100">
        <form [formGroup]="tamperEventForm" (input)="onChange()" (change)="onChange()">
            <div class="divide-y divide-gray-100">
                <div>
                    <app-input-field
                        controlName="sequence_id"
                        type="select|sequences"
                        [form]="tamperEventForm"
                        label="Sequence"
                        [errorMessages]="{ required: 'Sequence is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="event_id"
                        type="select|events"
                        [form]="tamperEventForm"
                        label="Event"
                        [errorMessages]="{ required: 'Event is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="sensor_type_id"
                        type="select|sensor-types"
                        [form]="tamperEventForm"
                        label="Sensor type"
                        [errorMessages]="{ required: 'Sensor type is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="started_at"
                        [form]="tamperEventForm"
                        type="datetime-local"
                        label="Started at (UTC)"
                        [errorMessages]="{ required: 'Started at is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="ended_at"
                        [form]="tamperEventForm"
                        type="datetime-local"
                        label="Ended at (UTC)"
                        [errorMessages]="{ required: 'Ended at is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="event_number"
                        [form]="tamperEventForm"
                        type="number"
                        step="any"
                        label="Event number"
                        [errorMessages]="{ required: 'Event number is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="baseline_data_value"
                        [form]="tamperEventForm"
                        type="number"
                        step="any"
                        label="Baseline data value"
                        [errorMessages]="{ required: 'Baseline data value is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="average_delta_change"
                        [form]="tamperEventForm"
                        type="number"
                        step="any"
                        label="Average delta change"
                        [errorMessages]="{ required: 'Average delta change is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="duration_minutes"
                        [form]="tamperEventForm"
                        type="number"
                        step="any"
                        label="Duration minutes"
                        [errorMessages]="{ required: 'Duration minutes is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="out_of_range"
                        type="toggle"
                        [form]="tamperEventForm"
                        label="Out of range?"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="standard_dev"
                        [form]="tamperEventForm"
                        type="number"
                        step="any"
                        label="Standard dev"
                        [errorMessages]="{ required: 'Standard dev is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="battery_decay_slope"
                        [form]="tamperEventForm"
                        type="number"
                        step="any"
                        label="Battery decay slope"
                        [errorMessages]="{ required: 'Battery decay slope is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="battery_percentage"
                        [form]="tamperEventForm"
                        type="number"
                        step="any"
                        label="Battery percentage"
                        [errorMessages]="{ required: 'Battery percentage is required'}"
                    />
                </div>
            </div>
        </form>
    </div>
</div>