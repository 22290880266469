import { Component } from '@angular/core';
import { HeadMetaComponent } from '@app/components/ui.component';
import { AuthService } from '@app/services/auth.service';
import { Observable } from 'rxjs';
import { LetDirective } from '@ngrx/component';
import { LoadingErrorBlockComponent } from "@app/components/loading-error-block/loading-error-block.component";
import { StatsHelperService } from '@app/services/helpers/stats-helper.service';
import { HelpButtonComponent } from "@app/components/help-button/help-button.component";
import { DatePipe, DecimalPipe } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { 
  faRing,
  faHourglass,
  faTriangleExclamation,
  faCodeBranch,
  faThumbsUp,
  faCloud,
  faCloudArrowUp,
  faCalculator,
 } from '@fortawesome/free-solid-svg-icons';
import { Provider } from '@app/models/provider.interface';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ProviderHelperService } from '@app/services/helpers/provider-helper.service';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    HeadMetaComponent,
    LetDirective,
    LoadingErrorBlockComponent,
    HelpButtonComponent,
    DatePipe,
    DecimalPipe,
    FontAwesomeModule,
    ReactiveFormsModule,
],
  templateUrl: './home.page.html',
  styleUrl: './home.page.scss'
})
export class HomePage {
  stats$;
  providers$: Observable<Provider[]>;
  searchForm: FormGroup = new FormGroup({
    provider_id: new FormControl<number | null>(null),
  });
  constructor(
    public auth: AuthService,
    private stats: StatsHelperService,
    private providerHelper: ProviderHelperService,
  ) {
    this.stats$ = this.stats.get();
    this.providers$ = this.providerHelper.getAll();
  }

  faRing = faRing;
  faHourglass = faHourglass;
  faTriangleExclamation = faTriangleExclamation;
  faCodeBranch = faCodeBranch;
  faThumbsUp = faThumbsUp;
  faCloud = faCloud;
  faCloudArrowUp = faCloudArrowUp;
  faCalculator = faCalculator;


  public submit(){
    const form = this.searchForm.value;
    if(form) {
      this.stats$ = this.stats.get(form.provider_id);
    }
  }
}
