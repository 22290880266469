<head-meta [title]="this.title" />
<app-help-button src="/assets/help/sequences/edit.md" />
<article>
    <div class="card w-full bg-base-100 shadow-xl">
        <div class="card-body">
            <ng-container *ngrxLet="sequence$ as sequence; error as e; complete as c">
                <div class="navbar bg-base-100">
                    <div class="flex-none">
                      <a routerLink="/sequences/{{this.id}}" routerLinkActive="active" class="btn btn-square btn-ghost text-primary"><fa-icon [icon]="faArrowLeft" class="w-5"></fa-icon></a>
                    </div>
                    <div class="flex-1">
                      <h1 class="h1 ml-4">Edit Sequence</h1>
                    </div>
                </div>
                <hr class="hr" />
                <app-sequence-form 
                    (formValid)="formValid.set($event)"
                    (formChange)="formValue.set($event)"
                    [initialValue]="sequence"
                />
                <button (click)="update()" class="btn btn-primary" [disabled]="!formValid()"><fa-icon [icon]="faSave" class="w-5"></fa-icon> Save</button>
                <app-loading-error-block [error]="e?.error" [complete]="c" />
            </ng-container>
        </div>
    </div>
</article>