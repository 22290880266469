import { Injectable } from '@angular/core';
import { ToastComponent } from '@app/components/toast/toast.component';
import { ToastMessage } from '@app/interfaces/toast.interface';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private toastComponent?: ToastComponent;
  private _queue?: ToastMessage;

  constructor() { }

  init(component?: ToastComponent) {
    this.toastComponent = component;
    if(this._queue && this.toastComponent) {
      this.toastComponent.show(this._queue);
    }
  }

  message(tm: ToastMessage) {
    if(this.toastComponent) {
      this.toastComponent.show(tm);
    } else {
      this._queue = tm;
    }
  }
}
