import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';
import { ToastMessage } from '@app/interfaces/toast.interface';

@Component({
  selector: 'app-toast',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './toast.component.html',
  styleUrl: './toast.component.scss',
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(150, style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate(250, style({ opacity: 0 }))
      ])
    ])
  ],
})
export class ToastComponent {
  @Input('type') type!: string;
  @Input('timeout') timeout: number = 3000;

  public _message: string | null = null;

  private message(m: string) {
    this._message = m;
    setTimeout(() => {
      this._message = null;
    }, this.timeout);
  }

  public show(tm: ToastMessage) {
    this.type = tm.type;
    this.message(tm.message);
  }
}
