import { Component, Input } from '@angular/core';
import { PGError } from '@app/interfaces/pg-models.interface';
import { LetDirective } from '@ngrx/component';

@Component({
  selector: 'app-loading-error-block',
  standalone: true,
  imports: [
    LetDirective
  ],
  templateUrl: './loading-error-block.component.html',
  styleUrl: './loading-error-block.component.scss'
})
export class LoadingErrorBlockComponent {
  @Input('error') e?: PGError;
  @Input('complete') c!: boolean;

  constructor() {
  }
}
