import { Component } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { Provider } from '@app/models/provider.interface';
import { ProviderHelperService } from '@app/services/helpers/provider-helper.service';
import { Observable } from 'rxjs';
import { HeadMetaComponent } from "@app/components/head-meta/head-meta.component";
import { LoadingErrorBlockComponent } from "@app/components/loading-error-block/loading-error-block.component";
import { LetDirective } from '@ngrx/component';
import { CreatedUpdatedDisplayComponent } from "@app/components/created-updated-display/created-updated-display.component";
import { DetailDisplayComponent } from "@app/components/detail-display/detail-display.component";
import { faCheck, faPenToSquare, faXmark, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AuthService } from '@app/services/auth.service';
import { HelpButtonComponent } from "@app/components/help-button/help-button.component";

@Component({
  selector: 'app-provider-detail',
  standalone: true,
  templateUrl: './detail.page.html',
  styleUrl: './detail.page.scss',
  imports: [
    HeadMetaComponent,
    LoadingErrorBlockComponent,
    LetDirective,
    CreatedUpdatedDisplayComponent,
    DetailDisplayComponent,
    FontAwesomeModule,
    RouterModule,
    HelpButtonComponent
]
})
export class ProviderDetailPage {
  title = "Provider Detail";
  id: number;
  provider$: Observable<Provider>;

  faCheck = faCheck;
  faXmark = faXmark;
  faPenToSquare = faPenToSquare;
  faArrowLeft = faArrowLeft;

  constructor(
    private route: ActivatedRoute,
    private providerHelper: ProviderHelperService,
    public auth: AuthService,
  ) {
    this.id = this.route.snapshot.params['id'];
    this.provider$ = this.providerHelper.getById(this.id);
  }
}
