import { Injectable } from '@angular/core';
import { SequenceEventDefaultParams as DefaultParams } from '@app/models/sequence-event-default-params.class';
import { SequenceEvent, SequenceEventCreate } from '@app/models/sequence-event.interface';
import { SequenceEventsService } from '@lib/core';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SequenceEventHelperService {

  constructor(
    private service: SequenceEventsService,
  ) { }

  getAll(): Observable<SequenceEvent[]> {
    let params = new DefaultParams();
    return this.service.sequenceEventsGet(params)
      .pipe(map(x => <SequenceEvent[]>x));
  }
  getById(id: number): Observable<SequenceEvent> {
    let params = <any>new DefaultParams();
    params.id = 'eq.' + id;
    return this.service.sequenceEventsGet(params)
      .pipe(map(x => <SequenceEvent>x[0]));
  }
  create(input: SequenceEventCreate) {
    return this.service.sequenceEventsPost({
      sequenceEvents: <any>input,
      prefer: "return=representation"
    }, 'response')
    .pipe(map(response => response.body[0]))
    .pipe(map(body => {
      //console.log(body)
      return (<SequenceEvent>body).id;
    }));
  }
  update(id: number, input: SequenceEventCreate) {
    return this.service.sequenceEventsPatch({
      id: 'eq.'+id,
      sequenceEvents: <any>input
    }, 'response')
    .pipe(map(response => response.ok));
  }
}
