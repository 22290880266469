import { Component } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { PeakEvent } from '@app/models/peak-event.interface';
import { PeakEventHelperService } from '@app/services/helpers/peak-event-helper.service';
import { Observable } from 'rxjs';
import { HeadMetaComponent } from "@app/components/head-meta/head-meta.component";
import { LoadingErrorBlockComponent } from "@app/components/loading-error-block/loading-error-block.component";
import { LetDirective } from '@ngrx/component';
import { CreatedUpdatedDisplayComponent } from "@app/components/created-updated-display/created-updated-display.component";
import { DetailDisplayComponent } from "@app/components/detail-display/detail-display.component";
import { faCheck, faPenToSquare, faXmark, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AuthService } from '@app/services/auth.service';

@Component({
  selector: 'app-peak-event-detail',
  standalone: true,
  templateUrl: './detail.page.html',
  styleUrl: './detail.page.scss',
  imports: [
    HeadMetaComponent,
    LoadingErrorBlockComponent,
    LetDirective,
    CreatedUpdatedDisplayComponent,
    DetailDisplayComponent,
    FontAwesomeModule,
    RouterModule,
    DatePipe,
  ]
})
export class PeakEventDetailPage {
  title = "Peak Event Detail";
  id: number;
  peakEvent$: Observable<PeakEvent>;

  faCheck = faCheck;
  faXmark = faXmark;
  faPenToSquare = faPenToSquare;
  faArrowLeft = faArrowLeft;

  constructor(
    private route: ActivatedRoute,
    private peakEventHelper: PeakEventHelperService,
    public auth: AuthService,
  ) {
    this.id = this.route.snapshot.params['id'];
    this.peakEvent$ = this.peakEventHelper.getById(this.id);
  }
}
