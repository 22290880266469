import { Component, input, output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { InputFieldComponent, SelectOptions } from '@app/components/input-field/input-field.component';
import { AutoForm } from '@app/interfaces/auto-form.interface';
import { VersionCreate } from '@app/models/version.interface';
import { toObservable } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-version-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    InputFieldComponent,
  ],
  templateUrl: './form.component.html',
  styleUrl: './form.component.scss'
})
export class VersionFormComponent {
  initialValue = input<VersionCreate>();
  formChange = output<VersionCreate>();
  formValid = output<boolean>();

  versionForm = new FormGroup<AutoForm<VersionCreate>>({
    name: new FormControl<string>('', { validators: [Validators.required], nonNullable: true}),
    algorithm_id: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true}),
    //latest_firmware_id: new FormControl<number>(0, { nonNullable: true}),
    minimum_version: new FormControl<number>(0, { nonNullable: true}),
  });

  constructor() {
    toObservable(this.initialValue).subscribe((initial) => {
      if(initial) {
        Object.keys(initial).forEach(key => {
          // https://stackoverflow.com/a/62438434/3341745
          let prop = key as keyof typeof initial;
          if(initial[prop] != null) {
            this.versionForm.get(key)?.setValue(initial[prop]);
          }
        });
      }
      this.onChange();
    });
  }

  onChange() {
    this.formValid.emit(this.versionForm.valid);
    this.formChange.emit(<VersionCreate>this.versionForm.value);
  }
}