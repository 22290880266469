

<div class="overflow-hidden bg-white shadow sm:rounded-lg mb-4">
    <div class="border-t border-gray-100">
        <form [formGroup]="deviceStatusForm" (input)="onChange()" (change)="onChange()">
            <div class="divide-y divide-gray-100">
                <div>
                    <app-input-field
                        controlName="name"
                        [form]="deviceStatusForm"
                        label="Name"
                        [errorMessages]="{ required: 'Name is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="key"
                        [form]="deviceStatusForm"
                        label="Key"
                        [errorMessages]="{ required: 'Key is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="sort_order"
                        type="number"
                        step="1"
                        [form]="deviceStatusForm"
                        label="Sort order"
                        [errorMessages]="{ required: 'Sort order is required'}"
                    />
                </div>
            </div>
        </form>
    </div>
</div>