<div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6" [formGroup]="form">
    <label [for]="controlName" class="text-sm font-medium text-gray-900 h-12 py-2">{{ label }}</label>
    <div class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
        <div class="h-12">
            @switch (type) {
                @case ("toggle") {
                    <input class="toggle toggle-primary mt-1" 
                    type="checkbox"
                    [formControlName]="controlName" 
                    [id]="controlName" 
                    [name]="controlName" 
                    >
                }
                @case ("select|providers") {
                    <ng-container *ngrxLet="providers$ as providers; error as e; complete as c">
                        @if(c) {
                            <select class="input input-bordered w-full max-w-xs h-10"
                            [formControlName]="controlName" 
                            [id]="controlName" 
                            [name]="controlName" 
                            [autocomplete]="controlName"
                            >
                                @if(providers.length > 0) {
                                    @for (provider of providers; track $index) {
                                        <option [value]="provider.id">{{ provider.name }}</option>
                                    }
                                } @else {
                                    <option value="null">No Providers</option>
                                }
                            </select>
                        }
                    </ng-container>
                }
                @case ("select|algorithms") {
                    <ng-container *ngrxLet="algorithms$ as algorithms; error as e; complete as c">
                        @if(c) {
                            <select class="input input-bordered w-full max-w-xs h-10"
                            [formControlName]="controlName" 
                            [id]="controlName" 
                            [name]="controlName" 
                            [autocomplete]="controlName"
                            >
                                @if(algorithms.length > 0) {
                                    @for (algorithm of algorithms; track $index) {
                                        <option [value]="algorithm.id">{{ algorithm.name }}</option>
                                    }
                                } @else {
                                    <option value="null">No Algorithms</option>
                                }
                            </select>
                        }
                    </ng-container>
                }
                @case("select|firmware"){
                    <ng-container *ngrxLet="firmware$ as firmware; error as e; complete as c">
                        @if(c) {
                            <select class="input input-bordered w-full max-w-xs h-10"
                            [formControlName]="controlName" 
                            [id]="controlName" 
                            [name]="controlName" 
                            [autocomplete]="controlName"
                            >
                                @if(firmware.length > 0) {
                                    @for (firmware_item of firmware; track $index) {
                                        <option [value]="firmware_item.id">{{ firmware_item.name }}</option>
                                    }
                                } @else {
                                    <option value="null">No Firmware</option>
                                }
                            </select>
                        }
                    </ng-container>
                }
                @case ("select|versions") {
                    <ng-container *ngrxLet="versions$ as versions; error as e; complete as c">
                        @if(c) {
                            <select class="input input-bordered w-full max-w-xs h-10"
                            [formControlName]="controlName" 
                            [id]="controlName" 
                            [name]="controlName" 
                            [autocomplete]="controlName"
                            >
                                @if(versions.length > 0) {
                                    @for (version of versions; track $index) {
                                        <option [value]="version.id">{{ version.name }}</option>
                                    }
                                } @else {
                                    <option value="null">No Versions</option>
                                }
                            </select>
                        }
                    </ng-container>
                }
                @case ("select|devices") {
                    <ng-container *ngrxLet="devices$ as devices; error as e; complete as c">
                        @if(c) {
                            <select class="input input-bordered w-full max-w-xs h-10"
                            [formControlName]="controlName" 
                            [id]="controlName" 
                            [name]="controlName" 
                            [autocomplete]="controlName"
                            >
                                @if(devices.length > 0) {
                                    @for (device of devices; track $index) {
                                        <option [value]="device.id">{{ device.serial_number }}</option>
                                    }
                                } @else {
                                    <option value="null">No Devices</option>
                                }
                            </select>
                        }
                    </ng-container>
                }
                @case ("select|device_statuses") {
                    <ng-container *ngrxLet="deviceStatuses$ as deviceStatuses; error as e; complete as c">
                        @if(c) {
                            <select class="input input-bordered w-full max-w-xs h-10"
                            [formControlName]="controlName" 
                            [id]="controlName" 
                            [name]="controlName" 
                            [autocomplete]="controlName"
                            >
                                @if(deviceStatuses.length > 0) {
                                    @for (deviceStatus of deviceStatuses; track $index) {
                                        <option [value]="deviceStatus.id">{{ deviceStatus.name }}</option>
                                    }
                                } @else {
                                    <option value="null">No Device Statuses</option>
                                }
                            </select>
                        }
                    </ng-container>
                }
                @case ("select|users") {
                    <ng-container *ngrxLet="users$ as users; error as e; complete as c">
                        @if(c) {
                            <select class="input input-bordered w-full max-w-xs h-10"
                            [formControlName]="controlName" 
                            [id]="controlName" 
                            [name]="controlName" 
                            [autocomplete]="controlName"
                            >
                                @if(users.length > 0) {
                                    @for (user of users; track $index) {
                                        <option [value]="user.id">{{ user.display_name }}</option>
                                    }
                                } @else {
                                    <option value="null">No Users</option>
                                }
                            </select>
                        }
                    </ng-container>
                }
                @case ("select|platforms") {
                    <select class="input input-bordered w-full max-w-xs h-10"
                    [formControlName]="controlName" 
                    [id]="controlName" 
                    [name]="controlName" 
                    [autocomplete]="controlName"
                    >
                        <option [value]="'ios'">iOS</option>
                        <option [value]="'android'">Android</option>
                    </select>
                }
                @case ("select|sequences") {
                    <ng-container *ngrxLet="sequences$ as sequences; error as e; complete as c">
                        @if(c) {
                            <select class="input input-bordered w-full max-w-xs h-10"
                            [formControlName]="controlName" 
                            [id]="controlName" 
                            [name]="controlName" 
                            [autocomplete]="controlName"
                            >
                                @if(sequences.length > 0) {
                                    @for (sequence of sequences; track $index) {
                                        <option [value]="sequence.id">{{ sequence.id }}</option>
                                    }
                                } @else {
                                    <option value="null">No Sequences</option>
                                }
                            </select>
                        }
                    </ng-container>
                }
                @case ("select|events") {
                    <ng-container *ngrxLet="events$ as events; error as e; complete as c">
                        @if(c) {
                            <select class="input input-bordered w-full max-w-xs h-10"
                            [formControlName]="controlName" 
                            [id]="controlName" 
                            [name]="controlName" 
                            [autocomplete]="controlName"
                            >
                                @if(events.length > 0) {
                                    @for (event of events; track $index) {
                                        <option [value]="event.id">{{ event.name }}</option>
                                    }
                                } @else {
                                    <option value="null">No Events</option>
                                }
                            </select>
                        }
                    </ng-container>
                }
                @case ("select|sensor-types") {
                    <ng-container *ngrxLet="sensorTypes$ as sensorTypes; error as e; complete as c">
                        @if(c) {
                            <select class="input input-bordered w-full max-w-xs h-10"
                            [formControlName]="controlName" 
                            [id]="controlName" 
                            [name]="controlName" 
                            [autocomplete]="controlName"
                            >
                                @if(sensorTypes.length > 0) {
                                    @for (sensorType of sensorTypes; track $index) {
                                        <option [value]="sensorType.id">{{ sensorType.name }}</option>
                                    }
                                } @else {
                                    <option value="null">No Sensor Type</option>
                                }
                            </select>
                        }
                    </ng-container>
                }
                @case("number"){
                    <input class="input input-bordered w-full max-w-xs h-10"
                        [type]="type"
                        [step]="step"
                        [formControlName]="controlName" 
                        [id]="controlName" 
                        [name]="controlName" 
                        [autocomplete]="controlName"
                        [placeholder]="placeholder"
                        >
                }
                @case("textarea"){
                    <textarea class="input input-bordered w-full max-w-full h-14"
                        [formControlName]="controlName" 
                        [id]="controlName" 
                        [name]="controlName" 
                        [autocomplete]="controlName"
                        [placeholder]="placeholder"
                        rows="3"
                        >
                    </textarea>
                }
                @default(){
                    <input class="input input-bordered w-full max-w-xs h-10"
                        [type]="type"
                        [formControlName]="controlName" 
                        [id]="controlName" 
                        [name]="controlName" 
                        [autocomplete]="controlName"
                        [placeholder]="placeholder"
                        >
                }
            }
            @if(form.controls[controlName]; as ctl) {
                <div class="label">
                    @if (ctl.invalid && (ctl.dirty || ctl.touched) && ctl.errors) {
                        @for (errKey of getErrorKeys(ctl.errors); track $index) {
                            <span class="label-text-alt text-error">
                                {{ errorMessages[errKey] }}
                            </span>
                        }
                    }
                    @if (form.invalid && (ctl.dirty || ctl.touched) && form.errors) {
                        @for (errKey of getErrorKeys(form.errors); track $index) {
                            <span class="label-text-alt text-error">
                                {{ errorMessages[errKey] }}
                            </span>
                        }
                    }
                </div>
            }
        </div>
    </div>
</div>
