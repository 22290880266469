import { Injectable } from '@angular/core';
import { ProviderDefaultParams as DefaultParams } from '@app/models/provider-default-params.class';
import { Provider, ProviderCreate } from '@app/models/provider.interface';
import { ProvidersService } from '@lib/core';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProviderHelperService {

  constructor(
    private service: ProvidersService,
  ) { }

  getAll(): Observable<Provider[]> {
    let params = new DefaultParams();
    return this.service.providersGet(params)
      .pipe(map(x => <Provider[]>x));
  }
  getById(id: number): Observable<Provider> {
    let params = <any>new DefaultParams();
    params.id = 'eq.' + id;
    return this.service.providersGet(params)
      .pipe(map(x => <Provider>x[0]));
  }
  create(input: ProviderCreate) {
    return this.service.providersPost({
      providers: <any>input,
      prefer: "return=representation"
    }, 'response')
    .pipe(map(response => response.body[0]))
    .pipe(map(body => {
      //console.log(body)
      return (<Provider>body).id;
    }));
  }
  update(id: number, input: ProviderCreate) {
    return this.service.providersPatch({
      id: 'eq.'+id,
      providers: <any>input
    }, 'response')
    .pipe(map(response => response.ok));
  }
}
