import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import {
  trigger,
  style,
  animate,
  transition,
} from '@angular/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { 
  faQuestion
 } from '@fortawesome/free-solid-svg-icons';
import { MarkdownModule } from 'ngx-markdown';

@Component({
  selector: 'app-help-button',
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    MarkdownModule,
  ],
  templateUrl: './help-button.component.html',
  styleUrl: './help-button.component.scss',
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(150, style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate(250, style({ opacity: 0 }))
      ])
    ])
  ],
})
export class HelpButtonComponent {
  faQuestion = faQuestion;
  @Input('src') src!: string;
}
