import { PhonesGetRequestParams } from "@lib/core";

export interface PhoneDefaultParams extends PhonesGetRequestParams { }
export class PhoneDefaultParams {
  constructor() {
    this.select = "*,\
      provider:providers(*),\
      device:devices(*),\
      user:users(*)";
    this.order = "id.desc";
  }
}