import { Component, Input } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors } from '@angular/forms';
import { AlgorithmHelperService } from '@app/services/helpers/algorithm-helper.service';
import { Algorithm } from '@app/models/algorithm.interface';
import { FirmwareHelperService } from '@app/services/helpers/firmware-helper.service';
import { Firmware } from '@app/models/firmware.interface';
import { ProviderHelperService } from '@app/services/helpers/provider-helper.service';
import { Provider } from '@app/models/provider.interface';
import { VersionHelperService } from '@app/services/helpers/version-helper.service';
import { Version } from '@app/models/version.interface';
import { DeviceHelperService } from '@app/services/helpers/device-helper.service';
import { Device } from '@app/models/device.interface';
import { UserHelperService } from '@app/services/helpers/user-helper.service';
import { User } from '@app/models/user.interface';
import { EventHelperService } from '@app/services/helpers/event-helper.service';
import { Event } from '@app/models/event.interface';
import { SequenceHelperService } from '@app/services/helpers/sequence-helper.service';
import { Sequence } from '@app/models/sequence.interface';
import { SensorTypeHelperService } from '@app/services/helpers/sensor-type-helper.service';
import { SensorType } from '@app/models/sensor-type.interface';
import { Observable } from 'rxjs';
import { LetDirective } from '@ngrx/component';
import { DeviceStatus } from '@app/models/device-status.interface';
import { DeviceStatusHelperService } from '@app/services/helpers/device-status-helper.service';
export interface SelectOptions {
  value: string;
  label: string;
}
@Component({
  selector: 'app-input-field',
  templateUrl: './input-field.component.html',
  imports: [
    FormsModule,
    ReactiveFormsModule,
    LetDirective,
  ],
  standalone: true
})
export class InputFieldComponent {
  algorithms$: Observable<Algorithm[]>;
  firmware$: Observable<Firmware[]>;
  providers$: Observable<Provider[]>;
  versions$: Observable<Version[]>;
  devices$: Observable<Device[]>;
  users$: Observable<User[]>;
  events$: Observable<Event[]>;
  sequences$: Observable<Sequence[]>;
  sensorTypes$: Observable<SensorType[]>;
  deviceStatuses$: Observable<DeviceStatus[]>;
  @Input() form!: FormGroup;
  @Input() controlName!: string;
  @Input() label!: string;
  @Input() errorMessages: any;
  @Input() type: string = "text";
  @Input() placeholder: string = "";
  @Input() options: SelectOptions[] = [];
  @Input() step: string = "1";

  constructor(
    private algorithmHelper: AlgorithmHelperService,
    private firmwareHelper: FirmwareHelperService,
    private providerHelper: ProviderHelperService,
    private versionHelper: VersionHelperService,
    private deviceHelper: DeviceHelperService,
    private deviceStatusHelper: DeviceStatusHelperService,
    private userHelper: UserHelperService,
    private eventHelper: EventHelperService,
    private sequenceHelper: SequenceHelperService,
    private sensorTypeHelper: SensorTypeHelperService,
  ) { 
    this.algorithms$ = this.algorithmHelper.getAll();
    this.firmware$ = this.firmwareHelper.getAll();
    this.providers$ = this.providerHelper.getAll();
    this.versions$ = this.versionHelper.getAll();
    this.devices$ = this.deviceHelper.getAll();
    this.deviceStatuses$ = this.deviceStatusHelper.getAll();
    this.users$ = this.userHelper.getAll();
    this.events$ = this.eventHelper.getAll();
    this.sequences$ = this.sequenceHelper.getAll();
    this.sensorTypes$ = this.sensorTypeHelper.getAll();
  }

  public getErrorKeys(errors: ValidationErrors) {
    if (errors) {
      return Object.keys(errors);
    } else {
      return [];
    }
  }
}
