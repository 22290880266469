<head-meta [title]="this.title" />
<app-help-button src="/assets/help/webhooks/detail.md" />
<article>
    <div class="card w-full bg-base-100 shadow-xl">
        <div class="card-body">
            <ng-container *ngrxLet="webhook$ as webhook; error as e; complete as c">
                @if(webhook) {
                    <div class="navbar bg-base-100">
                        <div class="flex-none">
                          <a routerLink="/webhooks" routerLinkActive="active" class="btn btn-square btn-ghost text-primary"><fa-icon [icon]="faArrowLeft" class="w-5"></fa-icon></a>
                        </div>
                        <div class="flex-1">
                          <h1 class="h1 ml-4">Webhook Detail</h1>
                        </div>
                        <div class="flex-none">
                            @if(this.auth.has('webhook_update')) {
                                <button [routerLink]="'/webhooks/' + this.id + '/edit'" routerLinkActive="active" class="btn btn-ghost text-primary">
                                    <fa-icon [icon]="faPenToSquare" class="w-5"></fa-icon> Edit
                                </button>
                            }
                        </div>
                    </div>
                    <hr class="hr" />
                    <div>

                    </div>
                    <div class="join join-vertical w-full">
                        <div class="collapse collapse-arrow join-item border-base-300 border">
                            <input type="radio" name="data_tables" checked="checked" />
                            <div class="collapse-title text-xl font-medium">Details</div>
                            <div class="collapse-content">
                                <div class="overflow-hidden bg-white shadow sm:rounded-lg">
                                    <div class="border-t border-gray-100">
                                        <dl class="divide-y divide-gray-100">
                                            <div>
                                                <app-detail-display title="URL" [value]="webhook.url" />
                                            </div>
                                            <div>
                                                <app-detail-display title="Sign Secret" value="****************" />
                                            </div>
                                            <div>
                                                <app-detail-display title="Provider" [value]="webhook.provider.name" />
                                            </div>
                                            <div>
                                                <app-detail-display title="Send processed sequence items" [value]="webhook.processed_sequence_items ? 'Yes' : 'No'" />
                                            </div>
                                            <div>
                                                <app-detail-display title="Send peak events" [value]="webhook.peak_events ? 'Yes' : 'No'" />
                                            </div>
                                            <div>
                                                <app-detail-display title="Send tamper events" [value]="webhook.tamper_events? 'Yes' : 'No'" />
                                            </div>
                                            <div>
                                                <app-detail-display title="Send sequence events" [value]="webhook.sequence_events? 'Yes' : 'No'" />
                                            </div>
                                            <div>
                                                <app-detail-display title="Send sequence updates" [value]="webhook.sequences ? 'Yes' : 'No'" />
                                            </div>
                                            <div>
                                                <app-detail-display title="Send device updates" [value]="webhook.devices ? 'Yes' : 'No'" />
                                            </div>
                                            <div>
                                                <app-created-updated-display [entity]="webhook" />
                                            </div>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ng-container *ngrxLet="webhookPayload$ as webhookPayload; error as e2; complete as c2">
                            @if(webhookPayload) {
                                <div class="collapse collapse-arrow join-item border-base-300 border">
                                    <input type="radio" name="data_tables" />
                                    <div class="collapse-title text-xl font-medium">Most Recent Webhook Payloads</div>
                                    <div class="collapse-content">
                                        <table class="table table-zebra table-pin-rows">
                                            <thead>
                                                <th>Payload</th>
                                                <th>Sent at</th>
                                            </thead>
                                            <tbody>
                                                @if(webhookPayload?.length ?? 0 > 0) {
                                                    @for (webhook_payload of webhookPayload; track $index) {
                                                        <tr data-id="{{ webhook_payload.id }}">
                                                            <td>
                                                                <pre class="max-h-96 overflow-y-auto text-xs">{{ webhook_payload.payload | json }}</pre>
                                                            </td>
                                                            <td>{{ webhook_payload.created_at | date: 'short' }}</td>
                                                        </tr>
                                                    }
                                                } @else {
                                                    <tr>
                                                        <td class="text-center" colspan="2">
                                                            <span class="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center">
                                                                <span class="mt-2 block text-sm text-gray-400">No Webhook Payloads</span>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            }
                            <app-loading-error-block [error]="e2?.error" [complete]="c2" />
                        </ng-container>
                    </div>
                }
                <app-loading-error-block [error]="e?.error" [complete]="c" />
            </ng-container>
        </div>
    </div>
</article>