import { Injectable } from '@angular/core';
import { PostgrestQueryHelper } from './postgrest-query-helper.service';

@Injectable({
  providedIn: 'root'
})
export class ProcessorLogsHelperService {

  constructor(
    private pg: PostgrestQueryHelper,
  ) { }

  exportBySequenceId(sequence_id: number) {
    var params = this.pg.defaultParams();
    params = this.pg.addToHttpParams(params, 'logged_at,message', 'select');
    params = this.pg.addToHttpParams(params, 'eq.' + sequence_id, 'sequence_id');
    return this.pg.get<string>('processor_logs', params, {httpHeaderAccept: 'text/csv'});
  }
}
