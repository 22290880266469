<head-meta [title]="this.title" />
<app-help-button src="/assets/help/devices/detail.md" />
<article>
    <div class="card w-full bg-base-100 shadow-xl">
        <div class="card-body">
            <ng-container *ngrxLet="device$ as device; error as e; complete as c">
                @if(device) {
                    <div class="navbar bg-base-100">
                        <div class="flex-none">
                          <a routerLink="/devices" routerLinkActive="active" class="btn btn-square btn-ghost text-primary"><fa-icon [icon]="faArrowLeft" class="w-5"></fa-icon></a>
                        </div>
                        <div class="flex-1">
                          <h1 class="h1 ml-4">Device {{this.device.serial_number}}</h1>
                        </div>
                        <div class="flex-none">
                            @if(this.auth.has('device_update')) {
                                <button [routerLink]="'/devices/' + this.id + '/edit'" routerLinkActive="active" class="btn btn-ghost text-primary">
                                    <fa-icon [icon]="faPenToSquare" class="w-5"></fa-icon> Edit
                                </button>
                            }
                        </div>
                    </div>
                    <hr class="hr" />
                    <div class="overflow-hidden bg-white shadow sm:rounded-lg">
                        <div class="border-t border-gray-100">
                            <dl class="divide-y divide-gray-100">
                                <div>
                                    <app-detail-display title="Status" [value]="device.device_status.name" />
                                </div>
                                @if(device.provider) {
                                    <div>
                                        <app-detail-display title="Provider" [value]="device.provider.name" />
                                    </div>
                                }
                                <div>
                                    <app-detail-display title="Sensor" [value]="device.sensor" />
                                </div>
                                <div>
                                    <app-detail-display title="Primary PCB" [value]="device.primary_pcb" />
                                </div>
                                <div>
                                    <app-detail-display title="Pin" [value]="device.pin" />
                                </div>
                                <div>
                                    <app-detail-display title="Version" [value]="device.version.name" />
                                </div>
                                <div>
                                    <app-detail-display title="Slope E Test" [value]="device.slope_e_test.toString()" />
                                </div>
                                <div>
                                    <app-detail-display title="Baseline Voltage" [value]="device.baseline_voltage.toString()" />
                                </div>
                                <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt class="text-sm font-medium text-gray-900">Status</dt>
                                    <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                        @if(device.active) {
                                            <fa-icon [icon]="faCheck" class="w-5 text-primary"></fa-icon> Active
                                        } @else {
                                            <fa-icon [icon]="faXmark" class="w-5 text-error"></fa-icon> Not Active
                                        }
                                    </dd>
                                </div>
                                <div>
                                    <app-detail-display title="Last Battery" [value]="device.last_battery_level?.toString() ?? ''" />
                                </div>
                                <div>
                                    <app-detail-date-display title="Last Communicated At" [value]="device.last_communicated_at ?? ''" />
                                </div>
                                <div>
                                    <app-detail-display title="Last Sensor Voltage" [value]="device.last_sensor_voltage?.toString() ?? ''" />
                                </div>
                                <div>
                                    <app-detail-display title="Last Record Mode" [value]="device.last_record_mode == true ? 'Yes' : (device.last_record_mode == false ? 'No' : '')" />
                                </div>
                                <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt class="text-sm font-medium text-gray-900">Last Tamper</dt>
                                    <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                        @if(device.last_tamper == false) {
                                            <fa-icon [icon]="faCheck" class="w-5 text-primary"></fa-icon> Not Tamper
                                        } @else if (device.last_tamper == true) {
                                            <fa-icon [icon]="faXmark" class="w-5 text-error"></fa-icon> Tamper
                                        }
                                    </dd>
                                </div>
                                <div>
                                    <app-created-updated-display [entity]="device" />
                                </div>
                            </dl>
                        </div>
                    </div>
                }
                <app-loading-error-block [error]="e?.error" [complete]="c" />
            </ng-container>
        </div>
    </div>
</article>