import { Component, effect, viewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { HeadMetaComponent } from '@app/components/ui.component';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AuthService } from '@app/services/auth.service';
import { ToastService } from '@app/services/toast.service';
import { ToastComponent } from "@app/components/toast/toast.component";
import { AuthError } from '@app/interfaces/auth-models.interface';

@Component({
  selector: 'app-forgot',
  standalone: true,
  templateUrl: './page.component.html',
  styleUrl: './page.component.scss',
  imports: [
    HeadMetaComponent,
    ReactiveFormsModule,
    ToastComponent,
    CommonModule
  ]
})
export class ForgotComponent {
  forgotForm: FormGroup = new FormGroup({
    email: new FormControl<string>('', [Validators.required, Validators.email]),
  });
  messageToast = viewChild<ToastComponent>('messageToast');
  
  constructor(
    private auth: AuthService,
    private toast: ToastService,
    private router: Router,
  ) {
    effect(() => {
      this.toast.init(this.messageToast());
    })
  }

  navToLogin() {
    this.router.navigateByUrl('/login');
  }

  submit() {
    const form = this.forgotForm.value;
    if(form) {
      this.auth.recoverPassword(form.email).subscribe({
        next: (success) => { 
          this.navToLogin();
        },
        error: (err: AuthError) => {
          console.log(err.error)
          this.toast.message({ message: err.error.error_description, type: 'error'});
        }
      });
    }
  }

}
