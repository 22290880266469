import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LoadingErrorBlockComponent } from '@app/components/loading-error-block/loading-error-block.component';
import { HeadMetaComponent } from '@app/components/ui.component';
import { Phone } from '@app/models/phone.interface';
import { AuthService } from '@app/services/auth.service';
import { PhoneHelperService } from '@app/services/helpers/phone-helper.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { 
  faCirclePlus,
  faArrowLeft 
 } from '@fortawesome/free-solid-svg-icons';
import { LetDirective } from '@ngrx/component';
import { Observable } from 'rxjs';
import { HelpButtonComponent } from "@app/components/help-button/help-button.component";
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Provider } from '@app/models/provider.interface';
import { ProviderHelperService } from '@app/services/helpers/provider-helper.service';

@Component({
  selector: 'app-phones',
  standalone: true,
  imports: [
    HeadMetaComponent,
    LetDirective,
    LoadingErrorBlockComponent,
    DatePipe,
    FontAwesomeModule,
    RouterModule,
    HelpButtonComponent,
    ReactiveFormsModule
],
  templateUrl: './listing.page.html',
  styleUrl: './listing.page.scss'
})
export class PhonesPage {
  public title = 'Phones';
  phones$: Observable<Phone[]>;
  providers$: Observable<Provider[]>;
  searchForm: FormGroup = new FormGroup({
    provider_id: new FormControl<number>(0),
  });

  faCirclePlus = faCirclePlus;
  faArrowLeft = faArrowLeft;

  constructor(
    private phoneHelper: PhoneHelperService,
    private providerHelper: ProviderHelperService,
    public auth: AuthService,
  ) {
    this.phones$ = this.phoneHelper.getAll();
    this.providers$ = this.providerHelper.getAll();
  }

  public submit(){
    const form = this.searchForm.value;
    if(form) {
      this.phones$ = this.phoneHelper.search(form.provider_id);
    }
  }
}
