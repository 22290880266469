import { Injectable } from '@angular/core';
import { Stats, StatsGetRequestParams, StatsService } from '@lib/core';
import { map, Observable } from 'rxjs';
import { PostgrestQueryHelper } from './postgrest-query-helper.service';

@Injectable({
  providedIn: 'root'
})
export class StatsHelperService {

  constructor(
    private service: StatsService,
    private pg: PostgrestQueryHelper,
  ) {

  }

  public get(provider_id?: number): Observable<Stats> {
    let params: StatsGetRequestParams = {
      select: 'stats_date,\
        count_sober_hours:count_sober_hours.sum(),\
        count_total_hours:count_total_hours.sum(),\
        count_devices:count_devices.sum(),\
        count_active_sequences:count_active_sequences.sum(),\
        count_sequence_alerts:count_sequence_alerts.sum(),\
        count_processed_sequence_items:count_processed_sequence_items.sum(),\
        count_delivered_webhooks:count_delivered_webhooks.sum(),\
        created_at:created_at.max()',
      order: 'stats_date.desc',
      limit: '1'
    }
    if(provider_id != null || provider_id != undefined) {
      params.providerId = 'eq.'+provider_id;
    }
    return this.service.statsGet(params)
    .pipe(map(x => <Stats>x[0]));
  }
}
