import { Component, signal } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { DeviceFormComponent } from '@app/components/forms/device/form.component';
import { LoadingErrorBlockComponent } from '@app/components/loading-error-block/loading-error-block.component';
import { HeadMetaComponent } from '@app/components/ui.component';
import { AppHttpError } from '@app/interfaces/pg-models.interface';
import { Device, DeviceCreate } from '@app/models/device.interface';
import { DeviceHelperService } from '@app/services/helpers/device-helper.service';
import { ToastService } from '@app/services/toast.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faSave, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { LetDirective } from '@ngrx/component';
import { Observable } from 'rxjs';
import { HelpButtonComponent } from "@app/components/help-button/help-button.component";

@Component({
  selector: 'app-device-edit',
  standalone: true,
  imports: [
    HeadMetaComponent,
    LetDirective,
    LoadingErrorBlockComponent,
    DeviceFormComponent,
    FontAwesomeModule,
    RouterModule,
    HelpButtonComponent
],
  templateUrl: './edit.page.html',
  styleUrl: './edit.page.scss'
})
export class DeviceEditPage {
  public title = 'Device Edit';
  formValid = signal(false);
  formValue = signal<DeviceCreate | null>(null);
  id: number;
  device$: Observable<Device>;

  faSave = faSave;
  faArrowLeft = faArrowLeft;

  constructor(
    private route: ActivatedRoute,
    private deviceHelper: DeviceHelperService,
    private router: Router,
    private toast: ToastService,
  ) {
    this.id = this.route.snapshot.params['id'];
    this.device$ = this.deviceHelper.getById(this.id);
  }

  update() {
    let val = this.formValue();
    if(val)
      this.deviceHelper.update(this.id, val).subscribe({
        next: (success) => { this.router.navigate(['devices', this.id]); },
        error: (err: AppHttpError) => {
          this.toast.message({ message: err.error.code + ": " + err.error.message, type: 'error'});
        }
      });
  }
}
