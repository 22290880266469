<button class="btn btn-circle btn-neutral btn-lg fixed bottom-12 right-12 z-50" onclick="help_modal.showModal()">
  <span class="text-3xl"><fa-icon [icon]="faQuestion" class="w-5"></fa-icon></span>
</button>

<dialog id="help_modal" class="modal">
  <div class="modal-box w-11/12 max-w-7xl">
    <div class="markdown-doc">
      <markdown
        mermaid
        [src]="this.src"
      />
    </div>
  </div>
  <form method="dialog" class="modal-backdrop">
    <button>close</button>
  </form>
</dialog>