import { Component, Input } from "@angular/core";
import { Event, Router } from "@angular/router";

@Component({
  selector: 'api-version',
  standalone: true,
  imports: [],
  templateUrl: './api-version.component.html',
  styleUrl: './api-version.component.scss'
})
export class ApiVersionComponent {
  @Input() current_selection: string = "v1";
  public versions = [
    'v1',
  ];
  constructor(
    private router:Router
  ){

  }

  navigateTo(event:any) {
    let value = event.target.value;
    this.current_selection = value;
    this.router.navigate(['/docs/'+value]);
    return false;
  }
}

