

<div class="overflow-hidden bg-white shadow sm:rounded-lg mb-4">
    <div class="border-t border-gray-100">
        <form [formGroup]="sensorTypeForm" (input)="onChange()" (change)="onChange()">
            <div class="divide-y divide-gray-100">
                <div>
                    <app-input-field
                        controlName="id"
                        [form]="sensorTypeForm"
                        type="number"
                        label="Id"
                        [errorMessages]="{ required: 'Id is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="name"
                        [form]="sensorTypeForm"
                        label="Name"
                        [errorMessages]="{ required: 'Name is required'}"
                    />
                </div>
            </div>
        </form>
    </div>
</div>