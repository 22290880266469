<head-meta [title]="this.title" />
<article>
    <div class="card w-full bg-base-100 shadow-xl">
        <div class="card-body">
            <ng-container *ngrxLet="processedSequenceItem$ as processedSequenceItem; error as e; complete as c">
                @if(processedSequenceItem) {
                    <div class="navbar bg-base-100">
                        <div class="flex-none">
                          <a routerLink="/processed-sequence-items" routerLinkActive="active" class="btn btn-square btn-ghost text-primary"><fa-icon [icon]="faArrowLeft" class="w-5"></fa-icon></a>
                        </div>
                        <div class="flex-1">
                          <h1 class="h1 ml-4">Processed Sequence Item Detail</h1>
                        </div>
                        <div class="flex-none">
                            @if(this.auth.has('device_status_update')) {
                                <button [routerLink]="'/processed-sequence-items/' + this.id + '/edit'" routerLinkActive="active" class="btn btn-ghost text-primary">
                                    <fa-icon [icon]="faPenToSquare" class="w-5"></fa-icon> Edit
                                </button>
                            }
                        </div>
                    </div>
                    <hr class="hr" />
                    <div class="overflow-hidden bg-white shadow sm:rounded-lg">
                        <div class="border-t border-gray-100">
                            <dl class="divide-y divide-gray-100">
                                <div>
                                    <app-detail-display title="Sequence Id" [value]="processedSequenceItem.sequence_id.toString()" />
                                </div>
                                <div>
                                    <app-detail-display title="Raw sequence item Id" [value]="processedSequenceItem.raw_sequence_item_id.toString()" />
                                </div>
                                <div>
                                    <app-detail-display title="Activity at" [value]="(processedSequenceItem.activity_at | date: 'medium') ?? ''" />
                                </div>
                                <div>
                                    <app-detail-display title="Smoothed voltage" [value]="processedSequenceItem.smoothed_voltage?.toString() ?? ''" />
                                </div>
                                <div>
                                    <app-detail-display title="Smoothed temp 1" [value]="processedSequenceItem.smoothed_temp_1?.toString() ?? ''" />
                                </div>
                                <div>
                                    <app-detail-display title="Smoothed temp 2" [value]="processedSequenceItem.smoothed_temp_2?.toString() ?? ''" />
                                </div>
                                <div>
                                    <app-detail-display title="Smoothed humidity 1" [value]="processedSequenceItem.smoothed_humidity_1?.toString() ?? ''" />
                                </div>
                                <div>
                                    <app-detail-display title="Smoothed humidity 2" [value]="processedSequenceItem.smoothed_humidity_2?.toString() ?? ''" />
                                </div>
                                <div>
                                    <app-detail-display title="Ambient sensor 1" [value]="processedSequenceItem.ambient_sensor_1?.toString() ?? ''" />
                                </div>
                                <div>
                                    <app-detail-display title="Ambient sensor 2" [value]="processedSequenceItem.ambient_sensor_2?.toString() ?? ''" />
                                </div>
                                <div>
                                    <app-detail-display title="IR sensor 1" [value]="processedSequenceItem.ir_sensor_1?.toString() ?? ''" />
                                </div>
                                <div>
                                    <app-detail-display title="IR sensor 2" [value]="processedSequenceItem.ir_sensor_2?.toString() ?? ''" />
                                </div>
                                <div>
                                    <app-detail-display title="Slope 1" [value]="processedSequenceItem.slope_1?.toString() ?? ''" />
                                </div>
                                <div>
                                    <app-detail-display title="Slope 2" [value]="processedSequenceItem.slope_2?.toString() ?? ''" />
                                </div>
                                <div>
                                    <app-detail-display title="Final graphene sensor ata" [value]="processedSequenceItem.final_graphene_sensor_data?.toString() ?? ''" />
                                </div>
                                <div>
                                    <app-detail-display title="Baseline graphene sensor data" [value]="processedSequenceItem.baseline_graphene_sensor_data?.toString() ?? ''" />
                                </div>
                                <div>
                                    <app-detail-display title="Smoothed graphene sensor data" [value]="processedSequenceItem.smoothed_graphene_sensor_data?.toString() ?? ''" />
                                </div>
                                <div>
                                    <app-detail-display title="TAC value" [value]="processedSequenceItem.tac_value?.toString() ?? ''" />
                                </div>
                                <div>
                                    <app-detail-display title="Set to zero threshold" [value]="processedSequenceItem.set_to_zero_threshold?.toString() ?? ''" />
                                </div>
                                <div>
                                    <app-detail-display title="Final suppressed data" [value]="processedSequenceItem.final_suppressed_data?.toString() ?? ''" />
                                </div>
                                <div>
                                    <app-detail-display title="Is tamper?" [value]="processedSequenceItem.is_tamper ? 'Yes' : 'No'" />
                                </div>
                                <div>
                                    <app-detail-display title="Battery level" [value]="processedSequenceItem.battery_level?.toString() ?? ''" />
                                </div>
                                <div>
                                    <app-detail-display title="Time interval" [value]="processedSequenceItem.time_interval?.toString() ?? ''" />
                                </div>
                                <div>
                                    <app-created-updated-display [entity]="processedSequenceItem" />
                                </div>
                            </dl>
                        </div>
                    </div>
                }
                <app-loading-error-block [error]="e?.error" [complete]="c" />
            </ng-container>
        </div>
    </div>
</article>