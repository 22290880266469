import { Component, signal } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { PhoneFormComponent } from '@app/components/forms/phone/form.component';
import { LoadingErrorBlockComponent } from '@app/components/loading-error-block/loading-error-block.component';
import { HeadMetaComponent } from '@app/components/ui.component';
import { AppHttpError } from '@app/interfaces/pg-models.interface';
import { Phone, PhoneCreate } from '@app/models/phone.interface';
import { PhoneHelperService } from '@app/services/helpers/phone-helper.service';
import { ToastService } from '@app/services/toast.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faSave, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { LetDirective } from '@ngrx/component';
import { Observable } from 'rxjs';
import { HelpButtonComponent } from "@app/components/help-button/help-button.component";

@Component({
  selector: 'app-phone-edit',
  standalone: true,
  imports: [
    HeadMetaComponent,
    LetDirective,
    LoadingErrorBlockComponent,
    PhoneFormComponent,
    FontAwesomeModule,
    RouterModule,
    HelpButtonComponent
],
  templateUrl: './edit.page.html',
  styleUrl: './edit.page.scss'
})
export class PhoneEditPage {
  public title = 'Phone Edit';
  formValid = signal(false);
  formValue = signal<PhoneCreate | null>(null);
  id: number;
  phone$: Observable<Phone>;

  faSave = faSave;
  faArrowLeft = faArrowLeft;

  constructor(
    private route: ActivatedRoute,
    private phoneHelper: PhoneHelperService,
    private router: Router,
    private toast: ToastService,
  ) {
    this.id = this.route.snapshot.params['id'];
    this.phone$ = this.phoneHelper.getById(this.id);
  }

  update() {
    let val = this.formValue();
    if(val)
      this.phoneHelper.update(this.id, val).subscribe({
        next: (success) => { this.router.navigate(['phones', this.id]); },
        error: (err: AppHttpError) => {
          this.toast.message({ message: err.error.code + ": " + err.error.message, type: 'error'});
        }
      });
  }
}
