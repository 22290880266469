/**
 * Arborsense API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 12.2.8 (4a3936f)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { Algorithms } from '../model/algorithms';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface AlgorithmsDeleteRequestParams {
    id?: string;
    name?: string;
    plotFrequency?: string;
    useFahrenheit?: string;
    graphTac?: string;
    graphEnvironment?: string;
    graphBaseline?: string;
    graphBio?: string;
    graphAmbient?: string;
    graphTamper?: string;
    graphBattery?: string;
    minPeakHeight?: string;
    minPeakProminence?: string;
    minPeakHourHeight?: string;
    minPeakHalfHourHeight?: string;
    maxPeakRiseSlope?: string;
    maxPeakFallSlope?: string;
    excludeDqdAlcoholEvents?: string;
    discardInitialDataHours?: string;
    maxTimeWithoutReading?: string;
    percentileFilterWindow?: string;
    flattenTac?: string;
    rhSlope1?: string;
    rhSlope2?: string;
    gainResistance?: string;
    correctionFactor5?: string;
    negValuesToZero?: string;
    relativeHumiditySynthThreshold?: string;
    tempCorrectionDateChunkSize?: string;
    tempCorrectionRhRangeSize?: string;
    pointsOfWindow?: string;
    smoothFactor?: string;
    threshold?: string;
    tacThreshold?: string;
    percentileFiltering?: string;
    fftSmoothing?: string;
    rhCorrection?: string;
    tempCorrection?: string;
    baselineCorrection?: string;
    deltaThreshold?: string;
    distanceBetweenPeaks?: string;
    savgolFilterWindow?: string;
    savgolFilterPolynomial?: string;
    factorB1?: string;
    factorA1?: string;
    alpha?: string;
    createdAt?: string;
    updatedAt?: string;
    isGalvanic?: string;
    /** Preference */
    prefer?: 'return=representation' | 'return=minimal' | 'return=none';
}

export interface AlgorithmsGetRequestParams {
    id?: string;
    name?: string;
    plotFrequency?: string;
    useFahrenheit?: string;
    graphTac?: string;
    graphEnvironment?: string;
    graphBaseline?: string;
    graphBio?: string;
    graphAmbient?: string;
    graphTamper?: string;
    graphBattery?: string;
    minPeakHeight?: string;
    minPeakProminence?: string;
    minPeakHourHeight?: string;
    minPeakHalfHourHeight?: string;
    maxPeakRiseSlope?: string;
    maxPeakFallSlope?: string;
    excludeDqdAlcoholEvents?: string;
    discardInitialDataHours?: string;
    maxTimeWithoutReading?: string;
    percentileFilterWindow?: string;
    flattenTac?: string;
    rhSlope1?: string;
    rhSlope2?: string;
    gainResistance?: string;
    correctionFactor5?: string;
    negValuesToZero?: string;
    relativeHumiditySynthThreshold?: string;
    tempCorrectionDateChunkSize?: string;
    tempCorrectionRhRangeSize?: string;
    pointsOfWindow?: string;
    smoothFactor?: string;
    threshold?: string;
    tacThreshold?: string;
    percentileFiltering?: string;
    fftSmoothing?: string;
    rhCorrection?: string;
    tempCorrection?: string;
    baselineCorrection?: string;
    deltaThreshold?: string;
    distanceBetweenPeaks?: string;
    savgolFilterWindow?: string;
    savgolFilterPolynomial?: string;
    factorB1?: string;
    factorA1?: string;
    alpha?: string;
    createdAt?: string;
    updatedAt?: string;
    isGalvanic?: string;
    /** Filtering Columns */
    select?: string;
    /** Ordering */
    order?: string;
    /** Limiting and Pagination */
    range?: string;
    /** Limiting and Pagination */
    rangeUnit?: string;
    /** Limiting and Pagination */
    offset?: string;
    /** Limiting and Pagination */
    limit?: string;
    /** Preference */
    prefer?: 'count=none';
}

export interface AlgorithmsPatchRequestParams {
    id?: string;
    name?: string;
    plotFrequency?: string;
    useFahrenheit?: string;
    graphTac?: string;
    graphEnvironment?: string;
    graphBaseline?: string;
    graphBio?: string;
    graphAmbient?: string;
    graphTamper?: string;
    graphBattery?: string;
    minPeakHeight?: string;
    minPeakProminence?: string;
    minPeakHourHeight?: string;
    minPeakHalfHourHeight?: string;
    maxPeakRiseSlope?: string;
    maxPeakFallSlope?: string;
    excludeDqdAlcoholEvents?: string;
    discardInitialDataHours?: string;
    maxTimeWithoutReading?: string;
    percentileFilterWindow?: string;
    flattenTac?: string;
    rhSlope1?: string;
    rhSlope2?: string;
    gainResistance?: string;
    correctionFactor5?: string;
    negValuesToZero?: string;
    relativeHumiditySynthThreshold?: string;
    tempCorrectionDateChunkSize?: string;
    tempCorrectionRhRangeSize?: string;
    pointsOfWindow?: string;
    smoothFactor?: string;
    threshold?: string;
    tacThreshold?: string;
    percentileFiltering?: string;
    fftSmoothing?: string;
    rhCorrection?: string;
    tempCorrection?: string;
    baselineCorrection?: string;
    deltaThreshold?: string;
    distanceBetweenPeaks?: string;
    savgolFilterWindow?: string;
    savgolFilterPolynomial?: string;
    factorB1?: string;
    factorA1?: string;
    alpha?: string;
    createdAt?: string;
    updatedAt?: string;
    isGalvanic?: string;
    /** Preference */
    prefer?: 'return=representation' | 'return=minimal' | 'return=none';
    /** algorithms */
    algorithms?: Algorithms;
}

export interface AlgorithmsPostRequestParams {
    /** Filtering Columns */
    select?: string;
    /** Preference */
    prefer?: 'return=representation' | 'return=minimal' | 'return=none' | 'resolution=ignore-duplicates' | 'resolution=merge-duplicates';
    /** algorithms */
    algorithms?: Algorithms;
}


@Injectable({
  providedIn: 'root'
})
export class AlgorithmsService {

    protected basePath = 'http://0.0.0.0:3000';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public algorithmsDelete(requestParameters: AlgorithmsDeleteRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public algorithmsDelete(requestParameters: AlgorithmsDeleteRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public algorithmsDelete(requestParameters: AlgorithmsDeleteRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public algorithmsDelete(requestParameters: AlgorithmsDeleteRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const id = requestParameters.id;
        const name = requestParameters.name;
        const plotFrequency = requestParameters.plotFrequency;
        const useFahrenheit = requestParameters.useFahrenheit;
        const graphTac = requestParameters.graphTac;
        const graphEnvironment = requestParameters.graphEnvironment;
        const graphBaseline = requestParameters.graphBaseline;
        const graphBio = requestParameters.graphBio;
        const graphAmbient = requestParameters.graphAmbient;
        const graphTamper = requestParameters.graphTamper;
        const graphBattery = requestParameters.graphBattery;
        const minPeakHeight = requestParameters.minPeakHeight;
        const minPeakProminence = requestParameters.minPeakProminence;
        const minPeakHourHeight = requestParameters.minPeakHourHeight;
        const minPeakHalfHourHeight = requestParameters.minPeakHalfHourHeight;
        const maxPeakRiseSlope = requestParameters.maxPeakRiseSlope;
        const maxPeakFallSlope = requestParameters.maxPeakFallSlope;
        const excludeDqdAlcoholEvents = requestParameters.excludeDqdAlcoholEvents;
        const discardInitialDataHours = requestParameters.discardInitialDataHours;
        const maxTimeWithoutReading = requestParameters.maxTimeWithoutReading;
        const percentileFilterWindow = requestParameters.percentileFilterWindow;
        const flattenTac = requestParameters.flattenTac;
        const rhSlope1 = requestParameters.rhSlope1;
        const rhSlope2 = requestParameters.rhSlope2;
        const gainResistance = requestParameters.gainResistance;
        const correctionFactor5 = requestParameters.correctionFactor5;
        const negValuesToZero = requestParameters.negValuesToZero;
        const relativeHumiditySynthThreshold = requestParameters.relativeHumiditySynthThreshold;
        const tempCorrectionDateChunkSize = requestParameters.tempCorrectionDateChunkSize;
        const tempCorrectionRhRangeSize = requestParameters.tempCorrectionRhRangeSize;
        const pointsOfWindow = requestParameters.pointsOfWindow;
        const smoothFactor = requestParameters.smoothFactor;
        const threshold = requestParameters.threshold;
        const tacThreshold = requestParameters.tacThreshold;
        const percentileFiltering = requestParameters.percentileFiltering;
        const fftSmoothing = requestParameters.fftSmoothing;
        const rhCorrection = requestParameters.rhCorrection;
        const tempCorrection = requestParameters.tempCorrection;
        const baselineCorrection = requestParameters.baselineCorrection;
        const deltaThreshold = requestParameters.deltaThreshold;
        const distanceBetweenPeaks = requestParameters.distanceBetweenPeaks;
        const savgolFilterWindow = requestParameters.savgolFilterWindow;
        const savgolFilterPolynomial = requestParameters.savgolFilterPolynomial;
        const factorB1 = requestParameters.factorB1;
        const factorA1 = requestParameters.factorA1;
        const alpha = requestParameters.alpha;
        const createdAt = requestParameters.createdAt;
        const updatedAt = requestParameters.updatedAt;
        const isGalvanic = requestParameters.isGalvanic;
        const prefer = requestParameters.prefer;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (id !== undefined && id !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>id, 'id');
        }
        if (name !== undefined && name !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>name, 'name');
        }
        if (plotFrequency !== undefined && plotFrequency !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>plotFrequency, 'plot_frequency');
        }
        if (useFahrenheit !== undefined && useFahrenheit !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>useFahrenheit, 'use_fahrenheit');
        }
        if (graphTac !== undefined && graphTac !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>graphTac, 'graph_tac');
        }
        if (graphEnvironment !== undefined && graphEnvironment !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>graphEnvironment, 'graph_environment');
        }
        if (graphBaseline !== undefined && graphBaseline !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>graphBaseline, 'graph_baseline');
        }
        if (graphBio !== undefined && graphBio !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>graphBio, 'graph_bio');
        }
        if (graphAmbient !== undefined && graphAmbient !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>graphAmbient, 'graph_ambient');
        }
        if (graphTamper !== undefined && graphTamper !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>graphTamper, 'graph_tamper');
        }
        if (graphBattery !== undefined && graphBattery !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>graphBattery, 'graph_battery');
        }
        if (minPeakHeight !== undefined && minPeakHeight !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>minPeakHeight, 'min_peak_height');
        }
        if (minPeakProminence !== undefined && minPeakProminence !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>minPeakProminence, 'min_peak_prominence');
        }
        if (minPeakHourHeight !== undefined && minPeakHourHeight !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>minPeakHourHeight, 'min_peak_hour_height');
        }
        if (minPeakHalfHourHeight !== undefined && minPeakHalfHourHeight !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>minPeakHalfHourHeight, 'min_peak_half_hour_height');
        }
        if (maxPeakRiseSlope !== undefined && maxPeakRiseSlope !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>maxPeakRiseSlope, 'max_peak_rise_slope');
        }
        if (maxPeakFallSlope !== undefined && maxPeakFallSlope !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>maxPeakFallSlope, 'max_peak_fall_slope');
        }
        if (excludeDqdAlcoholEvents !== undefined && excludeDqdAlcoholEvents !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>excludeDqdAlcoholEvents, 'exclude_dqd_alcohol_events');
        }
        if (discardInitialDataHours !== undefined && discardInitialDataHours !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>discardInitialDataHours, 'discard_initial_data_hours');
        }
        if (maxTimeWithoutReading !== undefined && maxTimeWithoutReading !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>maxTimeWithoutReading, 'max_time_without_reading');
        }
        if (percentileFilterWindow !== undefined && percentileFilterWindow !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>percentileFilterWindow, 'percentile_filter_window');
        }
        if (flattenTac !== undefined && flattenTac !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>flattenTac, 'flatten_tac');
        }
        if (rhSlope1 !== undefined && rhSlope1 !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>rhSlope1, 'rh_slope_1');
        }
        if (rhSlope2 !== undefined && rhSlope2 !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>rhSlope2, 'rh_slope_2');
        }
        if (gainResistance !== undefined && gainResistance !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>gainResistance, 'gain_resistance');
        }
        if (correctionFactor5 !== undefined && correctionFactor5 !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>correctionFactor5, 'correction_factor_5');
        }
        if (negValuesToZero !== undefined && negValuesToZero !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>negValuesToZero, 'neg_values_to_zero');
        }
        if (relativeHumiditySynthThreshold !== undefined && relativeHumiditySynthThreshold !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>relativeHumiditySynthThreshold, 'relative_humidity_synth_threshold');
        }
        if (tempCorrectionDateChunkSize !== undefined && tempCorrectionDateChunkSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tempCorrectionDateChunkSize, 'temp_correction_date_chunk_size');
        }
        if (tempCorrectionRhRangeSize !== undefined && tempCorrectionRhRangeSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tempCorrectionRhRangeSize, 'temp_correction_rh_range_size');
        }
        if (pointsOfWindow !== undefined && pointsOfWindow !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pointsOfWindow, 'points_of_window');
        }
        if (smoothFactor !== undefined && smoothFactor !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>smoothFactor, 'smooth_factor');
        }
        if (threshold !== undefined && threshold !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>threshold, 'threshold');
        }
        if (tacThreshold !== undefined && tacThreshold !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tacThreshold, 'tac_threshold');
        }
        if (percentileFiltering !== undefined && percentileFiltering !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>percentileFiltering, 'percentile_filtering');
        }
        if (fftSmoothing !== undefined && fftSmoothing !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>fftSmoothing, 'fft_smoothing');
        }
        if (rhCorrection !== undefined && rhCorrection !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>rhCorrection, 'rh_correction');
        }
        if (tempCorrection !== undefined && tempCorrection !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tempCorrection, 'temp_correction');
        }
        if (baselineCorrection !== undefined && baselineCorrection !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>baselineCorrection, 'baseline_correction');
        }
        if (deltaThreshold !== undefined && deltaThreshold !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>deltaThreshold, 'delta_threshold');
        }
        if (distanceBetweenPeaks !== undefined && distanceBetweenPeaks !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>distanceBetweenPeaks, 'distance_between_peaks');
        }
        if (savgolFilterWindow !== undefined && savgolFilterWindow !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>savgolFilterWindow, 'savgol_filter_window');
        }
        if (savgolFilterPolynomial !== undefined && savgolFilterPolynomial !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>savgolFilterPolynomial, 'savgol_filter_polynomial');
        }
        if (factorB1 !== undefined && factorB1 !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>factorB1, 'factor_b1');
        }
        if (factorA1 !== undefined && factorA1 !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>factorA1, 'factor_a1');
        }
        if (alpha !== undefined && alpha !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>alpha, 'alpha');
        }
        if (createdAt !== undefined && createdAt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>createdAt, 'created_at');
        }
        if (updatedAt !== undefined && updatedAt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>updatedAt, 'updated_at');
        }
        if (isGalvanic !== undefined && isGalvanic !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isGalvanic, 'is_galvanic');
        }

        let localVarHeaders = this.defaultHeaders;
        if (prefer !== undefined && prefer !== null) {
            localVarHeaders = localVarHeaders.set('Prefer', String(prefer));
        }

        let localVarCredential: string | undefined;
        // authentication (JWT) required
        localVarCredential = this.configuration.lookupCredential('JWT');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/algorithms`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public algorithmsGet(requestParameters: AlgorithmsGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/vnd.pgrst.object+json;nulls=stripped' | 'application/vnd.pgrst.object+json' | 'text/csv', context?: HttpContext, transferCache?: boolean}): Observable<Array<Algorithms>>;
    public algorithmsGet(requestParameters: AlgorithmsGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/vnd.pgrst.object+json;nulls=stripped' | 'application/vnd.pgrst.object+json' | 'text/csv', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Array<Algorithms>>>;
    public algorithmsGet(requestParameters: AlgorithmsGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/vnd.pgrst.object+json;nulls=stripped' | 'application/vnd.pgrst.object+json' | 'text/csv', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Array<Algorithms>>>;
    public algorithmsGet(requestParameters: AlgorithmsGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'application/vnd.pgrst.object+json;nulls=stripped' | 'application/vnd.pgrst.object+json' | 'text/csv', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const id = requestParameters.id;
        const name = requestParameters.name;
        const plotFrequency = requestParameters.plotFrequency;
        const useFahrenheit = requestParameters.useFahrenheit;
        const graphTac = requestParameters.graphTac;
        const graphEnvironment = requestParameters.graphEnvironment;
        const graphBaseline = requestParameters.graphBaseline;
        const graphBio = requestParameters.graphBio;
        const graphAmbient = requestParameters.graphAmbient;
        const graphTamper = requestParameters.graphTamper;
        const graphBattery = requestParameters.graphBattery;
        const minPeakHeight = requestParameters.minPeakHeight;
        const minPeakProminence = requestParameters.minPeakProminence;
        const minPeakHourHeight = requestParameters.minPeakHourHeight;
        const minPeakHalfHourHeight = requestParameters.minPeakHalfHourHeight;
        const maxPeakRiseSlope = requestParameters.maxPeakRiseSlope;
        const maxPeakFallSlope = requestParameters.maxPeakFallSlope;
        const excludeDqdAlcoholEvents = requestParameters.excludeDqdAlcoholEvents;
        const discardInitialDataHours = requestParameters.discardInitialDataHours;
        const maxTimeWithoutReading = requestParameters.maxTimeWithoutReading;
        const percentileFilterWindow = requestParameters.percentileFilterWindow;
        const flattenTac = requestParameters.flattenTac;
        const rhSlope1 = requestParameters.rhSlope1;
        const rhSlope2 = requestParameters.rhSlope2;
        const gainResistance = requestParameters.gainResistance;
        const correctionFactor5 = requestParameters.correctionFactor5;
        const negValuesToZero = requestParameters.negValuesToZero;
        const relativeHumiditySynthThreshold = requestParameters.relativeHumiditySynthThreshold;
        const tempCorrectionDateChunkSize = requestParameters.tempCorrectionDateChunkSize;
        const tempCorrectionRhRangeSize = requestParameters.tempCorrectionRhRangeSize;
        const pointsOfWindow = requestParameters.pointsOfWindow;
        const smoothFactor = requestParameters.smoothFactor;
        const threshold = requestParameters.threshold;
        const tacThreshold = requestParameters.tacThreshold;
        const percentileFiltering = requestParameters.percentileFiltering;
        const fftSmoothing = requestParameters.fftSmoothing;
        const rhCorrection = requestParameters.rhCorrection;
        const tempCorrection = requestParameters.tempCorrection;
        const baselineCorrection = requestParameters.baselineCorrection;
        const deltaThreshold = requestParameters.deltaThreshold;
        const distanceBetweenPeaks = requestParameters.distanceBetweenPeaks;
        const savgolFilterWindow = requestParameters.savgolFilterWindow;
        const savgolFilterPolynomial = requestParameters.savgolFilterPolynomial;
        const factorB1 = requestParameters.factorB1;
        const factorA1 = requestParameters.factorA1;
        const alpha = requestParameters.alpha;
        const createdAt = requestParameters.createdAt;
        const updatedAt = requestParameters.updatedAt;
        const isGalvanic = requestParameters.isGalvanic;
        const select = requestParameters.select;
        const order = requestParameters.order;
        const range = requestParameters.range;
        const rangeUnit = requestParameters.rangeUnit;
        const offset = requestParameters.offset;
        const limit = requestParameters.limit;
        const prefer = requestParameters.prefer;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (id !== undefined && id !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>id, 'id');
        }
        if (name !== undefined && name !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>name, 'name');
        }
        if (plotFrequency !== undefined && plotFrequency !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>plotFrequency, 'plot_frequency');
        }
        if (useFahrenheit !== undefined && useFahrenheit !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>useFahrenheit, 'use_fahrenheit');
        }
        if (graphTac !== undefined && graphTac !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>graphTac, 'graph_tac');
        }
        if (graphEnvironment !== undefined && graphEnvironment !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>graphEnvironment, 'graph_environment');
        }
        if (graphBaseline !== undefined && graphBaseline !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>graphBaseline, 'graph_baseline');
        }
        if (graphBio !== undefined && graphBio !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>graphBio, 'graph_bio');
        }
        if (graphAmbient !== undefined && graphAmbient !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>graphAmbient, 'graph_ambient');
        }
        if (graphTamper !== undefined && graphTamper !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>graphTamper, 'graph_tamper');
        }
        if (graphBattery !== undefined && graphBattery !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>graphBattery, 'graph_battery');
        }
        if (minPeakHeight !== undefined && minPeakHeight !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>minPeakHeight, 'min_peak_height');
        }
        if (minPeakProminence !== undefined && minPeakProminence !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>minPeakProminence, 'min_peak_prominence');
        }
        if (minPeakHourHeight !== undefined && minPeakHourHeight !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>minPeakHourHeight, 'min_peak_hour_height');
        }
        if (minPeakHalfHourHeight !== undefined && minPeakHalfHourHeight !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>minPeakHalfHourHeight, 'min_peak_half_hour_height');
        }
        if (maxPeakRiseSlope !== undefined && maxPeakRiseSlope !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>maxPeakRiseSlope, 'max_peak_rise_slope');
        }
        if (maxPeakFallSlope !== undefined && maxPeakFallSlope !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>maxPeakFallSlope, 'max_peak_fall_slope');
        }
        if (excludeDqdAlcoholEvents !== undefined && excludeDqdAlcoholEvents !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>excludeDqdAlcoholEvents, 'exclude_dqd_alcohol_events');
        }
        if (discardInitialDataHours !== undefined && discardInitialDataHours !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>discardInitialDataHours, 'discard_initial_data_hours');
        }
        if (maxTimeWithoutReading !== undefined && maxTimeWithoutReading !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>maxTimeWithoutReading, 'max_time_without_reading');
        }
        if (percentileFilterWindow !== undefined && percentileFilterWindow !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>percentileFilterWindow, 'percentile_filter_window');
        }
        if (flattenTac !== undefined && flattenTac !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>flattenTac, 'flatten_tac');
        }
        if (rhSlope1 !== undefined && rhSlope1 !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>rhSlope1, 'rh_slope_1');
        }
        if (rhSlope2 !== undefined && rhSlope2 !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>rhSlope2, 'rh_slope_2');
        }
        if (gainResistance !== undefined && gainResistance !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>gainResistance, 'gain_resistance');
        }
        if (correctionFactor5 !== undefined && correctionFactor5 !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>correctionFactor5, 'correction_factor_5');
        }
        if (negValuesToZero !== undefined && negValuesToZero !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>negValuesToZero, 'neg_values_to_zero');
        }
        if (relativeHumiditySynthThreshold !== undefined && relativeHumiditySynthThreshold !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>relativeHumiditySynthThreshold, 'relative_humidity_synth_threshold');
        }
        if (tempCorrectionDateChunkSize !== undefined && tempCorrectionDateChunkSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tempCorrectionDateChunkSize, 'temp_correction_date_chunk_size');
        }
        if (tempCorrectionRhRangeSize !== undefined && tempCorrectionRhRangeSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tempCorrectionRhRangeSize, 'temp_correction_rh_range_size');
        }
        if (pointsOfWindow !== undefined && pointsOfWindow !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pointsOfWindow, 'points_of_window');
        }
        if (smoothFactor !== undefined && smoothFactor !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>smoothFactor, 'smooth_factor');
        }
        if (threshold !== undefined && threshold !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>threshold, 'threshold');
        }
        if (tacThreshold !== undefined && tacThreshold !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tacThreshold, 'tac_threshold');
        }
        if (percentileFiltering !== undefined && percentileFiltering !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>percentileFiltering, 'percentile_filtering');
        }
        if (fftSmoothing !== undefined && fftSmoothing !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>fftSmoothing, 'fft_smoothing');
        }
        if (rhCorrection !== undefined && rhCorrection !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>rhCorrection, 'rh_correction');
        }
        if (tempCorrection !== undefined && tempCorrection !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tempCorrection, 'temp_correction');
        }
        if (baselineCorrection !== undefined && baselineCorrection !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>baselineCorrection, 'baseline_correction');
        }
        if (deltaThreshold !== undefined && deltaThreshold !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>deltaThreshold, 'delta_threshold');
        }
        if (distanceBetweenPeaks !== undefined && distanceBetweenPeaks !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>distanceBetweenPeaks, 'distance_between_peaks');
        }
        if (savgolFilterWindow !== undefined && savgolFilterWindow !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>savgolFilterWindow, 'savgol_filter_window');
        }
        if (savgolFilterPolynomial !== undefined && savgolFilterPolynomial !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>savgolFilterPolynomial, 'savgol_filter_polynomial');
        }
        if (factorB1 !== undefined && factorB1 !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>factorB1, 'factor_b1');
        }
        if (factorA1 !== undefined && factorA1 !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>factorA1, 'factor_a1');
        }
        if (alpha !== undefined && alpha !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>alpha, 'alpha');
        }
        if (createdAt !== undefined && createdAt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>createdAt, 'created_at');
        }
        if (updatedAt !== undefined && updatedAt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>updatedAt, 'updated_at');
        }
        if (isGalvanic !== undefined && isGalvanic !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isGalvanic, 'is_galvanic');
        }
        if (select !== undefined && select !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>select, 'select');
        }
        if (order !== undefined && order !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>order, 'order');
        }
        if (offset !== undefined && offset !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>offset, 'offset');
        }
        if (limit !== undefined && limit !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>limit, 'limit');
        }

        let localVarHeaders = this.defaultHeaders;
        if (range !== undefined && range !== null) {
            localVarHeaders = localVarHeaders.set('Range', String(range));
        }
        if (rangeUnit !== undefined && rangeUnit !== null) {
            localVarHeaders = localVarHeaders.set('Range-Unit', String(rangeUnit));
        }
        if (prefer !== undefined && prefer !== null) {
            localVarHeaders = localVarHeaders.set('Prefer', String(prefer));
        }

        let localVarCredential: string | undefined;
        // authentication (JWT) required
        localVarCredential = this.configuration.lookupCredential('JWT');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
                'application/vnd.pgrst.object+json;nulls=stripped',
                'application/vnd.pgrst.object+json',
                'text/csv'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/algorithms`;
        return this.httpClient.request<Array<Algorithms>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public algorithmsPatch(requestParameters: AlgorithmsPatchRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public algorithmsPatch(requestParameters: AlgorithmsPatchRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public algorithmsPatch(requestParameters: AlgorithmsPatchRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public algorithmsPatch(requestParameters: AlgorithmsPatchRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const id = requestParameters.id;
        const name = requestParameters.name;
        const plotFrequency = requestParameters.plotFrequency;
        const useFahrenheit = requestParameters.useFahrenheit;
        const graphTac = requestParameters.graphTac;
        const graphEnvironment = requestParameters.graphEnvironment;
        const graphBaseline = requestParameters.graphBaseline;
        const graphBio = requestParameters.graphBio;
        const graphAmbient = requestParameters.graphAmbient;
        const graphTamper = requestParameters.graphTamper;
        const graphBattery = requestParameters.graphBattery;
        const minPeakHeight = requestParameters.minPeakHeight;
        const minPeakProminence = requestParameters.minPeakProminence;
        const minPeakHourHeight = requestParameters.minPeakHourHeight;
        const minPeakHalfHourHeight = requestParameters.minPeakHalfHourHeight;
        const maxPeakRiseSlope = requestParameters.maxPeakRiseSlope;
        const maxPeakFallSlope = requestParameters.maxPeakFallSlope;
        const excludeDqdAlcoholEvents = requestParameters.excludeDqdAlcoholEvents;
        const discardInitialDataHours = requestParameters.discardInitialDataHours;
        const maxTimeWithoutReading = requestParameters.maxTimeWithoutReading;
        const percentileFilterWindow = requestParameters.percentileFilterWindow;
        const flattenTac = requestParameters.flattenTac;
        const rhSlope1 = requestParameters.rhSlope1;
        const rhSlope2 = requestParameters.rhSlope2;
        const gainResistance = requestParameters.gainResistance;
        const correctionFactor5 = requestParameters.correctionFactor5;
        const negValuesToZero = requestParameters.negValuesToZero;
        const relativeHumiditySynthThreshold = requestParameters.relativeHumiditySynthThreshold;
        const tempCorrectionDateChunkSize = requestParameters.tempCorrectionDateChunkSize;
        const tempCorrectionRhRangeSize = requestParameters.tempCorrectionRhRangeSize;
        const pointsOfWindow = requestParameters.pointsOfWindow;
        const smoothFactor = requestParameters.smoothFactor;
        const threshold = requestParameters.threshold;
        const tacThreshold = requestParameters.tacThreshold;
        const percentileFiltering = requestParameters.percentileFiltering;
        const fftSmoothing = requestParameters.fftSmoothing;
        const rhCorrection = requestParameters.rhCorrection;
        const tempCorrection = requestParameters.tempCorrection;
        const baselineCorrection = requestParameters.baselineCorrection;
        const deltaThreshold = requestParameters.deltaThreshold;
        const distanceBetweenPeaks = requestParameters.distanceBetweenPeaks;
        const savgolFilterWindow = requestParameters.savgolFilterWindow;
        const savgolFilterPolynomial = requestParameters.savgolFilterPolynomial;
        const factorB1 = requestParameters.factorB1;
        const factorA1 = requestParameters.factorA1;
        const alpha = requestParameters.alpha;
        const createdAt = requestParameters.createdAt;
        const updatedAt = requestParameters.updatedAt;
        const isGalvanic = requestParameters.isGalvanic;
        const prefer = requestParameters.prefer;
        const algorithms = requestParameters.algorithms;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (id !== undefined && id !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>id, 'id');
        }
        if (name !== undefined && name !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>name, 'name');
        }
        if (plotFrequency !== undefined && plotFrequency !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>plotFrequency, 'plot_frequency');
        }
        if (useFahrenheit !== undefined && useFahrenheit !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>useFahrenheit, 'use_fahrenheit');
        }
        if (graphTac !== undefined && graphTac !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>graphTac, 'graph_tac');
        }
        if (graphEnvironment !== undefined && graphEnvironment !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>graphEnvironment, 'graph_environment');
        }
        if (graphBaseline !== undefined && graphBaseline !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>graphBaseline, 'graph_baseline');
        }
        if (graphBio !== undefined && graphBio !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>graphBio, 'graph_bio');
        }
        if (graphAmbient !== undefined && graphAmbient !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>graphAmbient, 'graph_ambient');
        }
        if (graphTamper !== undefined && graphTamper !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>graphTamper, 'graph_tamper');
        }
        if (graphBattery !== undefined && graphBattery !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>graphBattery, 'graph_battery');
        }
        if (minPeakHeight !== undefined && minPeakHeight !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>minPeakHeight, 'min_peak_height');
        }
        if (minPeakProminence !== undefined && minPeakProminence !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>minPeakProminence, 'min_peak_prominence');
        }
        if (minPeakHourHeight !== undefined && minPeakHourHeight !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>minPeakHourHeight, 'min_peak_hour_height');
        }
        if (minPeakHalfHourHeight !== undefined && minPeakHalfHourHeight !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>minPeakHalfHourHeight, 'min_peak_half_hour_height');
        }
        if (maxPeakRiseSlope !== undefined && maxPeakRiseSlope !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>maxPeakRiseSlope, 'max_peak_rise_slope');
        }
        if (maxPeakFallSlope !== undefined && maxPeakFallSlope !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>maxPeakFallSlope, 'max_peak_fall_slope');
        }
        if (excludeDqdAlcoholEvents !== undefined && excludeDqdAlcoholEvents !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>excludeDqdAlcoholEvents, 'exclude_dqd_alcohol_events');
        }
        if (discardInitialDataHours !== undefined && discardInitialDataHours !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>discardInitialDataHours, 'discard_initial_data_hours');
        }
        if (maxTimeWithoutReading !== undefined && maxTimeWithoutReading !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>maxTimeWithoutReading, 'max_time_without_reading');
        }
        if (percentileFilterWindow !== undefined && percentileFilterWindow !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>percentileFilterWindow, 'percentile_filter_window');
        }
        if (flattenTac !== undefined && flattenTac !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>flattenTac, 'flatten_tac');
        }
        if (rhSlope1 !== undefined && rhSlope1 !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>rhSlope1, 'rh_slope_1');
        }
        if (rhSlope2 !== undefined && rhSlope2 !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>rhSlope2, 'rh_slope_2');
        }
        if (gainResistance !== undefined && gainResistance !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>gainResistance, 'gain_resistance');
        }
        if (correctionFactor5 !== undefined && correctionFactor5 !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>correctionFactor5, 'correction_factor_5');
        }
        if (negValuesToZero !== undefined && negValuesToZero !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>negValuesToZero, 'neg_values_to_zero');
        }
        if (relativeHumiditySynthThreshold !== undefined && relativeHumiditySynthThreshold !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>relativeHumiditySynthThreshold, 'relative_humidity_synth_threshold');
        }
        if (tempCorrectionDateChunkSize !== undefined && tempCorrectionDateChunkSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tempCorrectionDateChunkSize, 'temp_correction_date_chunk_size');
        }
        if (tempCorrectionRhRangeSize !== undefined && tempCorrectionRhRangeSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tempCorrectionRhRangeSize, 'temp_correction_rh_range_size');
        }
        if (pointsOfWindow !== undefined && pointsOfWindow !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pointsOfWindow, 'points_of_window');
        }
        if (smoothFactor !== undefined && smoothFactor !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>smoothFactor, 'smooth_factor');
        }
        if (threshold !== undefined && threshold !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>threshold, 'threshold');
        }
        if (tacThreshold !== undefined && tacThreshold !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tacThreshold, 'tac_threshold');
        }
        if (percentileFiltering !== undefined && percentileFiltering !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>percentileFiltering, 'percentile_filtering');
        }
        if (fftSmoothing !== undefined && fftSmoothing !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>fftSmoothing, 'fft_smoothing');
        }
        if (rhCorrection !== undefined && rhCorrection !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>rhCorrection, 'rh_correction');
        }
        if (tempCorrection !== undefined && tempCorrection !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>tempCorrection, 'temp_correction');
        }
        if (baselineCorrection !== undefined && baselineCorrection !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>baselineCorrection, 'baseline_correction');
        }
        if (deltaThreshold !== undefined && deltaThreshold !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>deltaThreshold, 'delta_threshold');
        }
        if (distanceBetweenPeaks !== undefined && distanceBetweenPeaks !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>distanceBetweenPeaks, 'distance_between_peaks');
        }
        if (savgolFilterWindow !== undefined && savgolFilterWindow !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>savgolFilterWindow, 'savgol_filter_window');
        }
        if (savgolFilterPolynomial !== undefined && savgolFilterPolynomial !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>savgolFilterPolynomial, 'savgol_filter_polynomial');
        }
        if (factorB1 !== undefined && factorB1 !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>factorB1, 'factor_b1');
        }
        if (factorA1 !== undefined && factorA1 !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>factorA1, 'factor_a1');
        }
        if (alpha !== undefined && alpha !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>alpha, 'alpha');
        }
        if (createdAt !== undefined && createdAt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>createdAt, 'created_at');
        }
        if (updatedAt !== undefined && updatedAt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>updatedAt, 'updated_at');
        }
        if (isGalvanic !== undefined && isGalvanic !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isGalvanic, 'is_galvanic');
        }

        let localVarHeaders = this.defaultHeaders;
        if (prefer !== undefined && prefer !== null) {
            localVarHeaders = localVarHeaders.set('Prefer', String(prefer));
        }

        let localVarCredential: string | undefined;
        // authentication (JWT) required
        localVarCredential = this.configuration.lookupCredential('JWT');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/vnd.pgrst.object+json;nulls=stripped',
            'application/vnd.pgrst.object+json',
            'text/csv'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/algorithms`;
        return this.httpClient.request<any>('patch', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: algorithms,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public algorithmsPost(requestParameters: AlgorithmsPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public algorithmsPost(requestParameters: AlgorithmsPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public algorithmsPost(requestParameters: AlgorithmsPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public algorithmsPost(requestParameters: AlgorithmsPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const select = requestParameters.select;
        const prefer = requestParameters.prefer;
        const algorithms = requestParameters.algorithms;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (select !== undefined && select !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>select, 'select');
        }

        let localVarHeaders = this.defaultHeaders;
        if (prefer !== undefined && prefer !== null) {
            localVarHeaders = localVarHeaders.set('Prefer', String(prefer));
        }

        let localVarCredential: string | undefined;
        // authentication (JWT) required
        localVarCredential = this.configuration.lookupCredential('JWT');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/vnd.pgrst.object+json;nulls=stripped',
            'application/vnd.pgrst.object+json',
            'text/csv'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/algorithms`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: algorithms,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

}
