import { Component, input, output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { InputFieldComponent } from '@app/components/input-field/input-field.component';
import { AutoForm } from '@app/interfaces/auto-form.interface';
import { UserCreate } from '@app/models/user.interface';
import { toObservable } from '@angular/core/rxjs-interop';
import { RpcCreateUserPostRequest, RpcCreateUserPostRequestParams } from '@lib/core';

@Component({
  selector: 'app-user-create-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    InputFieldComponent,
  ],
  templateUrl: './form.component.html',
  styleUrl: './form.component.scss'
})
export class UserCreateFormComponent {
  initialValue = input<RpcCreateUserPostRequest>();
  formChange = output<RpcCreateUserPostRequest>();
  formValid = output<boolean>();

  userForm = new FormGroup<AutoForm<RpcCreateUserPostRequest>>({
    provider_id_in: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true }),
    display_name_in: new FormControl<string>('', { validators: [Validators.required], nonNullable: true}),
    email_in: new FormControl<string>('', { validators: [Validators.required, Validators.email], nonNullable: true}),
  });

  constructor() {
    toObservable(this.initialValue).subscribe((initial) => {
      if(initial) {
        Object.keys(initial).forEach(key => {
          // https://stackoverflow.com/a/62438434/3341745
          let prop = key as keyof typeof initial;
          if(initial[prop] != null) {
            this.userForm.get(key)?.setValue(initial[prop]);
          }
        });
      }
      this.onChange();
    });
  }

  onChange() {
    this.formValid.emit(this.userForm.valid);
    this.formChange.emit(<RpcCreateUserPostRequest>this.userForm.value);
  }
}