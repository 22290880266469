import { Injectable } from '@angular/core';
import { TamperEventDefaultParams as DefaultParams } from '@app/models/tamper-event-default-params.class';
import { TamperEvent, TamperEventCreate } from '@app/models/tamper-event.interface';
import { TamperEventsService } from '@lib/core';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TamperEventHelperService {

  constructor(
    private service: TamperEventsService,
  ) { }

  getAll(): Observable<TamperEvent[]> {
    let params = new DefaultParams();
    return this.service.tamperEventsGet(params)
      .pipe(map(x => <TamperEvent[]>x));
  }
  getById(id: number): Observable<TamperEvent> {
    let params = <any>new DefaultParams();
    params.id = 'eq.' + id;
    return this.service.tamperEventsGet(params)
      .pipe(map(x => <TamperEvent>x[0]));
  }
  create(input: TamperEventCreate) {
    return this.service.tamperEventsPost({
      tamperEvents: <any>input,
      prefer: "return=representation"
    }, 'response')
    .pipe(map(response => response.body[0]))
    .pipe(map(body => {
      //console.log(body)
      return (<TamperEvent>body).id;
    }));
  }
  update(id: number, input: TamperEventCreate) {
    return this.service.tamperEventsPatch({
      id: 'eq.'+id,
      tamperEvents: <any>input
    }, 'response')
    .pipe(map(response => response.ok));
  }
}
