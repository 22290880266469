import { Component, signal } from '@angular/core';
import { ProviderFormComponent } from "@app/components/forms/provider/form.component";
import { ProviderHelperService } from '@app/services/helpers/provider-helper.service';
import { Router, RouterModule } from '@angular/router';
import { AppHttpError } from '@app/interfaces/pg-models.interface';
import { ProviderCreate } from '@app/models/provider.interface';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { HeadMetaComponent } from '@app/components/ui.component';
import { ToastService } from '@app/services/toast.service';
import { HelpButtonComponent } from "@app/components/help-button/help-button.component";

@Component({
  selector: 'app-provider-create',
  standalone: true,
  templateUrl: './create.page.html',
  styleUrl: './create.page.scss',
  imports: [
    HeadMetaComponent,
    ProviderFormComponent,
    RouterModule,
    FontAwesomeModule,
    HelpButtonComponent
]
})
export class ProviderCreatePage {
  public title = 'New Provider';
  formValid = signal(false);
  formValue = signal<ProviderCreate | null>(null);

  faArrowLeft = faArrowLeft;

  constructor(
    private providerService: ProviderHelperService,
    private router: Router,
    private toast: ToastService,
  ) {
    
  }

  create() {
    let val = this.formValue();
    if(val) {
      this.providerService.create(val)
        .subscribe({
          next: id => {
            console.log(id)
            if(id) {
              this.router.navigateByUrl('/providers/' + id);
            }
          },
          error: (err: AppHttpError) => {
            console.log(err.error)
            this.toast.message({ message: err.error.code + ": " + err.error.message, type: 'error'});
          }
        });
    }
  }
}
