import { Component, input, output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { InputFieldComponent } from '@app/components/input-field/input-field.component';
import { AutoForm } from '@app/interfaces/auto-form.interface';
import { DeviceCreate } from '@app/models/device.interface';
import { toObservable } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-device-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    InputFieldComponent,
  ],
  templateUrl: './form.component.html',
  styleUrl: './form.component.scss'
})
export class DeviceFormComponent {
  initialValue = input<DeviceCreate>();
  formChange = output<DeviceCreate>();
  formValid = output<boolean>();

  deviceForm = new FormGroup<AutoForm<DeviceCreate>>({
    serial_number: new FormControl<string>('', { validators: [Validators.required], nonNullable: true }),
    active: new FormControl<boolean>(true, { nonNullable: true }),
    provider_id: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true }),
    device_status_id: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true }),
    sensor: new FormControl<string>('', { validators: [Validators.required], nonNullable: true }),
    primary_pcb: new FormControl<string>('', {  validators: [Validators.required], nonNullable: true }),
    pin: new FormControl<string>('', { validators: [Validators.required], nonNullable: true }),
    version_id: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true }),
    last_battery_level: new FormControl<number>(100, {nonNullable: true}),
    slope_e_test: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true }),
    baseline_current: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true }),
  });

  constructor() {
    toObservable(this.initialValue).subscribe((initial) => {
      if(initial) {
        Object.keys(initial).forEach(key => {
          // https://stackoverflow.com/a/62438434/3341745
          let prop = key as keyof typeof initial;
          if(initial[prop] != null) {
            this.deviceForm.get(key)?.setValue(initial[prop]);
          }
        });
      }
      this.onChange();
    });
  }

  onChange() {
    this.formValid.emit(this.deviceForm.valid);
    this.formChange.emit(<DeviceCreate>this.deviceForm.value);
  }
}