import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LoadingErrorBlockComponent } from '@app/components/loading-error-block/loading-error-block.component';
import { HeadMetaComponent } from '@app/components/ui.component';
import { SensorType } from '@app/models/sensor-type.interface';
import { AuthService } from '@app/services/auth.service';
import { SensorTypeHelperService } from '@app/services/helpers/sensor-type-helper.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { 
  faCirclePlus,
  faArrowLeft 
 } from '@fortawesome/free-solid-svg-icons';
import { LetDirective } from '@ngrx/component';
import { Observable } from 'rxjs';
import { HelpButtonComponent } from "@app/components/help-button/help-button.component";

@Component({
  selector: 'app-sensor-types',
  standalone: true,
  imports: [
    HeadMetaComponent,
    LetDirective,
    LoadingErrorBlockComponent,
    DatePipe,
    FontAwesomeModule,
    RouterModule,
    HelpButtonComponent
],
  templateUrl: './listing.page.html',
  styleUrl: './listing.page.scss'
})
export class SensorTypesPage {
  public title = 'Sensor Types';
  sensorTypes$: Observable<SensorType[]>;

  faCirclePlus = faCirclePlus;
  faArrowLeft = faArrowLeft;

  constructor(
    private sensorTypeHelper: SensorTypeHelperService,
    public auth: AuthService,
  ) {
    this.sensorTypes$ = this.sensorTypeHelper.getAll();
  }
}
