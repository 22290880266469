<head-meta [title]="this.title" />
<article>
    <div class="card w-full bg-base-100 shadow-xl">
        <div class="card-body">
            <ng-container *ngrxLet="rawSequenceItem$ as rawSequenceItem; error as e; complete as c">
                @if(rawSequenceItem) {
                    <div class="navbar bg-base-100">
                        <div class="flex-none">
                          <a routerLink="/raw-sequence-items" routerLinkActive="active" class="btn btn-square btn-ghost text-primary"><fa-icon [icon]="faArrowLeft" class="w-5"></fa-icon></a>
                        </div>
                        <div class="flex-1">
                          <h1 class="h1 ml-4">Raw Sequence Item Detail</h1>
                        </div>
                        <div class="flex-none">
                            @if(this.auth.has('device_status_update')) {
                                <button [routerLink]="'/raw-sequence-items/' + this.id + '/edit'" routerLinkActive="active" class="btn btn-ghost text-primary">
                                    <fa-icon [icon]="faPenToSquare" class="w-5"></fa-icon> Edit
                                </button>
                            }
                        </div>
                    </div>
                    <hr class="hr" />
                    <div class="overflow-hidden bg-white shadow sm:rounded-lg">
                        <div class="border-t border-gray-100">
                            <div>
                                <app-detail-display title="Sequence Id" [value]="rawSequenceItem.sequence_id.toString()" />
                            </div>
                            <div>
                                <app-detail-display title="Activity at" [value]="(rawSequenceItem.activity_at | date: 'medium') ?? ''" />
                            </div>
                            <div>
                                <app-detail-display title="Time of reading" [value]="(rawSequenceItem.time_of_reading | date: 'medium') ?? ''" />
                            </div>
                            <div>
                                <app-detail-display title="Readable timestamp" [value]="(rawSequenceItem.readable_timestamp | date: 'medium') ?? ''" />
                            </div>
                            <div>
                                <app-detail-display title="Flash timestamp" [value]="(rawSequenceItem.flash_timestamp | date: 'medium') ?? ''" />
                            </div>
                            <div>
                                <app-detail-display title="Delivered at" [value]="(rawSequenceItem.delivered_at | date: 'medium') ?? ''" />
                            </div>
                            <div>
                                <app-detail-display title="Vapor concentration" [value]="rawSequenceItem.vapor_concentration?.toString() ?? ''" />
                            </div>
                            <div>
                                <app-detail-display title="Temperature 1" [value]="rawSequenceItem.temperature_1?.toString() ?? ''" />
                            </div>
                            <div>
                                <app-detail-display title="Temperature 2" [value]="rawSequenceItem.temperature_2?.toString() ?? ''" />
                            </div>
                            <div>
                                <app-detail-display title="Relative Humidity 1" [value]="rawSequenceItem.relative_humidity_1?.toString() ?? ''" />
                            </div>
                            <div>
                                <app-detail-display title="Relative Humidity 2" [value]="rawSequenceItem.relative_humidity_2?.toString() ?? ''" />
                            </div>
                            <div>
                                <app-detail-display title="Ambient raw 1" [value]="rawSequenceItem.ambient_raw_1?.toString() ?? ''" />
                            </div>
                            <div>
                                <app-detail-display title="Ambient raw 2" [value]="rawSequenceItem.ambient_raw_2?.toString() ?? ''" />
                            </div>
                            <div>
                                <app-detail-display title="Bio 1" [value]="rawSequenceItem.bio_raw_1?.toString() ?? ''" />
                            </div>
                            <div>
                                <app-detail-display title="Bio 2" [value]="rawSequenceItem.bio_raw_2?.toString() ?? ''" />
                            </div>
                            <div>
                                <app-detail-display title="Is tamper?" [value]="rawSequenceItem.is_tamper ? 'Yes' : 'No'" />
                            </div>
                            <div>
                                <app-detail-display title="Battery level" [value]="rawSequenceItem.battery_level?.toString() ?? ''" />
                            </div>
                            <div>
                                <app-detail-display title="Raw payload" [value]="rawSequenceItem.raw_payload?.toString() ?? ''" />
                            </div>
                            
                            <dl class="divide-y divide-gray-100">
                                <div>
                                    <app-created-updated-display [entity]="rawSequenceItem" />
                                </div>
                            </dl>
                        </div>
                    </div>
                }
                <app-loading-error-block [error]="e?.error" [complete]="c" />
            </ng-container>
        </div>
    </div>
</article>