

<div class="overflow-hidden bg-white shadow sm:rounded-lg mb-4">
    <div class="border-t border-gray-100">
        <form [formGroup]="userForm" (input)="onChange()" (change)="onChange()">
            <div class="divide-y divide-gray-100">
                <div>
                    <app-input-field
                        controlName="display_name"
                        [form]="userForm"
                        label="Display name"
                        [errorMessages]="{ required: 'Display name is required'}"
                    />
                </div>
            </div>
        </form>
    </div>
</div>