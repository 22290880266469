

<div class="overflow-hidden bg-white shadow sm:rounded-lg mb-4">
    <div class="border-t border-gray-100">
        <form [formGroup]="userForm" (input)="onChange()" (change)="onChange()">
            <div class="divide-y divide-gray-100">
                <div>
                    <app-input-field
                        controlName="provider_id_in"
                        type="select|providers"
                        [form]="userForm"
                        label="Provider"
                        [errorMessages]="{ required: 'Provider is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="email_in"
                        [form]="userForm"
                        label="Email Address"
                        [errorMessages]="{ required: 'Email is required', email: 'Must be a valid Email address'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="display_name_in"
                        [form]="userForm"
                        label="Display name"
                        [errorMessages]="{ required: 'Display name is required'}"
                    />
                </div>
            </div>
        </form>
    </div>
</div>