

<div class="overflow-hidden bg-white shadow sm:rounded-lg mb-4">
    <div class="border-t border-gray-100">
        <form [formGroup]="providerForm" (input)="onChange()" (change)="onChange()">
            <div class="divide-y divide-gray-100">
                <div>
                    <app-input-field
                        controlName="name"
                        [form]="providerForm"
                        label="Provider Name"
                        [errorMessages]="{ required: 'Name is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="active"
                        type="toggle"
                        [form]="providerForm"
                        label="Active?"
                    />
                </div>
            </div>
        </form>
    </div>
</div>