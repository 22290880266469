import { Injectable } from '@angular/core';
import { WebhookDefaultParams as DefaultParams } from '@app/models/webhook-default-params.class';
import { Webhook, WebhookCreate } from '@app/models/webhook.interface';
import { WebhooksService } from '@lib/core';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WebhookHelperService {

  constructor(
    private service: WebhooksService,
  ) { }

  getAll(): Observable<Webhook[]> {
    let params = new DefaultParams();
    return this.service.webhooksGet(params)
      .pipe(map(x => <Webhook[]>x));
  }
  getById(id: number): Observable<Webhook> {
    let params = <any>new DefaultParams();
    params.id = 'eq.' + id;
    return this.service.webhooksGet(params)
      .pipe(map(x => <Webhook>x[0]));
  }
  create(input: WebhookCreate) {
    return this.service.webhooksPost({
      webhooks: <any>input,
      prefer: "return=representation"
    }, 'response')
    .pipe(map(response => response.body[0]))
    .pipe(map(body => {
      //console.log(body)
      return (<Webhook>body).id;
    }));
  }
  update(id: number, input: WebhookCreate) {
    return this.service.webhooksPatch({
      id: 'eq.'+id,
      webhooks: <any>input
    }, 'response')
    .pipe(map(response => response.ok));
  }
}
