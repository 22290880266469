import { DevicesGetRequestParams } from "@lib/core";

export interface DeviceDefaultParams extends DevicesGetRequestParams { }
export class DeviceDefaultParams {
  constructor() {
    this.select = "*,\
      provider:providers(*),\
      version:versions(*),\
      device_status:device_statuses(*)";
    this.order = "id.asc";
  }
}