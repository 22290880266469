import { Injectable } from '@angular/core';
import { ProcessedSequenceItemDefaultParams as DefaultParams } from '@app/models/processed-sequence-item-default-params.class';
import { ProcessedSequenceItem, ProcessedSequenceItemCreate } from '@app/models/processed-sequence-item.interface';
import { ProcessedSequenceItemsService } from '@lib/core';
import { Observable, map } from 'rxjs';
import { PostgrestQueryHelper } from './postgrest-query-helper.service';

@Injectable({
  providedIn: 'root'
})
export class ProcessedSequenceItemHelperService {

  constructor(
    private service: ProcessedSequenceItemsService,
    private pg: PostgrestQueryHelper,
  ) { }

  getAll(): Observable<ProcessedSequenceItem[]> {
    let params = new DefaultParams();
    return this.service.processedSequenceItemsGet(params)
      .pipe(map(x => <ProcessedSequenceItem[]>x));
  }
  getById(id: number): Observable<ProcessedSequenceItem> {
    let params = <any>new DefaultParams();
    params.id = 'eq.' + id;
    return this.service.processedSequenceItemsGet(params)
      .pipe(map(x => <ProcessedSequenceItem>x[0]));
  }
  exportBySequenceId(sequence_id: number) {
    var params = this.pg.defaultParams();
    params = this.pg.addToHttpParams(params, '*', 'select');
    params = this.pg.addToHttpParams(params, 'eq.' + sequence_id, 'sequence_id');
    return this.pg.get<string>('processed_sequence_items', params, {httpHeaderAccept: 'text/csv'});
  }
  create(input: ProcessedSequenceItemCreate) {
    return this.service.processedSequenceItemsPost({
      processedSequenceItems: <any>input,
      prefer: "return=representation"
    }, 'response')
    .pipe(map(response => response.body[0]))
    .pipe(map(body => {
      //console.log(body)
      return (<ProcessedSequenceItem>body).id;
    }));
  }
  update(id: number, input: ProcessedSequenceItemCreate) {
    return this.service.processedSequenceItemsPatch({
      id: 'eq.'+id,
      processedSequenceItems: <any>input
    }, 'response')
    .pipe(map(response => response.ok));
  }
}
