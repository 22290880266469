<head-meta [title]="this.title"></head-meta>
<article>
    <div class="card w-full bg-base-100 shadow-xl">
        <div class="card-body">
            <div class="navbar bg-base-100">
                <div class="flex-none">
                </div>
                <div class="flex-1">
                  <h1 class="h1">{{this.title}}</h1>
                </div>
                <div class="flex-none">
                    @if(this.auth.has('device_status_create')) {
                        <button routerLink="/processed-sequence-items/create" routerLinkActive="active" class="btn btn-ghost text-primary"><fa-icon [icon]="faCirclePlus" class="w-5"></fa-icon> Add</button>
                    }
                </div>
            </div>
            <hr class="hr" />
            <table class="table table-zebra table-pin-rows">
                <ng-container *ngrxLet="processedSequenceItems$ as processedSequenceItems; error as e; complete as c">
                    <app-loading-error-block [error]="e?.error" [complete]="c" />
                    @if(c) {
                        <thead>
                            <th>Id</th>
                            <th>Sequence Id</th>
                        </thead>
                        <tbody>
                            @if(processedSequenceItems.length > 0) {
                                @for (processedSequenceItem of processedSequenceItems; track $index) {
                                    <tr [routerLink]="'/processed-sequence-items/' + processedSequenceItem.id" class="cursor-pointer">
                                        <td>{{ processedSequenceItem.id }}</td>
                                        <td>{{ processedSequenceItem.sequence_id }}</td>
                                    </tr>
                                }
                            } @else {
                                <tr>
                                    <td class="text-center" colspan="2">
                                        <span class="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center">
                                            <span class="mt-2 block text-sm text-gray-400">No Processed Sequence Items</span>
                                        </span>
                                    </td>
                                </tr>
                            }
                        </tbody>
                    }
                </ng-container>
            </table>
        </div>
    </div>
</article>