import { Component, input, output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { InputFieldComponent } from '@app/components/input-field/input-field.component';
import { AutoForm } from '@app/interfaces/auto-form.interface';
import { ProcessedSequenceItemCreate } from '@app/models/processed-sequence-item.interface';
import { toObservable } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-processed-sequence-item-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    InputFieldComponent,
  ],
  templateUrl: './form.component.html',
  styleUrl: './form.component.scss'
})
export class ProcessedSequenceItemFormComponent {
  initialValue = input<ProcessedSequenceItemCreate>();
  formChange = output<ProcessedSequenceItemCreate>();
  formValid = output<boolean>();

  processedSequenceItemForm = new FormGroup<AutoForm<ProcessedSequenceItemCreate>>({
    sequence_id: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true}),
    raw_sequence_item_id: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true}),
    activity_at: new FormControl<string>('', { validators: [Validators.required], nonNullable: true}),
    smoothed_voltage: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true}),
    smoothed_temp_1: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true}),
    smoothed_temp_2: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true}),
    smoothed_humidity_1: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true}),
    smoothed_humidity_2: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true}),
    ambient_sensor_1: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true}),
    ambient_sensor_2: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true}),
    ir_sensor_1: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true}),
    ir_sensor_2: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true}),
    slope_1: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true}),
    slope_2: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true}),
    final_graphene_sensor_data: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true}),
    baseline_graphene_sensor_data: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true}),
    smoothed_graphene_sensor_data: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true}),
    tac_value: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true}),
    set_to_zero_threshold: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true}),
    final_suppressed_data: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true}),
    is_tamper: new FormControl<boolean>(false, { validators: [Validators.required], nonNullable: true}),
    battery_level: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true}),
    time_interval: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true}),
  });

  constructor() {
    toObservable(this.initialValue).subscribe((initial) => {
      if(initial) {
        Object.keys(initial).forEach(key => {
          // https://stackoverflow.com/a/62438434/3341745
          let prop = key as keyof typeof initial;
          if(initial[prop] != null) {
            this.processedSequenceItemForm.get(key)?.setValue(initial[prop]);
          }
        });
      }
      this.onChange();
    });
  }

  onChange() {
    this.formValid.emit(this.processedSequenceItemForm.valid);
    this.formChange.emit(<ProcessedSequenceItemCreate>this.processedSequenceItemForm.value);
  }
}