import { Injectable } from '@angular/core';
import { PeakEventDefaultParams as DefaultParams } from '@app/models/peak-event-default-params.class';
import { PeakEvent, PeakEventCreate } from '@app/models/peak-event.interface';
import { PeakEventsService } from '@lib/core';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PeakEventHelperService {

  constructor(
    private service: PeakEventsService,
  ) { }

  getAll(): Observable<PeakEvent[]> {
    let params = new DefaultParams();
    return this.service.peakEventsGet(params)
      .pipe(map(x => <PeakEvent[]>x));
  }
  getById(id: number): Observable<PeakEvent> {
    let params = <any>new DefaultParams();
    params.id = 'eq.' + id;
    return this.service.peakEventsGet(params)
      .pipe(map(x => <PeakEvent>x[0]));
  }
  create(input: PeakEventCreate) {
    return this.service.peakEventsPost({
      peakEvents: <any>input,
      prefer: "return=representation"
    }, 'response')
    .pipe(map(response => response.body[0]))
    .pipe(map(body => {
      //console.log(body)
      return (<PeakEvent>body).id;
    }));
  }
  update(id: number, input: PeakEventCreate) {
    return this.service.peakEventsPatch({
      id: 'eq.'+id,
      peakEvents: <any>input
    }, 'response')
    .pipe(map(response => response.ok));
  }
}
