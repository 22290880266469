/**
 * Arborsense API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 12.2.3 (519615d)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { RawSequenceItems } from '../model/rawSequenceItems';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface RawSequenceItemsDeleteRequestParams {
    id?: string;
    sequenceId?: string;
    activityAt?: string;
    timeOfReading?: string;
    readableTimestamp?: string;
    flashTimestamp?: string;
    deliveredAt?: string;
    vaporConcentration?: string;
    temperature1?: string;
    temperature2?: string;
    relativeHumidity1?: string;
    relativeHumidity2?: string;
    ambientRaw1?: string;
    ambientRaw2?: string;
    bioRaw1?: string;
    bioRaw2?: string;
    isTamper?: string;
    batteryLevel?: string;
    rawPayload?: string;
    createdAt?: string;
    updatedAt?: string;
    /** Preference */
    prefer?: 'return=representation' | 'return=minimal' | 'return=none';
}

export interface RawSequenceItemsGetRequestParams {
    id?: string;
    sequenceId?: string;
    activityAt?: string;
    timeOfReading?: string;
    readableTimestamp?: string;
    flashTimestamp?: string;
    deliveredAt?: string;
    vaporConcentration?: string;
    temperature1?: string;
    temperature2?: string;
    relativeHumidity1?: string;
    relativeHumidity2?: string;
    ambientRaw1?: string;
    ambientRaw2?: string;
    bioRaw1?: string;
    bioRaw2?: string;
    isTamper?: string;
    batteryLevel?: string;
    rawPayload?: string;
    createdAt?: string;
    updatedAt?: string;
    /** Filtering Columns */
    select?: string;
    /** Ordering */
    order?: string;
    /** Limiting and Pagination */
    range?: string;
    /** Limiting and Pagination */
    rangeUnit?: string;
    /** Limiting and Pagination */
    offset?: string;
    /** Limiting and Pagination */
    limit?: string;
    /** Preference */
    prefer?: 'count=none';
}

export interface RawSequenceItemsPatchRequestParams {
    id?: string;
    sequenceId?: string;
    activityAt?: string;
    timeOfReading?: string;
    readableTimestamp?: string;
    flashTimestamp?: string;
    deliveredAt?: string;
    vaporConcentration?: string;
    temperature1?: string;
    temperature2?: string;
    relativeHumidity1?: string;
    relativeHumidity2?: string;
    ambientRaw1?: string;
    ambientRaw2?: string;
    bioRaw1?: string;
    bioRaw2?: string;
    isTamper?: string;
    batteryLevel?: string;
    rawPayload?: string;
    createdAt?: string;
    updatedAt?: string;
    /** Preference */
    prefer?: 'return=representation' | 'return=minimal' | 'return=none';
    /** raw_sequence_items */
    rawSequenceItems?: RawSequenceItems;
}

export interface RawSequenceItemsPostRequestParams {
    /** Filtering Columns */
    select?: string;
    /** Preference */
    prefer?: 'return=representation' | 'return=minimal' | 'return=none' | 'resolution=ignore-duplicates' | 'resolution=merge-duplicates';
    /** raw_sequence_items */
    rawSequenceItems?: RawSequenceItems;
}


@Injectable({
  providedIn: 'root'
})
export class RawSequenceItemsService {

    protected basePath = 'http://0.0.0.0:3000';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public rawSequenceItemsDelete(requestParameters: RawSequenceItemsDeleteRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public rawSequenceItemsDelete(requestParameters: RawSequenceItemsDeleteRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public rawSequenceItemsDelete(requestParameters: RawSequenceItemsDeleteRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public rawSequenceItemsDelete(requestParameters: RawSequenceItemsDeleteRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const id = requestParameters.id;
        const sequenceId = requestParameters.sequenceId;
        const activityAt = requestParameters.activityAt;
        const timeOfReading = requestParameters.timeOfReading;
        const readableTimestamp = requestParameters.readableTimestamp;
        const flashTimestamp = requestParameters.flashTimestamp;
        const deliveredAt = requestParameters.deliveredAt;
        const vaporConcentration = requestParameters.vaporConcentration;
        const temperature1 = requestParameters.temperature1;
        const temperature2 = requestParameters.temperature2;
        const relativeHumidity1 = requestParameters.relativeHumidity1;
        const relativeHumidity2 = requestParameters.relativeHumidity2;
        const ambientRaw1 = requestParameters.ambientRaw1;
        const ambientRaw2 = requestParameters.ambientRaw2;
        const bioRaw1 = requestParameters.bioRaw1;
        const bioRaw2 = requestParameters.bioRaw2;
        const isTamper = requestParameters.isTamper;
        const batteryLevel = requestParameters.batteryLevel;
        const rawPayload = requestParameters.rawPayload;
        const createdAt = requestParameters.createdAt;
        const updatedAt = requestParameters.updatedAt;
        const prefer = requestParameters.prefer;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (id !== undefined && id !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>id, 'id');
        }
        if (sequenceId !== undefined && sequenceId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sequenceId, 'sequence_id');
        }
        if (activityAt !== undefined && activityAt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>activityAt, 'activity_at');
        }
        if (timeOfReading !== undefined && timeOfReading !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>timeOfReading, 'time_of_reading');
        }
        if (readableTimestamp !== undefined && readableTimestamp !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>readableTimestamp, 'readable_timestamp');
        }
        if (flashTimestamp !== undefined && flashTimestamp !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>flashTimestamp, 'flash_timestamp');
        }
        if (deliveredAt !== undefined && deliveredAt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>deliveredAt, 'delivered_at');
        }
        if (vaporConcentration !== undefined && vaporConcentration !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>vaporConcentration, 'vapor_concentration');
        }
        if (temperature1 !== undefined && temperature1 !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>temperature1, 'temperature_1');
        }
        if (temperature2 !== undefined && temperature2 !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>temperature2, 'temperature_2');
        }
        if (relativeHumidity1 !== undefined && relativeHumidity1 !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>relativeHumidity1, 'relative_humidity_1');
        }
        if (relativeHumidity2 !== undefined && relativeHumidity2 !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>relativeHumidity2, 'relative_humidity_2');
        }
        if (ambientRaw1 !== undefined && ambientRaw1 !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>ambientRaw1, 'ambient_raw_1');
        }
        if (ambientRaw2 !== undefined && ambientRaw2 !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>ambientRaw2, 'ambient_raw_2');
        }
        if (bioRaw1 !== undefined && bioRaw1 !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>bioRaw1, 'bio_raw_1');
        }
        if (bioRaw2 !== undefined && bioRaw2 !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>bioRaw2, 'bio_raw_2');
        }
        if (isTamper !== undefined && isTamper !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isTamper, 'is_tamper');
        }
        if (batteryLevel !== undefined && batteryLevel !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>batteryLevel, 'battery_level');
        }
        if (rawPayload !== undefined && rawPayload !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>rawPayload, 'raw_payload');
        }
        if (createdAt !== undefined && createdAt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>createdAt, 'created_at');
        }
        if (updatedAt !== undefined && updatedAt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>updatedAt, 'updated_at');
        }

        let localVarHeaders = this.defaultHeaders;
        if (prefer !== undefined && prefer !== null) {
            localVarHeaders = localVarHeaders.set('Prefer', String(prefer));
        }

        let localVarCredential: string | undefined;
        // authentication (JWT) required
        localVarCredential = this.configuration.lookupCredential('JWT');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/raw_sequence_items`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public rawSequenceItemsGet(requestParameters: RawSequenceItemsGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/vnd.pgrst.object+json;nulls=stripped' | 'application/vnd.pgrst.object+json' | 'text/csv', context?: HttpContext, transferCache?: boolean}): Observable<Array<RawSequenceItems>>;
    public rawSequenceItemsGet(requestParameters: RawSequenceItemsGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/vnd.pgrst.object+json;nulls=stripped' | 'application/vnd.pgrst.object+json' | 'text/csv', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Array<RawSequenceItems>>>;
    public rawSequenceItemsGet(requestParameters: RawSequenceItemsGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/vnd.pgrst.object+json;nulls=stripped' | 'application/vnd.pgrst.object+json' | 'text/csv', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Array<RawSequenceItems>>>;
    public rawSequenceItemsGet(requestParameters: RawSequenceItemsGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'application/vnd.pgrst.object+json;nulls=stripped' | 'application/vnd.pgrst.object+json' | 'text/csv', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const id = requestParameters.id;
        const sequenceId = requestParameters.sequenceId;
        const activityAt = requestParameters.activityAt;
        const timeOfReading = requestParameters.timeOfReading;
        const readableTimestamp = requestParameters.readableTimestamp;
        const flashTimestamp = requestParameters.flashTimestamp;
        const deliveredAt = requestParameters.deliveredAt;
        const vaporConcentration = requestParameters.vaporConcentration;
        const temperature1 = requestParameters.temperature1;
        const temperature2 = requestParameters.temperature2;
        const relativeHumidity1 = requestParameters.relativeHumidity1;
        const relativeHumidity2 = requestParameters.relativeHumidity2;
        const ambientRaw1 = requestParameters.ambientRaw1;
        const ambientRaw2 = requestParameters.ambientRaw2;
        const bioRaw1 = requestParameters.bioRaw1;
        const bioRaw2 = requestParameters.bioRaw2;
        const isTamper = requestParameters.isTamper;
        const batteryLevel = requestParameters.batteryLevel;
        const rawPayload = requestParameters.rawPayload;
        const createdAt = requestParameters.createdAt;
        const updatedAt = requestParameters.updatedAt;
        const select = requestParameters.select;
        const order = requestParameters.order;
        const range = requestParameters.range;
        const rangeUnit = requestParameters.rangeUnit;
        const offset = requestParameters.offset;
        const limit = requestParameters.limit;
        const prefer = requestParameters.prefer;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (id !== undefined && id !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>id, 'id');
        }
        if (sequenceId !== undefined && sequenceId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sequenceId, 'sequence_id');
        }
        if (activityAt !== undefined && activityAt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>activityAt, 'activity_at');
        }
        if (timeOfReading !== undefined && timeOfReading !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>timeOfReading, 'time_of_reading');
        }
        if (readableTimestamp !== undefined && readableTimestamp !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>readableTimestamp, 'readable_timestamp');
        }
        if (flashTimestamp !== undefined && flashTimestamp !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>flashTimestamp, 'flash_timestamp');
        }
        if (deliveredAt !== undefined && deliveredAt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>deliveredAt, 'delivered_at');
        }
        if (vaporConcentration !== undefined && vaporConcentration !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>vaporConcentration, 'vapor_concentration');
        }
        if (temperature1 !== undefined && temperature1 !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>temperature1, 'temperature_1');
        }
        if (temperature2 !== undefined && temperature2 !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>temperature2, 'temperature_2');
        }
        if (relativeHumidity1 !== undefined && relativeHumidity1 !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>relativeHumidity1, 'relative_humidity_1');
        }
        if (relativeHumidity2 !== undefined && relativeHumidity2 !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>relativeHumidity2, 'relative_humidity_2');
        }
        if (ambientRaw1 !== undefined && ambientRaw1 !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>ambientRaw1, 'ambient_raw_1');
        }
        if (ambientRaw2 !== undefined && ambientRaw2 !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>ambientRaw2, 'ambient_raw_2');
        }
        if (bioRaw1 !== undefined && bioRaw1 !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>bioRaw1, 'bio_raw_1');
        }
        if (bioRaw2 !== undefined && bioRaw2 !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>bioRaw2, 'bio_raw_2');
        }
        if (isTamper !== undefined && isTamper !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isTamper, 'is_tamper');
        }
        if (batteryLevel !== undefined && batteryLevel !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>batteryLevel, 'battery_level');
        }
        if (rawPayload !== undefined && rawPayload !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>rawPayload, 'raw_payload');
        }
        if (createdAt !== undefined && createdAt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>createdAt, 'created_at');
        }
        if (updatedAt !== undefined && updatedAt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>updatedAt, 'updated_at');
        }
        if (select !== undefined && select !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>select, 'select');
        }
        if (order !== undefined && order !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>order, 'order');
        }
        if (offset !== undefined && offset !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>offset, 'offset');
        }
        if (limit !== undefined && limit !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>limit, 'limit');
        }

        let localVarHeaders = this.defaultHeaders;
        if (range !== undefined && range !== null) {
            localVarHeaders = localVarHeaders.set('Range', String(range));
        }
        if (rangeUnit !== undefined && rangeUnit !== null) {
            localVarHeaders = localVarHeaders.set('Range-Unit', String(rangeUnit));
        }
        if (prefer !== undefined && prefer !== null) {
            localVarHeaders = localVarHeaders.set('Prefer', String(prefer));
        }

        let localVarCredential: string | undefined;
        // authentication (JWT) required
        localVarCredential = this.configuration.lookupCredential('JWT');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
                'application/vnd.pgrst.object+json;nulls=stripped',
                'application/vnd.pgrst.object+json',
                'text/csv'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/raw_sequence_items`;
        return this.httpClient.request<Array<RawSequenceItems>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public rawSequenceItemsPatch(requestParameters: RawSequenceItemsPatchRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public rawSequenceItemsPatch(requestParameters: RawSequenceItemsPatchRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public rawSequenceItemsPatch(requestParameters: RawSequenceItemsPatchRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public rawSequenceItemsPatch(requestParameters: RawSequenceItemsPatchRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const id = requestParameters.id;
        const sequenceId = requestParameters.sequenceId;
        const activityAt = requestParameters.activityAt;
        const timeOfReading = requestParameters.timeOfReading;
        const readableTimestamp = requestParameters.readableTimestamp;
        const flashTimestamp = requestParameters.flashTimestamp;
        const deliveredAt = requestParameters.deliveredAt;
        const vaporConcentration = requestParameters.vaporConcentration;
        const temperature1 = requestParameters.temperature1;
        const temperature2 = requestParameters.temperature2;
        const relativeHumidity1 = requestParameters.relativeHumidity1;
        const relativeHumidity2 = requestParameters.relativeHumidity2;
        const ambientRaw1 = requestParameters.ambientRaw1;
        const ambientRaw2 = requestParameters.ambientRaw2;
        const bioRaw1 = requestParameters.bioRaw1;
        const bioRaw2 = requestParameters.bioRaw2;
        const isTamper = requestParameters.isTamper;
        const batteryLevel = requestParameters.batteryLevel;
        const rawPayload = requestParameters.rawPayload;
        const createdAt = requestParameters.createdAt;
        const updatedAt = requestParameters.updatedAt;
        const prefer = requestParameters.prefer;
        const rawSequenceItems = requestParameters.rawSequenceItems;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (id !== undefined && id !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>id, 'id');
        }
        if (sequenceId !== undefined && sequenceId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sequenceId, 'sequence_id');
        }
        if (activityAt !== undefined && activityAt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>activityAt, 'activity_at');
        }
        if (timeOfReading !== undefined && timeOfReading !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>timeOfReading, 'time_of_reading');
        }
        if (readableTimestamp !== undefined && readableTimestamp !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>readableTimestamp, 'readable_timestamp');
        }
        if (flashTimestamp !== undefined && flashTimestamp !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>flashTimestamp, 'flash_timestamp');
        }
        if (deliveredAt !== undefined && deliveredAt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>deliveredAt, 'delivered_at');
        }
        if (vaporConcentration !== undefined && vaporConcentration !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>vaporConcentration, 'vapor_concentration');
        }
        if (temperature1 !== undefined && temperature1 !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>temperature1, 'temperature_1');
        }
        if (temperature2 !== undefined && temperature2 !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>temperature2, 'temperature_2');
        }
        if (relativeHumidity1 !== undefined && relativeHumidity1 !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>relativeHumidity1, 'relative_humidity_1');
        }
        if (relativeHumidity2 !== undefined && relativeHumidity2 !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>relativeHumidity2, 'relative_humidity_2');
        }
        if (ambientRaw1 !== undefined && ambientRaw1 !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>ambientRaw1, 'ambient_raw_1');
        }
        if (ambientRaw2 !== undefined && ambientRaw2 !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>ambientRaw2, 'ambient_raw_2');
        }
        if (bioRaw1 !== undefined && bioRaw1 !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>bioRaw1, 'bio_raw_1');
        }
        if (bioRaw2 !== undefined && bioRaw2 !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>bioRaw2, 'bio_raw_2');
        }
        if (isTamper !== undefined && isTamper !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isTamper, 'is_tamper');
        }
        if (batteryLevel !== undefined && batteryLevel !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>batteryLevel, 'battery_level');
        }
        if (rawPayload !== undefined && rawPayload !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>rawPayload, 'raw_payload');
        }
        if (createdAt !== undefined && createdAt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>createdAt, 'created_at');
        }
        if (updatedAt !== undefined && updatedAt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>updatedAt, 'updated_at');
        }

        let localVarHeaders = this.defaultHeaders;
        if (prefer !== undefined && prefer !== null) {
            localVarHeaders = localVarHeaders.set('Prefer', String(prefer));
        }

        let localVarCredential: string | undefined;
        // authentication (JWT) required
        localVarCredential = this.configuration.lookupCredential('JWT');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/vnd.pgrst.object+json;nulls=stripped',
            'application/vnd.pgrst.object+json',
            'text/csv'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/raw_sequence_items`;
        return this.httpClient.request<any>('patch', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: rawSequenceItems,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public rawSequenceItemsPost(requestParameters: RawSequenceItemsPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public rawSequenceItemsPost(requestParameters: RawSequenceItemsPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public rawSequenceItemsPost(requestParameters: RawSequenceItemsPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public rawSequenceItemsPost(requestParameters: RawSequenceItemsPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const select = requestParameters.select;
        const prefer = requestParameters.prefer;
        const rawSequenceItems = requestParameters.rawSequenceItems;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (select !== undefined && select !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>select, 'select');
        }

        let localVarHeaders = this.defaultHeaders;
        if (prefer !== undefined && prefer !== null) {
            localVarHeaders = localVarHeaders.set('Prefer', String(prefer));
        }

        let localVarCredential: string | undefined;
        // authentication (JWT) required
        localVarCredential = this.configuration.lookupCredential('JWT');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/vnd.pgrst.object+json;nulls=stripped',
            'application/vnd.pgrst.object+json',
            'text/csv'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/raw_sequence_items`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: rawSequenceItems,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

}
