<head-meta title="Dashboard"></head-meta>
<app-help-button src="/assets/help/dashboard.md" />
<article>
    <div class="card w-full bg-base-100 shadow-xl">
        <div class="card-body">
            <h1 class="h1">Dashboard</h1>
            <hr class="hr" />
            <p>Welcome to Arborsense Cloud Platform!</p>

            @if(this.auth.has('stats_read')){
                <form [formGroup]="this.searchForm">
                  <div class="form-control -mt-10">
                      <div class="ml-4 input-group flex justify-end gap-2">
                        <ng-container *ngrxLet="providers$ as providers; error as e; complete as c">
                            @if(c) {
                                @if(providers && providers.length > 0) {
                                    <select class="input input-bordered input-md  w-40 hidden xl:flex" formControlName="provider_id" name="provider_id">
                                        <option value="null">Any Provider</option>
                                        @for (provider of providers; track $index) {
                                            <option [value]="provider.id">{{ provider.name }}</option>
                                        }
                                    </select>
                                    <button class="btn btn-square btn-md" [disabled]="!this.searchForm.valid" (click)="this.submit()">
                                        <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="h-6 w-6"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor">
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                        </svg>
                                    </button>
                                }
                            }
                        </ng-container>
                      </div>
                  </div>
                </form>
                <ng-container *ngrxLet="stats$ as stats; error as e; complete as c">
                    <app-loading-error-block [error]="e?.error" [complete]="c" />
                    <section class="stats stats-vertical grid-cols-2 md:grid-cols-4 xl:grid-cols-8 w-full shadow-md xl:stats-horizontal">
                        <div class="col-span-2 stat min-w-48 lg:min-w-24">
                            <div class="stat-figure text-neutral">
                                <fa-icon [icon]="faRing" class="w-5"></fa-icon>
                            </div>
                            <div class="stat-title text-accent">Devices</div>
                            <div class="stat-value text-primary">{{ stats.count_devices }}</div>
                            <div class="stat-desc">available &amp; in use</div>
                        </div>
                        <div class="col-span-2 stat min-w-48 lg:min-w-24">
                            <div class="stat-figure text-neutral">
                                <fa-icon [icon]="faHourglass" class="w-5"></fa-icon>
                            </div>
                            <div class="stat-title text-accent">Sequences</div>
                            <div class="stat-value text-primary">{{ stats.count_active_sequences }}</div>
                            <div class="stat-desc">currently active</div>
                        </div>
                        <div class="col-span-2 stat min-w-48 lg:min-w-24">
                            <div class="stat-figure text-neutral">
                                <fa-icon [icon]="faTriangleExclamation" class="w-5"></fa-icon>
                            </div>
                            <div class="stat-title text-accent">Alerts Generated</div>
                            <div class="stat-value text-primary">{{ stats.count_sequence_alerts }}</div>
                            <div class="stat-desc">for active sequences</div>
                        </div>
                        <div class="col-span-2 stat min-w-48 lg:min-w-24">
                            <div class="stat-figure text-neutral">
                                <fa-icon [icon]="faCodeBranch" class="w-5"></fa-icon>
                            </div>
                            <div class="stat-title text-accent">Webhooks Delivered</div>
                            <div class="stat-value text-primary">{{ stats.count_delivered_webhooks }}</div>
                            <div class="stat-desc">for active sequences</div>
                        </div>
                    </section>
                    @if(stats.count_processed_sequence_items > 0){
                        <section class="stats stats-vertical grid-cols-2 md:grid-cols-4 xl:grid-cols-8 w-full shadow-md xl:stats-horizontal">
                            <div class="col-span-2 stat min-w-48 lg:min-w-24">
                                <div class="stat-figure text-neutral">
                                    <fa-icon [icon]="faCloud" class="w-5"></fa-icon>
                                </div>
                                <div class="stat-title text-accent">Total Points Recorded</div>
                                <div class="stat-value text-primary">{{ stats.count_processed_sequence_items }}</div>
                                <div class="stat-desc">for active sequences</div>
                            </div>
                            <div class="col-span-2 stat min-w-48 lg:min-w-24">
                                <div class="stat-figure text-neutral">
                                    <fa-icon [icon]="faThumbsUp" class="w-5"></fa-icon>
                                </div>
                                <div class="stat-title text-accent">Verified Sober Hours</div>
                                <div class="stat-value text-primary">{{ stats.count_sober_hours | number:'1.0':'en-US' }}</div>
                                <div class="stat-desc">for active sequences</div>
                            </div>
                            <div class="col-span-2 stat min-w-48 lg:min-w-24">
                                <div class="stat-figure text-neutral">
                                    <fa-icon [icon]="faCloudArrowUp" class="w-5"></fa-icon>
                                </div>
                                <div class="stat-title text-accent">Total Recorded Hours</div>
                                <div class="stat-value text-primary">{{ stats.count_total_hours | number:'1.0':'en-US' }}</div>
                                <div class="stat-desc">for active sequences</div>
                            </div>
                            <div class="col-span-2 stat min-w-48 lg:min-w-24">
                                <div class="stat-figure text-neutral">
                                    <fa-icon [icon]="faCalculator" class="w-5"></fa-icon>
                                </div>
                                <div class="stat-title text-accent">Verified Sober Percent</div>
                                <div class="stat-value text-primary">{{ ((stats.count_sober_hours / stats.count_total_hours) * 100) | number:'1.2-2' }}%</div>
                                <div class="stat-desc">for active sequences</div>
                            </div>
                        </section>
                    }
                    <p><small class="float-right text-gray-500"><cite>*</cite> Data for {{stats.stats_date | date }}</small></p>
                </ng-container>
            }
        </div>
    </div>
</article>