import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Component, ViewChild, AfterViewInit, input, output } from '@angular/core';
import { easepick } from '@easepick/core';
import { RangePlugin } from '@easepick/range-plugin';
import { toObservable } from '@angular/core/rxjs-interop';
import { DateRange } from '@app/interfaces/date-range.interface';
import { AutoForm } from '@app/interfaces/auto-form.interface';

@Component({
  selector: 'app-date-range',
  standalone: true,
  imports: [
    ReactiveFormsModule
  ],
  templateUrl: './date-range.component.html',
  styleUrl: './date-range.component.scss'
})
export class DateRangeComponent implements AfterViewInit {
  @ViewChild('easepick') easepick: any;
  public initialValue = input<DateRange>();
  public formChange = output<DateRange>();
  public formValid = output<boolean>();
  public dateRangeForm = new FormGroup<AutoForm<DateRange>>({
    start: new FormControl<Date>(new Date(), { validators: [Validators.required], nonNullable: true }),
    end: new FormControl<Date>(new Date(), { validators: [Validators.required], nonNullable: true }),
  });
  public value: string = '';
  public picker: any;

  constructor() {
    toObservable(this.initialValue).subscribe((initial) => {
      if(initial) {
        let start_out = initial['start'].toLocaleDateString('en-US', {year:'2-digit', month:'2-digit', day:'2-digit'});
        let end_out = initial['end'].toLocaleDateString('en-US', {year:'2-digit', month:'2-digit', day:'2-digit'});
        this.value = start_out+' - '+end_out;

        Object.keys(initial).forEach(key => {
          let prop = key as keyof typeof initial;
          if(initial[prop] != null) {
            this.dateRangeForm.get(key)?.setValue(initial[prop]);
          }
        });
      }
      this.onChange();
    });
  }

  ngAfterViewInit() {
    if(document.getElementById('datepicker') != null){
      var self = this;
      this.picker = new easepick.create({
        element: document.getElementById('datepicker')!,
        css: [
          'https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.0/dist/index.css',
        ],
        plugins: [
          RangePlugin
        ],
        format: "MM\/DD\/YY",
        grid: 2,
        calendars: 2,
        inline: false,
        RangePlugin: {
          tooltip: false,
        },
        setup(picker:any) {
          picker.on('select', (e:any) => {
            self.dateRangeForm.get('start')?.setValue(e.detail.start);
            self.dateRangeForm.get('end')?.setValue(new Date(e.detail.end.getTime() + (1 * 24 * 60 * 60 * 1000) - 1));
            self.onChange();
          });
        },
      });
    }
  }

  onChange() {
    this.formValid.emit(this.dateRangeForm.valid);
    this.formChange.emit(<DateRange>this.dateRangeForm.value);
  }
}
