import { Injectable } from '@angular/core';
import { SensorTypeDefaultParams as DefaultParams } from '@app/models/sensor-type-default-params.class';
import { SensorType, SensorTypeCreate } from '@app/models/sensor-type.interface';
import { SensorTypesService } from '@lib/core';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SensorTypeHelperService {

  constructor(
    private service: SensorTypesService,
  ) { }

  getAll(): Observable<SensorType[]> {
    let params = new DefaultParams();
    return this.service.sensorTypesGet(params)
      .pipe(map(x => <SensorType[]>x));
  }
  getById(id: number): Observable<SensorType> {
    let params = <any>new DefaultParams();
    params.id = 'eq.' + id;
    return this.service.sensorTypesGet(params)
      .pipe(map(x => <SensorType>x[0]));
  }
  create(input: SensorTypeCreate) {
    return this.service.sensorTypesPost({
      sensorTypes: <any>input,
      prefer: "return=representation"
    }, 'response')
    .pipe(map(response => response.body[0]))
    .pipe(map(body => {
      //console.log(body)
      return (<SensorType>body).id;
    }));
  }
  update(id: number, input: SensorTypeCreate) {
    return this.service.sensorTypesPatch({
      id: 'eq.'+id,
      sensorTypes: <any>input
    }, 'response')
    .pipe(map(response => response.ok));
  }
}
