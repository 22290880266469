

<div class="overflow-hidden bg-white shadow sm:rounded-lg mb-4">
    <div class="border-t border-gray-100">
        <form [formGroup]="algorithmForm" (input)="onChange()" (change)="onChange()">
            <div class="divide-y divide-gray-100">
                <div>
                    <app-input-field
                        controlName="id"
                        [form]="algorithmForm"
                        type="number"
                        label="Id"
                        [errorMessages]="{ required: 'Id is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="name"
                        [form]="algorithmForm"
                        label="Name"
                        [errorMessages]="{ required: 'Name is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="min_peak_height"
                        [form]="algorithmForm"
                        type="number"
                        step="0.005"
                        label="Min Peak Height"
                        [errorMessages]="{ required: 'Min Peak Height is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="min_peak_prominence"
                        [form]="algorithmForm"
                        type="number"
                        step="0.005"
                        label="Min Peak Prominence"
                        [errorMessages]="{ required: 'Min Peak Prominence is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="min_peak_hour_height"
                        [form]="algorithmForm"
                        type="number"
                        step="0.005"
                        label="Min Peak Hour Height"
                        [errorMessages]="{ required: 'Min Peak Hour Height is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="min_peak_half_hour_height"
                        [form]="algorithmForm"
                        type="number"
                        step="0.005"
                        label="Min Peak Half Hour Height"
                        [errorMessages]="{ required: 'Min Peak Half Hour Height is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="max_peak_rise_slope"
                        [form]="algorithmForm"
                        type="number"
                        step="0.005"
                        label="Max Peak Rise Slope"
                        [errorMessages]="{ required: 'Max Peak Rise Slope is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="max_peak_fall_slope"
                        [form]="algorithmForm"
                        type="number"
                        step="0.005"
                        label="Max Peak Fall Slope"
                        [errorMessages]="{ required: 'Max Peak Fall Slope is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="discard_initial_data_hours"
                        [form]="algorithmForm"
                        type="number"
                        step="1"
                        label="Discard Initial Data Hours"
                        [errorMessages]="{ required: 'Discard Initial Data Hours is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="percentile_filter_window"
                        [form]="algorithmForm"
                        type="number"
                        step="1"
                        label="Percentile Filter Window"
                        [errorMessages]="{ required: 'Percentile Filter Window is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="gain_resistance"
                        [form]="algorithmForm"
                        type="number"
                        step="1"
                        label="Gain Resistance"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="correction_factor_5"
                        [form]="algorithmForm"
                        type="number"
                        step="1"
                        label="Correction Factor 5"
                        [errorMessages]="{ required: 'Correction Factor 5 is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="points_of_window"
                        [form]="algorithmForm"
                        type="number"
                        step="1"
                        label="Points of Window"
                        [errorMessages]="{ required: 'Points of Window is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="tac_threshold"
                        [form]="algorithmForm"
                        type="number"
                        step="0.0005"
                        label="TAC Threshold"
                        [errorMessages]="{ required: 'TAC Threshold is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="percentile_filtering"
                        type="toggle"
                        [form]="algorithmForm"
                        label="Percentile Filtering?"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="fft_smoothing"
                        type="toggle"
                        [form]="algorithmForm"
                        label="FFT Smoothing?"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="rh_correction"
                        type="toggle"
                        [form]="algorithmForm"
                        label="RH Correction?"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="temp_correction"
                        type="toggle"
                        [form]="algorithmForm"
                        label="Temp Correction?"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="delta_threshold"
                        [form]="algorithmForm"
                        type="number"
                        step="0.05"
                        label="Delta Threshold"
                        [errorMessages]="{ required: 'Delta Threshold is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="factor_b1"
                        [form]="algorithmForm"
                        type="number"
                        step="0.00001"
                        label="Factor B1"
                        [errorMessages]="{ required: 'Factor B1 is required'}"
                    />
                </div>
            </div>
        </form>
    </div>
</div>