import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LoadingErrorBlockComponent } from '@app/components/loading-error-block/loading-error-block.component';
import { HeadMetaComponent } from '@app/components/ui.component';
import { Provider } from '@app/models/provider.interface';
import { AuthService } from '@app/services/auth.service';
import { ProviderHelperService } from '@app/services/helpers/provider-helper.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { 
  faCirclePlus,
  faArrowLeft 
 } from '@fortawesome/free-solid-svg-icons';
import { LetDirective } from '@ngrx/component';
import { Observable } from 'rxjs';
import { HelpButtonComponent } from "@app/components/help-button/help-button.component";

@Component({
  selector: 'app-providers',
  standalone: true,
  imports: [
    HeadMetaComponent,
    LetDirective,
    LoadingErrorBlockComponent,
    DatePipe,
    FontAwesomeModule,
    RouterModule,
    HelpButtonComponent
],
  templateUrl: './listing.page.html',
  styleUrl: './listing.page.scss'
})
export class ProvidersPage {
  public title = 'Providers';
  providers$: Observable<Provider[]>;

  faCirclePlus = faCirclePlus;
  faArrowLeft = faArrowLeft;

  constructor(
    private providerHelper: ProviderHelperService,
    public auth: AuthService,
  ) {
    this.providers$ = this.providerHelper.getAll();
  }
}
