import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LoadingErrorBlockComponent } from '@app/components/loading-error-block/loading-error-block.component';
import { HeadMetaComponent } from '@app/components/ui.component';
import { PeakEvent } from '@app/models/peak-event.interface';
import { AuthService } from '@app/services/auth.service';
import { PeakEventHelperService } from '@app/services/helpers/peak-event-helper.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { 
  faCirclePlus,
  faArrowLeft 
 } from '@fortawesome/free-solid-svg-icons';
import { LetDirective } from '@ngrx/component';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-peak-events',
  standalone: true,
  imports: [
    HeadMetaComponent,
    LetDirective,
    LoadingErrorBlockComponent,
    DatePipe,
    FontAwesomeModule,
    RouterModule,
  ],
  templateUrl: './listing.page.html',
  styleUrl: './listing.page.scss'
})
export class PeakEventsPage {
  public title = 'Peak Events';
  peakEventes$: Observable<PeakEvent[]>;

  faCirclePlus = faCirclePlus;
  faArrowLeft = faArrowLeft;

  constructor(
    private peakEventHelper: PeakEventHelperService,
    public auth: AuthService,
  ) {
    this.peakEventes$ = this.peakEventHelper.getAll();
  }
}
