

<div class="overflow-hidden bg-white shadow sm:rounded-lg mb-4">
    <div class="border-t border-gray-100">
        <form [formGroup]="peakEventForm" (input)="onChange()" (change)="onChange()">
            <div class="divide-y divide-gray-100">
                <div>
                    <app-input-field
                        controlName="sequence_id"
                        type="select|sequences"
                        [form]="peakEventForm"
                        label="Sequence"
                        [errorMessages]="{ required: 'Sequence is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="event_id"
                        type="select|events"
                        [form]="peakEventForm"
                        label="Event"
                        [errorMessages]="{ required: 'Event is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="event_number"
                        [form]="peakEventForm"
                        type="number"
                        step="any"
                        label="Event number"
                        [errorMessages]="{ required: 'Event number is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="started_at"
                        [form]="peakEventForm"
                        type="datetime-local"
                        label="Started at (UTC)"
                        [errorMessages]="{ required: 'Started at is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="ended_at"
                        [form]="peakEventForm"
                        type="datetime-local"
                        label="Ended at (UTC)"
                        [errorMessages]="{ required: 'Ended at is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="peak_tac_at"
                        [form]="peakEventForm"
                        type="datetime-local"
                        label="Peak TAC at (UTC)"
                        [errorMessages]="{ required: 'Peak TAC at is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="peak_tac_value"
                        [form]="peakEventForm"
                        type="number"
                        step="any"
                        label="Peak TAC value"
                        [errorMessages]="{ required: 'Peak TAC value is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="peak_prominence_value"
                        [form]="peakEventForm"
                        type="number"
                        step="any"
                        label="Peak prominence value"
                        [errorMessages]="{ required: 'Peak prominence value is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="duration_minutes"
                        [form]="peakEventForm"
                        type="number"
                        step="any"
                        label="Duration minutes"
                        [errorMessages]="{ required: 'Duration minutes is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="hour_threshold_duration"
                        [form]="peakEventForm"
                        type="number"
                        step="any"
                        label="Hour threshold duration"
                        [errorMessages]="{ required: 'Hour threshold duration is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="half_hour_threshold_duration"
                        [form]="peakEventForm"
                        type="number"
                        step="any"
                        label="Half hour threshold duration"
                        [errorMessages]="{ required: 'Half hour threshold duration is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="event_rise_slope"
                        [form]="peakEventForm"
                        type="number"
                        step="any"
                        label="Event rise slope"
                        [errorMessages]="{ required: 'Event rise slope is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="event_fall_slope"
                        [form]="peakEventForm"
                        type="number"
                        step="any"
                        label="Event fall slope"
                        [errorMessages]="{ required: 'Event fall slope is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="area_under_curve"
                        [form]="peakEventForm"
                        type="number"
                        step="any"
                        label="Area under curve"
                        [errorMessages]="{ required: 'Area under curve is required'}"
                    />
                </div>
            </div>
        </form>
    </div>
</div>