/**
 * Arborsense API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 12.2.8 (4a3936f)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { Stats } from '../model/stats';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface StatsDeleteRequestParams {
    id?: string;
    providerId?: string;
    statsDate?: string;
    countSoberHours?: string;
    countTotalHours?: string;
    countDevices?: string;
    countActiveSequences?: string;
    countSequenceAlerts?: string;
    countProcessedSequenceItems?: string;
    countDeliveredWebhooks?: string;
    createdAt?: string;
    updatedAt?: string;
    /** Preference */
    prefer?: 'return=representation' | 'return=minimal' | 'return=none';
}

export interface StatsGetRequestParams {
    id?: string;
    providerId?: string;
    statsDate?: string;
    countSoberHours?: string;
    countTotalHours?: string;
    countDevices?: string;
    countActiveSequences?: string;
    countSequenceAlerts?: string;
    countProcessedSequenceItems?: string;
    countDeliveredWebhooks?: string;
    createdAt?: string;
    updatedAt?: string;
    /** Filtering Columns */
    select?: string;
    /** Ordering */
    order?: string;
    /** Limiting and Pagination */
    range?: string;
    /** Limiting and Pagination */
    rangeUnit?: string;
    /** Limiting and Pagination */
    offset?: string;
    /** Limiting and Pagination */
    limit?: string;
    /** Preference */
    prefer?: 'count=none';
}

export interface StatsPatchRequestParams {
    id?: string;
    providerId?: string;
    statsDate?: string;
    countSoberHours?: string;
    countTotalHours?: string;
    countDevices?: string;
    countActiveSequences?: string;
    countSequenceAlerts?: string;
    countProcessedSequenceItems?: string;
    countDeliveredWebhooks?: string;
    createdAt?: string;
    updatedAt?: string;
    /** Preference */
    prefer?: 'return=representation' | 'return=minimal' | 'return=none';
    /** stats */
    stats?: Stats;
}

export interface StatsPostRequestParams {
    /** Filtering Columns */
    select?: string;
    /** Preference */
    prefer?: 'return=representation' | 'return=minimal' | 'return=none' | 'resolution=ignore-duplicates' | 'resolution=merge-duplicates';
    /** stats */
    stats?: Stats;
}


@Injectable({
  providedIn: 'root'
})
export class StatsService {

    protected basePath = 'http://0.0.0.0:3000';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public statsDelete(requestParameters: StatsDeleteRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public statsDelete(requestParameters: StatsDeleteRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public statsDelete(requestParameters: StatsDeleteRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public statsDelete(requestParameters: StatsDeleteRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const id = requestParameters.id;
        const providerId = requestParameters.providerId;
        const statsDate = requestParameters.statsDate;
        const countSoberHours = requestParameters.countSoberHours;
        const countTotalHours = requestParameters.countTotalHours;
        const countDevices = requestParameters.countDevices;
        const countActiveSequences = requestParameters.countActiveSequences;
        const countSequenceAlerts = requestParameters.countSequenceAlerts;
        const countProcessedSequenceItems = requestParameters.countProcessedSequenceItems;
        const countDeliveredWebhooks = requestParameters.countDeliveredWebhooks;
        const createdAt = requestParameters.createdAt;
        const updatedAt = requestParameters.updatedAt;
        const prefer = requestParameters.prefer;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (id !== undefined && id !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>id, 'id');
        }
        if (providerId !== undefined && providerId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>providerId, 'provider_id');
        }
        if (statsDate !== undefined && statsDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>statsDate, 'stats_date');
        }
        if (countSoberHours !== undefined && countSoberHours !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>countSoberHours, 'count_sober_hours');
        }
        if (countTotalHours !== undefined && countTotalHours !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>countTotalHours, 'count_total_hours');
        }
        if (countDevices !== undefined && countDevices !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>countDevices, 'count_devices');
        }
        if (countActiveSequences !== undefined && countActiveSequences !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>countActiveSequences, 'count_active_sequences');
        }
        if (countSequenceAlerts !== undefined && countSequenceAlerts !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>countSequenceAlerts, 'count_sequence_alerts');
        }
        if (countProcessedSequenceItems !== undefined && countProcessedSequenceItems !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>countProcessedSequenceItems, 'count_processed_sequence_items');
        }
        if (countDeliveredWebhooks !== undefined && countDeliveredWebhooks !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>countDeliveredWebhooks, 'count_delivered_webhooks');
        }
        if (createdAt !== undefined && createdAt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>createdAt, 'created_at');
        }
        if (updatedAt !== undefined && updatedAt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>updatedAt, 'updated_at');
        }

        let localVarHeaders = this.defaultHeaders;
        if (prefer !== undefined && prefer !== null) {
            localVarHeaders = localVarHeaders.set('Prefer', String(prefer));
        }

        let localVarCredential: string | undefined;
        // authentication (JWT) required
        localVarCredential = this.configuration.lookupCredential('JWT');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/stats`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public statsGet(requestParameters: StatsGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/vnd.pgrst.object+json;nulls=stripped' | 'application/vnd.pgrst.object+json' | 'text/csv', context?: HttpContext, transferCache?: boolean}): Observable<Array<Stats>>;
    public statsGet(requestParameters: StatsGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/vnd.pgrst.object+json;nulls=stripped' | 'application/vnd.pgrst.object+json' | 'text/csv', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Array<Stats>>>;
    public statsGet(requestParameters: StatsGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/vnd.pgrst.object+json;nulls=stripped' | 'application/vnd.pgrst.object+json' | 'text/csv', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Array<Stats>>>;
    public statsGet(requestParameters: StatsGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'application/vnd.pgrst.object+json;nulls=stripped' | 'application/vnd.pgrst.object+json' | 'text/csv', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const id = requestParameters.id;
        const providerId = requestParameters.providerId;
        const statsDate = requestParameters.statsDate;
        const countSoberHours = requestParameters.countSoberHours;
        const countTotalHours = requestParameters.countTotalHours;
        const countDevices = requestParameters.countDevices;
        const countActiveSequences = requestParameters.countActiveSequences;
        const countSequenceAlerts = requestParameters.countSequenceAlerts;
        const countProcessedSequenceItems = requestParameters.countProcessedSequenceItems;
        const countDeliveredWebhooks = requestParameters.countDeliveredWebhooks;
        const createdAt = requestParameters.createdAt;
        const updatedAt = requestParameters.updatedAt;
        const select = requestParameters.select;
        const order = requestParameters.order;
        const range = requestParameters.range;
        const rangeUnit = requestParameters.rangeUnit;
        const offset = requestParameters.offset;
        const limit = requestParameters.limit;
        const prefer = requestParameters.prefer;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (id !== undefined && id !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>id, 'id');
        }
        if (providerId !== undefined && providerId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>providerId, 'provider_id');
        }
        if (statsDate !== undefined && statsDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>statsDate, 'stats_date');
        }
        if (countSoberHours !== undefined && countSoberHours !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>countSoberHours, 'count_sober_hours');
        }
        if (countTotalHours !== undefined && countTotalHours !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>countTotalHours, 'count_total_hours');
        }
        if (countDevices !== undefined && countDevices !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>countDevices, 'count_devices');
        }
        if (countActiveSequences !== undefined && countActiveSequences !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>countActiveSequences, 'count_active_sequences');
        }
        if (countSequenceAlerts !== undefined && countSequenceAlerts !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>countSequenceAlerts, 'count_sequence_alerts');
        }
        if (countProcessedSequenceItems !== undefined && countProcessedSequenceItems !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>countProcessedSequenceItems, 'count_processed_sequence_items');
        }
        if (countDeliveredWebhooks !== undefined && countDeliveredWebhooks !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>countDeliveredWebhooks, 'count_delivered_webhooks');
        }
        if (createdAt !== undefined && createdAt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>createdAt, 'created_at');
        }
        if (updatedAt !== undefined && updatedAt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>updatedAt, 'updated_at');
        }
        if (select !== undefined && select !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>select, 'select');
        }
        if (order !== undefined && order !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>order, 'order');
        }
        if (offset !== undefined && offset !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>offset, 'offset');
        }
        if (limit !== undefined && limit !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>limit, 'limit');
        }

        let localVarHeaders = this.defaultHeaders;
        if (range !== undefined && range !== null) {
            localVarHeaders = localVarHeaders.set('Range', String(range));
        }
        if (rangeUnit !== undefined && rangeUnit !== null) {
            localVarHeaders = localVarHeaders.set('Range-Unit', String(rangeUnit));
        }
        if (prefer !== undefined && prefer !== null) {
            localVarHeaders = localVarHeaders.set('Prefer', String(prefer));
        }

        let localVarCredential: string | undefined;
        // authentication (JWT) required
        localVarCredential = this.configuration.lookupCredential('JWT');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
                'application/vnd.pgrst.object+json;nulls=stripped',
                'application/vnd.pgrst.object+json',
                'text/csv'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/stats`;
        return this.httpClient.request<Array<Stats>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public statsPatch(requestParameters: StatsPatchRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public statsPatch(requestParameters: StatsPatchRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public statsPatch(requestParameters: StatsPatchRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public statsPatch(requestParameters: StatsPatchRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const id = requestParameters.id;
        const providerId = requestParameters.providerId;
        const statsDate = requestParameters.statsDate;
        const countSoberHours = requestParameters.countSoberHours;
        const countTotalHours = requestParameters.countTotalHours;
        const countDevices = requestParameters.countDevices;
        const countActiveSequences = requestParameters.countActiveSequences;
        const countSequenceAlerts = requestParameters.countSequenceAlerts;
        const countProcessedSequenceItems = requestParameters.countProcessedSequenceItems;
        const countDeliveredWebhooks = requestParameters.countDeliveredWebhooks;
        const createdAt = requestParameters.createdAt;
        const updatedAt = requestParameters.updatedAt;
        const prefer = requestParameters.prefer;
        const stats = requestParameters.stats;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (id !== undefined && id !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>id, 'id');
        }
        if (providerId !== undefined && providerId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>providerId, 'provider_id');
        }
        if (statsDate !== undefined && statsDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>statsDate, 'stats_date');
        }
        if (countSoberHours !== undefined && countSoberHours !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>countSoberHours, 'count_sober_hours');
        }
        if (countTotalHours !== undefined && countTotalHours !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>countTotalHours, 'count_total_hours');
        }
        if (countDevices !== undefined && countDevices !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>countDevices, 'count_devices');
        }
        if (countActiveSequences !== undefined && countActiveSequences !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>countActiveSequences, 'count_active_sequences');
        }
        if (countSequenceAlerts !== undefined && countSequenceAlerts !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>countSequenceAlerts, 'count_sequence_alerts');
        }
        if (countProcessedSequenceItems !== undefined && countProcessedSequenceItems !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>countProcessedSequenceItems, 'count_processed_sequence_items');
        }
        if (countDeliveredWebhooks !== undefined && countDeliveredWebhooks !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>countDeliveredWebhooks, 'count_delivered_webhooks');
        }
        if (createdAt !== undefined && createdAt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>createdAt, 'created_at');
        }
        if (updatedAt !== undefined && updatedAt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>updatedAt, 'updated_at');
        }

        let localVarHeaders = this.defaultHeaders;
        if (prefer !== undefined && prefer !== null) {
            localVarHeaders = localVarHeaders.set('Prefer', String(prefer));
        }

        let localVarCredential: string | undefined;
        // authentication (JWT) required
        localVarCredential = this.configuration.lookupCredential('JWT');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/vnd.pgrst.object+json;nulls=stripped',
            'application/vnd.pgrst.object+json',
            'text/csv'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/stats`;
        return this.httpClient.request<any>('patch', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: stats,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public statsPost(requestParameters: StatsPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public statsPost(requestParameters: StatsPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public statsPost(requestParameters: StatsPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public statsPost(requestParameters: StatsPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const select = requestParameters.select;
        const prefer = requestParameters.prefer;
        const stats = requestParameters.stats;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (select !== undefined && select !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>select, 'select');
        }

        let localVarHeaders = this.defaultHeaders;
        if (prefer !== undefined && prefer !== null) {
            localVarHeaders = localVarHeaders.set('Prefer', String(prefer));
        }

        let localVarCredential: string | undefined;
        // authentication (JWT) required
        localVarCredential = this.configuration.lookupCredential('JWT');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/vnd.pgrst.object+json;nulls=stripped',
            'application/vnd.pgrst.object+json',
            'text/csv'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/stats`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: stats,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

}
