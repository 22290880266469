import { Component, input, output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { InputFieldComponent } from '@app/components/input-field/input-field.component';
import { AutoForm } from '@app/interfaces/auto-form.interface';
import { PeakEventCreate } from '@app/models/peak-event.interface';
import { toObservable } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-peak-event-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    InputFieldComponent,
  ],
  templateUrl: './form.component.html',
  styleUrl: './form.component.scss'
})
export class PeakEventFormComponent {
  initialValue = input<PeakEventCreate>();
  formChange = output<PeakEventCreate>();
  formValid = output<boolean>();

  peakEventForm = new FormGroup<AutoForm<PeakEventCreate>>({
    sequence_id: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true}),
    event_id: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true}),
    event_number: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true}),
    started_at: new FormControl<string>('', { validators: [Validators.required], nonNullable: true}),
    ended_at: new FormControl<string>('', { validators: [Validators.required], nonNullable: true}),
    peak_tac_at: new FormControl<string>('', { validators: [Validators.required], nonNullable: true}),
    peak_tac_value: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true}),
    peak_prominence_value: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true}),
    duration_minutes: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true}),
    hour_threshold_duration: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true}),
    half_hour_threshold_duration: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true}),
    event_rise_slope: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true}),
    event_fall_slope: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true}),
    area_under_curve: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true}),
  });

  constructor() {
    toObservable(this.initialValue).subscribe((initial) => {
      if(initial) {
        Object.keys(initial).forEach(key => {
          // https://stackoverflow.com/a/62438434/3341745
          let prop = key as keyof typeof initial;
          if(initial[prop] != null) {
            this.peakEventForm.get(key)?.setValue(initial[prop]);
          }
        });
      }
      this.onChange();
    });
  }

  onChange() {
    this.formValid.emit(this.peakEventForm.valid);
    this.formChange.emit(<PeakEventCreate>this.peakEventForm.value);
  }
}