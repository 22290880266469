

<div class="overflow-hidden bg-white shadow sm:rounded-lg mb-4">
    <div class="border-t border-gray-100">
        <form [formGroup]="processedSequenceItemForm" (input)="onChange()" (change)="onChange()">
            <div class="divide-y divide-gray-100">
                <div>
                    <app-input-field
                        controlName="sequence_id"
                        type="select|sequences"
                        [form]="processedSequenceItemForm"
                        label="Sequence"
                        [errorMessages]="{ required: 'Sequence is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="raw_sequence_item_id"
                        [form]="processedSequenceItemForm"
                        label="Raw Sequence Item Id"
                        [errorMessages]="{ required: 'Raw Sequence Item Id is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="activity_at"
                        [form]="processedSequenceItemForm"
                        type="datetime-local"
                        label="Activity at (UTC)"
                        [errorMessages]="{ required: 'Activity at is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="smoothed_voltage"
                        [form]="processedSequenceItemForm"
                        type="number"
                        step="any"
                        label="Smoothed voltage"
                        [errorMessages]="{ required: 'Smoothed voltage is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="smoothed_temp_1"
                        [form]="processedSequenceItemForm"
                        type="number"
                        step="any"
                        label="Smoothed temperature 1"
                        [errorMessages]="{ required: 'Smoothed temperature 1 is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="smoothed_temp_2"
                        [form]="processedSequenceItemForm"
                        type="number"
                        step="any"
                        label="Smoothed temperature 2"
                        [errorMessages]="{ required: 'Smoothed temperature 2 is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="smoothed_humidity_1"
                        [form]="processedSequenceItemForm"
                        type="number"
                        step="any"
                        label="Smoothed humidity 1"
                        [errorMessages]="{ required: 'Smoothed humidity 1 is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="smoothed_humidity_2"
                        [form]="processedSequenceItemForm"
                        type="number"
                        step="any"
                        label="Smoothed humidity 2"
                        [errorMessages]="{ required: 'Smoothed humidity 2 is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="ambient_sensor_1"
                        [form]="processedSequenceItemForm"
                        type="number"
                        step="any"
                        label="Ambient sensor 1"
                        [errorMessages]="{ required: 'Ambient sensor 1 is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="ambient_sensor_2"
                        [form]="processedSequenceItemForm"
                        type="number"
                        step="any"
                        label="Ambient sensor 2"
                        [errorMessages]="{ required: 'Ambient sensor 2 is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="ir_sensor_1"
                        [form]="processedSequenceItemForm"
                        type="number"
                        step="any"
                        label="IR sensor 1"
                        [errorMessages]="{ required: 'IR sensor 1 is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="ir_sensor_2"
                        [form]="processedSequenceItemForm"
                        type="number"
                        step="any"
                        label="IR sensor 2"
                        [errorMessages]="{ required: 'IR sensor 2 is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="slope_1"
                        [form]="processedSequenceItemForm"
                        type="number"
                        step="any"
                        label="Slope 1"
                        [errorMessages]="{ required: 'Slope 1 is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="slope_2"
                        [form]="processedSequenceItemForm"
                        type="number"
                        step="any"
                        label="Slope 2"
                        [errorMessages]="{ required: 'Slope 2 is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="final_graphene_sensor_data"
                        [form]="processedSequenceItemForm"
                        type="number"
                        step="any"
                        label="Final graphene sensor data"
                        [errorMessages]="{ required: 'Final graphene sensor data is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="baseline_graphene_sensor_data"
                        [form]="processedSequenceItemForm"
                        type="number"
                        step="any"
                        label="Baseline graphene sensor data"
                        [errorMessages]="{ required: 'Baseline graphene sensor data is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="smoothed_graphene_sensor_data"
                        [form]="processedSequenceItemForm"
                        type="number"
                        step="any"
                        label="Smoothed graphene sensor data"
                        [errorMessages]="{ required: 'Smoothed graphene sensor data is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="tac_value"
                        [form]="processedSequenceItemForm"
                        type="number"
                        step="any"
                        label="TAC value"
                        [errorMessages]="{ required: 'TAC value is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="set_to_zero_threshold"
                        [form]="processedSequenceItemForm"
                        type="number"
                        step="any"
                        label="Set to zero threshold"
                        [errorMessages]="{ required: 'Set to zero threshold is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="final_suppressed_data"
                        [form]="processedSequenceItemForm"
                        type="number"
                        step="any"
                        label="Final suppressed data"
                        [errorMessages]="{ required: 'Final suppressed data is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="is_tamper"
                        type="toggle"
                        [form]="processedSequenceItemForm"
                        label="Is tamper?"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="battery_level"
                        [form]="processedSequenceItemForm"
                        type="number"
                        step="any"
                        label="Battery level"
                        [errorMessages]="{ required: 'Battery level is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="time_interval"
                        [form]="processedSequenceItemForm"
                        type="number"
                        step="any"
                        label="Time interval"
                        [errorMessages]="{ required: 'Time interval is required'}"
                    />
                </div>
            </div>
        </form>
    </div>
</div>