import { Component, effect, viewChild } from '@angular/core';
import { Router } from '@angular/router';
import { VersionService } from '@app/services/version.service';
import { HeadMetaComponent } from '@app/components/ui.component';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AuthService } from '@app/services/auth.service';
import { ToastService } from '@app/services/toast.service';
import { ToastComponent } from "@app/components/toast/toast.component";
import { AuthError } from '@app/interfaces/auth-models.interface';

@Component({
    selector: 'app-login',
    standalone: true,
    templateUrl: './page.component.html',
    styleUrl: './page.component.scss',
    imports: [
        HeadMetaComponent,
        ReactiveFormsModule,
        ToastComponent
    ]
})
export class LoginComponent {
  public version = '';
  public date = '';

  loginForm: FormGroup = new FormGroup({
    email: new FormControl<string>('', [Validators.required, Validators.email]),
    password: new FormControl<string>('', Validators.required)
  });

  messageToast = viewChild<ToastComponent>('messageToast');
  
  constructor(
    private versionService: VersionService,
    private auth: AuthService,
    private toast: ToastService,
    private router: Router,
  ) {
    this.version = this.versionService.getVersion();
    this.date = this.versionService.getYear();
    effect(() => {
      this.toast.init(this.messageToast());
    })
  }
  navtoForgot(){
    this.router.navigateByUrl('/forgot');
  }

  submit() {
    const creds = this.loginForm.value;
    if(creds) {
      this.auth.login(creds.email, creds.password).subscribe({
        error: (err: AuthError) => {
          console.log(err.error)
          this.toast.message({ message: err.error.error_description, type: 'error'});
        }
      });
    }
  }

}
