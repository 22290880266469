import { Injectable } from '@angular/core';
import { AlgorithmDefaultParams as DefaultParams } from '@app/models/algorithm-default-params.class';
import { Algorithm, AlgorithmCreate } from '@app/models/algorithm.interface';
import { AlgorithmsService } from '@lib/core';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlgorithmHelperService {

  constructor(
    private service: AlgorithmsService,
  ) { }

  getAll(): Observable<Algorithm[]> {
    let params = new DefaultParams();
    return this.service.algorithmsGet(params)
      .pipe(map(x => <Algorithm[]>x));
  }
  getById(id: number): Observable<Algorithm> {
    let params = <any>new DefaultParams();
    params.id = 'eq.' + id;
    return this.service.algorithmsGet(params)
      .pipe(map(x => <Algorithm>x[0]));
  }
  create(input: AlgorithmCreate) {
    return this.service.algorithmsPost({
      algorithms: <any>input,
      prefer: "return=representation"
    }, 'response')
    .pipe(map(response => response.body[0]))
    .pipe(map(body => {
      //console.log(body)
      return (<Algorithm>body).id;
    }));
  }
  update(id: number, input: AlgorithmCreate) {
    return this.service.algorithmsPatch({
      id: 'eq.'+id,
      algorithms: <any>input
    }, 'response')
    .pipe(map(response => response.ok));
  }
}
