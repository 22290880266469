import { Injectable } from '@angular/core';
import { UserDefaultParams as DefaultParams } from '@app/models/user-default-params.class';
import { User, UserCreate } from '@app/models/user.interface';
import { 
  UsersService, 
  RpcCreateUserPostRequest, 
  RpcCreateUserService, 
  RpcGetUserRolesPostRequest, 
  RpcGetUserRolesService,
  RpcSetUserRolesPostRequest,
  RpcSetUserRolesService,
} from '@lib/core';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserHelperService {

  constructor(
    private service: UsersService,
    private createRpc: RpcCreateUserService,
    private getRolesRpc: RpcGetUserRolesService,
    private setRolesRpc: RpcSetUserRolesService,
  ) { }

  getAll(): Observable<User[]> {
    let params = new DefaultParams();
    return this.service.usersGet(params)
      .pipe(map(x => <User[]>x));
  }
  getById(id: number): Observable<User> {
    let params = <any>new DefaultParams();
    params.id = 'eq.' + id;
    return this.service.usersGet(params)
      .pipe(map(x => <User>x[0]));
  }
  create(input: RpcCreateUserPostRequest) {
    return this.createRpc.rpcCreateUserPost({args: input}, 'response')
    .pipe(map(resp => {
      let body = resp.body;
      return <string>body;
    }));
  }
  update(id: number, input: UserCreate) {
    return this.service.usersPatch({
      id: 'eq.'+id,
      users: <any>input
    }, 'response')
    .pipe(map(response => response.ok));
  }
  getUserRoles(input: RpcGetUserRolesPostRequest){
    return this.getRolesRpc.rpcGetUserRolesPost({args: input}, 'response')
    .pipe(map(resp => {
      let body = resp.body;
      return <any>body;
    }));
  }
  setUserRoles(input: RpcSetUserRolesPostRequest){
    return this.setRolesRpc.rpcSetUserRolesPost({args: input}, 'response')
    .pipe(map(resp => {
      let body = resp.body;
      return <string>body;
    }));
  }
}
