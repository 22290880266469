import { Injectable } from '@angular/core';
import { PhoneDefaultParams as DefaultParams } from '@app/models/phone-default-params.class';
import { Phone, PhoneCreate } from '@app/models/phone.interface';
import { PhonesService } from '@lib/core';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PhoneHelperService {

  constructor(
    private service: PhonesService,
  ) { }

  getAll(): Observable<Phone[]> {
    let params = new DefaultParams();
    return this.service.phonesGet(params)
      .pipe(map(x => <Phone[]>x));
  }
  getById(id: number): Observable<Phone> {
    let params = <any>new DefaultParams();
    params.id = 'eq.' + id;
    return this.service.phonesGet(params)
      .pipe(map(x => <Phone>x[0]));
  }
  search(provider_id: number): Observable<Phone[]> {
    let params = <any>new DefaultParams();
    if(provider_id != 0) {
      params.providerId = 'eq.' + provider_id;
    }
    return this.service.phonesGet(params)
      .pipe(map(x => <Phone[]>x));
  }
  create(input: PhoneCreate) {
    return this.service.phonesPost({
      phones: <any>input,
      prefer: "return=representation"
    }, 'response')
    .pipe(map(response => response.body[0]))
    .pipe(map(body => {
      //console.log(body)
      return (<Phone>body).id;
    }));
  }
  update(id: number, input: PhoneCreate) {
    return this.service.phonesPatch({
      id: 'eq.'+id,
      phones: <any>input
    }, 'response')
    .pipe(map(response => response.ok));
  }
}
