import { Injectable } from '@angular/core';
import { FirmwareDefaultParams as DefaultParams } from '@app/models/firmware-default-params.class';
import { Firmware, FirmwareCreate } from '@app/models/firmware.interface';
import { FirmwareService } from '@lib/core';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FirmwareHelperService {

  constructor(
    private service: FirmwareService,
  ) { }

  getAll(): Observable<Firmware[]> {
    let params = new DefaultParams();
    return this.service.firmwareGet(params)
      .pipe(map(x => <Firmware[]>x));
  }
  getById(id: number): Observable<Firmware> {
    let params = <any>new DefaultParams();
    params.id = 'eq.' + id;
    return this.service.firmwareGet(params)
      .pipe(map(x => <Firmware>x[0]));
  }
  create(input: FirmwareCreate) {
    return this.service.firmwarePost({
      firmware: <any>input,
      prefer: "return=representation"
    }, 'response')
    .pipe(map(response => response.body[0]))
    .pipe(map(body => {
      //console.log(body)
      return (<Firmware>body).id;
    }));
  }
  update(id: number, input: FirmwareCreate) {
    return this.service.firmwarePatch({
      id: 'eq.'+id,
      firmware: <any>input
    }, 'response')
    .pipe(map(response => response.ok));
  }
}
