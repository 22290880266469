import { Component, signal } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { UserFormComponent } from '@app/components/forms/user/form.component';
import { LoadingErrorBlockComponent } from '@app/components/loading-error-block/loading-error-block.component';
import { HeadMetaComponent } from '@app/components/ui.component';
import { AppHttpError } from '@app/interfaces/pg-models.interface';
import { User, UserCreate, UserRoleSelection } from '@app/models/user.interface';
import { AuthService } from '@app/services/auth.service';
import { UserHelperService } from '@app/services/helpers/user-helper.service';
import { ToastService } from '@app/services/toast.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faSave, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { LetDirective } from '@ngrx/component';
import { Observable } from 'rxjs';
import { PasswordResetFormComponent } from "@app/components/forms/password-reset/form.component";
import { UserRoleFormComponent } from "@app/components/forms/user-roles/form.component";
import { AuthUserUpdateError } from '@app/interfaces/auth-models.interface';
import { HelpButtonComponent } from "@app/components/help-button/help-button.component";


@Component({
  selector: 'app-user-edit',
  standalone: true,
  templateUrl: './edit.page.html',
  styleUrl: './edit.page.scss',
  imports: [
    HeadMetaComponent,
    LetDirective,
    LoadingErrorBlockComponent,
    UserFormComponent,
    FontAwesomeModule,
    RouterModule,
    ReactiveFormsModule,
    PasswordResetFormComponent,
    UserRoleFormComponent,
    HelpButtonComponent
]
})
export class UserEditPage {
  public title = 'User Edit';
  formValid = signal(false);
  formValue = signal<UserCreate | null>(null);
  id: number;
  user$: Observable<User>;
  roles$: Observable<UserRoleSelection>;

  faSave = faSave;
  faArrowLeft = faArrowLeft;

  passwordFormValid = signal(false);
  passwordFormValue = signal<{ password: string, passwordConfirm: string} | null>(null);

  userRoleFormValid = signal(false);
  userRoleFormValue = signal<{ role_ids: number[]} | null>(null);

  constructor(
    private route: ActivatedRoute,
    private userHelper: UserHelperService,
    private router: Router,
    private toast: ToastService,
    public auth: AuthService,
  ) {
    this.id = this.route.snapshot.params['id'];
    this.user$ = this.userHelper.getById(this.id);
    this.roles$ = this.userHelper.getUserRoles({user_id_in: this.id.toString()});
  }

  update() {
    let val = this.formValue();
    if(val)
      this.userHelper.update(this.id, val).subscribe({
        next: (success) => { this.router.navigate(['users', this.id]); },
        error: (err: AppHttpError) => {
          this.toast.message({ message: err.error.code + ": " + err.error.message, type: 'error'});
        }
      });
  }

  updatePassword() {
    let val = this.passwordFormValue();
    if(val)
      this.auth.updatePassword(val.password).subscribe({
        next: (success) => { this.toast.message({ message: 'Password updated successfully', type: 'success' }) },
        error: (err: AuthUserUpdateError) => {
          console.log(err)
          this.toast.message({ message: err.error.msg, type: 'error'});
        }
      });
  }

  updateRole() {
    let val = this.userRoleFormValue();
    if(val && val != null){
      this.userHelper.setUserRoles({user_id_in: this.id.toString(), role_ids_in: val.role_ids }).subscribe({
        next: (success) => { this.toast.message({ message: 'Roles updated successfully', type: 'success' }) },
        error: (err: AppHttpError) => {
          this.toast.message({ message: err.error.code + ": " + err.error.message, type: 'error'});
        }
      });
    }
  }

  isMyUser(id: string): boolean {
    return this.auth.getUserId() == id;
  }
}
