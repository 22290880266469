import { Component, signal } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { FirmwareFormComponent } from '@app/components/forms/firmware/form.component';
import { LoadingErrorBlockComponent } from '@app/components/loading-error-block/loading-error-block.component';
import { HeadMetaComponent } from '@app/components/ui.component';
import { AppHttpError } from '@app/interfaces/pg-models.interface';
import { Firmware, FirmwareCreate } from '@app/models/firmware.interface';
import { FirmwareHelperService } from '@app/services/helpers/firmware-helper.service';
import { ToastService } from '@app/services/toast.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faSave, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { LetDirective } from '@ngrx/component';
import { Observable } from 'rxjs';
import { HelpButtonComponent } from "@app/components/help-button/help-button.component";

@Component({
  selector: 'app-firmware-edit',
  standalone: true,
  imports: [
    HeadMetaComponent,
    LetDirective,
    LoadingErrorBlockComponent,
    FirmwareFormComponent,
    FontAwesomeModule,
    RouterModule,
    HelpButtonComponent
],
  templateUrl: './edit.page.html',
  styleUrl: './edit.page.scss'
})
export class FirmwareEditPage {
  public title = 'Firmware Edit';
  formValid = signal(false);
  formValue = signal<FirmwareCreate | null>(null);
  id: number;
  firmware$: Observable<Firmware>;

  faSave = faSave;
  faArrowLeft = faArrowLeft;

  constructor(
    private route: ActivatedRoute,
    private firmwareHelper: FirmwareHelperService,
    private router: Router,
    private toast: ToastService,
  ) {
    this.id = this.route.snapshot.params['id'];
    this.firmware$ = this.firmwareHelper.getById(this.id);
  }

  update() {
    let val = this.formValue();
    if(val)
      this.firmwareHelper.update(this.id, val).subscribe({
        next: (success) => { this.router.navigate(['firmware', this.id]); },
        error: (err: AppHttpError) => {
          this.toast.message({ message: err.error.code + ": " + err.error.message, type: 'error'});
        }
      });
  }
}
