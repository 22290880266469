import { Component, Input, Output, EventEmitter } from "@angular/core";
import { Title, Meta, MetaDefinition } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'head-meta',
  standalone: true,
  imports: [],
  templateUrl: './head-meta.component.html',
  styleUrl: './head-meta.component.scss'
})
export class HeadMetaComponent {
  private appTitle = 'Arborsense';

  private title$:BehaviorSubject<string> = new BehaviorSubject<string>(this.appTitle);
  private description$:BehaviorSubject<string> = new BehaviorSubject<string>(this.appTitle);
  private type$:BehaviorSubject<string> = new BehaviorSubject<string>('website');
    
  @Input('title') set title(title: string) {
    this.setTitle(title + ' | ' + this.appTitle);
    this.titleChange.emit(title + ' | ' + this.appTitle);
  }
  @Output() titleChange = new EventEmitter<string>();

  @Input('description') set description(description: string) {
    this.setDescription(description);
    this.descriptionChange.emit(description);
  }
  @Output() descriptionChange = new EventEmitter<string>();

  @Input('type') set type(type: string) {
    this.setType(type);
    this.typeChange.emit(type);
  }
  @Output() typeChange = new EventEmitter<string>();

  constructor(
    private metaService: Meta,
    private titleService: Title,
  ) {
    this.title$.subscribe((title:string) => {
      this.titleService.setTitle(title);
      this.setMeta({
        property: 'og:title',
        content: title,
      });
    });
    this.description$.subscribe((description:string) => {
      this.metaService.updateTag({ name:'description', content: description });
      this.setMeta({
        property: 'og:description',
        content: description,
      });
    });
    this.type$.subscribe((type:string) => {
      this.setMeta({
        property: 'og:type',
        content: type,
      });
    }); 
    
  }

  private setMeta(meta: MetaDefinition){
    this.metaService.updateTag(meta);
  }

  private setTitle(input:string){
    this.title$.next(input);
  }

  private setDescription(input:string){
    this.description$.next(input);
  }

  private setType(input:string){
    this.type$.next(input);
  }
}

