import { Component, input, output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { InputFieldComponent } from '@app/components/input-field/input-field.component';
import { AutoForm } from '@app/interfaces/auto-form.interface';
import { RawSequenceItemCreate } from '@app/models/raw-sequence-item.interface';
import { toObservable } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-raw-sequence-item-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    InputFieldComponent,
  ],
  templateUrl: './form.component.html',
  styleUrl: './form.component.scss'
})
export class RawSequenceItemFormComponent {
  initialValue = input<RawSequenceItemCreate>();
  formChange = output<RawSequenceItemCreate>();
  formValid = output<boolean>();

  rawSequenceItemForm = new FormGroup<AutoForm<RawSequenceItemCreate>>({
    sequence_id: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true}),
    activity_at: new FormControl<string>('', { validators: [Validators.required], nonNullable: true}),
    time_of_reading: new FormControl<string>('', { validators: [Validators.required], nonNullable: true}),
    readable_timestamp: new FormControl<string>('', { validators: [Validators.required], nonNullable: true}),
    flash_timestamp: new FormControl<string>('', { validators: [Validators.required], nonNullable: true}),
    //delivered_at: new FormControl<string>('', { validators: [Validators.required], nonNullable: true}),
    vapor_concentration: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true}),
    temperature_1: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true}),
    temperature_2: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true}),
    relative_humidity_1: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true}),
    relative_humidity_2: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true}),
    ambient_raw_1: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true}),
    ambient_raw_2: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true}),
    bio_raw_1: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true}),
    bio_raw_2: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true}),
    is_tamper: new FormControl<boolean>(false, { validators: [Validators.required], nonNullable: true}),
    battery_level: new FormControl<number>(0, { validators: [Validators.required], nonNullable: true}),
    raw_payload: new FormControl<string>('', { validators: [Validators.required], nonNullable: true}),
  });

  constructor() {
    toObservable(this.initialValue).subscribe((initial) => {
      if(initial) {
        Object.keys(initial).forEach(key => {
          // https://stackoverflow.com/a/62438434/3341745
          let prop = key as keyof typeof initial;
          if(initial[prop] != null) {
            this.rawSequenceItemForm.get(key)?.setValue(initial[prop]);
          }
        });
      }
      this.onChange();
    });
  }

  onChange() {
    this.formValid.emit(this.rawSequenceItemForm.valid);
    this.formChange.emit(<RawSequenceItemCreate>this.rawSequenceItemForm.value);
  }
}