<select class="input input-bordered w-12 h-6 text-xs" (change)="navigateTo($event)">
    @if(versions.length > 0) {
        @for (version of versions; track $index) {
            @if(version == current_selection) {
                <option [value]="version" selected>{{ version }}</option>
            } @else {
                <option [value]="version">{{ version }}</option>
            }
        }
    }
</select>