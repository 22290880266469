import { Routes } from '@angular/router';
import { authGuard } from './guards/auth.guard';
import { nonAuthGuard } from './guards/non-auth.guard';
import { LoginComponent } from './pages/auth/login/page.component';
import { ForgotComponent } from './pages/auth/forgot/page.component';
import { NavComponent } from './pages/nav/nav.component';
import { HomePage } from './pages/home/home.page';
import { WebhooksPage } from './pages/webhooks/listing.page';
import { WebhookDetailPage } from './pages/webhooks/detail/detail.page';
import { WebhookEditPage } from './pages/webhooks/edit/edit.page';
import { WebhookCreatePage } from './pages/webhooks/create/create.page';
import { PhonesPage} from './pages/phones/listing.page';
import { PhoneDetailPage } from './pages/phones/detail/detail.page';
import { PhoneEditPage } from './pages/phones/edit/edit.page';
import { PhoneCreatePage } from './pages/phones/create/create.page';
import { UsersPage } from './pages/users/listing.page';
import { UserDetailPage } from './pages/users/detail/detail.page';
import { UserEditPage } from './pages/users/edit/edit.page';
import { UserCreatePage } from './pages/users/create/create.page';
import { SequencesPage } from './pages/sequences/listing.page';
import { SequenceDetailPage } from './pages/sequences/detail/detail.page';
import { SequenceEditPage } from './pages/sequences/edit/edit.page';
import { SequenceCreatePage } from './pages/sequences/create/create.page';
import { SequenceEventsPage } from './pages/sequence-events/listing.page';
import { SequenceEventDetailPage } from './pages/sequence-events/detail/detail.page';
import { SequenceEventEditPage } from './pages/sequence-events/edit/edit.page';
import { SequenceEventCreatePage } from './pages/sequence-events/create/create.page';
import { TamperEventsPage } from './pages/tamper-events/listing.page';
import { TamperEventDetailPage } from './pages/tamper-events/detail/detail.page';
import { TamperEventEditPage } from './pages/tamper-events/edit/edit.page';
import { TamperEventCreatePage } from './pages/tamper-events/create/create.page';
import { PeakEventsPage } from './pages/peak-events/listing.page';
import { PeakEventDetailPage } from './pages/peak-events/detail/detail.page';
import { PeakEventEditPage } from './pages/peak-events/edit/edit.page';
import { PeakEventCreatePage } from './pages/peak-events/create/create.page';
import { RawSequenceItemsPage } from './pages/raw-sequence-items/listing.page';
import { RawSequenceItemDetailPage } from './pages/raw-sequence-items/detail/detail.page';
import { RawSequenceItemEditPage } from './pages/raw-sequence-items/edit/edit.page';
import { RawSequenceItemCreatePage } from './pages/raw-sequence-items/create/create.page';
import { ProcessedSequenceItemsPage } from './pages/processed-sequence-items/listing.page';
import { ProcessedSequenceItemDetailPage } from './pages/processed-sequence-items/detail/detail.page';
import { ProcessedSequenceItemEditPage } from './pages/processed-sequence-items/edit/edit.page';
import { ProcessedSequenceItemCreatePage } from './pages/processed-sequence-items/create/create.page';
import { EventsPage } from './pages/events/listing.page';
import { EventDetailPage } from './pages/events/detail/detail.page';
import { EventEditPage } from './pages/events/edit/edit.page';
import { EventCreatePage } from './pages/events/create/create.page';
import { FirmwarePage } from './pages/firmware/listing.page';
import { FirmwareDetailPage } from './pages/firmware/detail/detail.page';
import { FirmwareEditPage } from './pages/firmware/edit/edit.page';
import { FirmwareCreatePage } from './pages/firmware/create/create.page';
import { DeviceStatusesPage } from './pages/device-statuses/listing.page';
import { DeviceStatusDetailPage } from './pages/device-statuses/detail/detail.page';
import { DeviceStatusEditPage } from './pages/device-statuses/edit/edit.page';
import { DeviceStatusCreatePage } from './pages/device-statuses/create/create.page';
import { SensorTypesPage } from './pages/sensor-types/listing.page';
import { SensorTypeDetailPage } from './pages/sensor-types/detail/detail.page';
import { SensorTypeEditPage } from './pages/sensor-types/edit/edit.page';
import { SensorTypeCreatePage } from './pages/sensor-types/create/create.page';
import { ProvidersPage } from './pages/providers/listing.page';
import { ProviderDetailPage } from './pages/providers/detail/detail.page';
import { ProviderEditPage } from './pages/providers/edit/edit.page';
import { ProviderCreatePage } from './pages/providers/create/create.page';
import { DevicesPage } from './pages/devices/listing.page';
import { DeviceDetailPage } from './pages/devices/detail/detail.page';
import { DeviceEditPage } from './pages/devices/edit/edit.page';
import { DeviceCreatePage } from './pages/devices/create/create.page';
import { VersionsPage } from './pages/versions/listing.page';
import { VersionDetailPage } from './pages/versions/detail/detail.page';
import { VersionEditPage } from './pages/versions/edit/edit.page';
import { VersionCreatePage } from './pages/versions/create/create.page';
import { AlgorithmsPage } from './pages/algorithms/listing.page';
import { AlgorithmDetailPage } from './pages/algorithms/detail/detail.page';
import { AlgorithmEditPage } from './pages/algorithms/edit/edit.page';
import { AlgorithmCreatePage } from './pages/algorithms/create/create.page';

import { DocsV1Page } from './pages/docs/v1/page.page';

export const routes: Routes = [
    { 
        path: 'login', component: LoginComponent, canActivate: [nonAuthGuard],
    },
    {
        path: 'forgot', component: ForgotComponent, canActivate: [nonAuthGuard],
    },
    {
        path: '', component: NavComponent, 
        canActivate: [authGuard],
        children: [
            { path: 'dashboard', component: HomePage },
            { path: 'providers', children: [
                { path: 'create', component: ProviderCreatePage },
                { path: ':id', component: ProviderDetailPage },
                { path: ':id/edit', component: ProviderEditPage },
                { path: '', component: ProvidersPage },
            ] },
            { path: 'webhooks', children: [
                { path: 'create', component: WebhookCreatePage },
                { path: ':id', component: WebhookDetailPage },
                { path: ':id/edit', component: WebhookEditPage },
                { path: '', component: WebhooksPage },
            ] },
            { path: 'phones', children: [
                { path: 'create', component: PhoneCreatePage },
                { path: ':id', component: PhoneDetailPage },
                { path: ':id/edit', component: PhoneEditPage },
                { path: '', component: PhonesPage },
            ] },
            { path: 'users', children: [
                { path: 'create', component: UserCreatePage },
                { path: ':id', component: UserDetailPage },
                { path: ':id/edit', component: UserEditPage },
                { path: '', component: UsersPage },
            ] },
            { path: 'devices', children: [
                { path: 'create', component: DeviceCreatePage },
                { path: ':id', component: DeviceDetailPage },
                { path: ':id/edit', component: DeviceEditPage },
                { path: '', component: DevicesPage },
            ] },
            { path: 'sequences', children: [
                { path: 'create', component: SequenceCreatePage },
                { path: ':id', component: SequenceDetailPage },
                { path: ':id/edit', component: SequenceEditPage },
                { path: '', component: SequencesPage },
            ] },
            { path: 'sequence-events', children: [
                { path: 'create', component: SequenceEventCreatePage },
                { path: ':id', component: SequenceEventDetailPage },
                { path: ':id/edit', component: SequenceEventEditPage },
                { path: '', component: SequenceEventsPage },
            ] },
            { path: 'tamper-events', children: [
                { path: 'create', component: TamperEventCreatePage },
                { path: ':id', component: TamperEventDetailPage },
                { path: ':id/edit', component: TamperEventEditPage },
                { path: '', component: TamperEventsPage },
            ] },
            { path: 'peak-events', children: [
                { path: 'create', component: PeakEventCreatePage },
                { path: ':id', component: PeakEventDetailPage },
                { path: ':id/edit', component: PeakEventEditPage },
                { path: '', component: PeakEventsPage },
            ] },
            { path: 'raw-sequence-items', children: [
                { path: 'create', component: RawSequenceItemCreatePage },
                { path: ':id', component: RawSequenceItemDetailPage },
                { path: ':id/edit', component: RawSequenceItemEditPage },
                { path: '', component: RawSequenceItemsPage },
            ] },
            { path: 'processed-sequence-items', children: [
                { path: 'create', component: ProcessedSequenceItemCreatePage },
                { path: ':id', component: ProcessedSequenceItemDetailPage },
                { path: ':id/edit', component: ProcessedSequenceItemEditPage },
                { path: '', component: ProcessedSequenceItemsPage },
            ] },
            { path: 'events', children: [
                { path: 'create', component: EventCreatePage },
                { path: ':id', component: EventDetailPage },
                { path: ':id/edit', component: EventEditPage },
                { path: '', component: EventsPage },
            ] },
            { path: 'versions', children: [
                { path: 'create', component: VersionCreatePage },
                { path: ':id', component: VersionDetailPage },
                { path: ':id/edit', component: VersionEditPage },
                { path: '', component: VersionsPage },
            ] },
            { path: 'firmware', children: [
                { path: 'create', component: FirmwareCreatePage },
                { path: ':id', component: FirmwareDetailPage },
                { path: ':id/edit', component: FirmwareEditPage },
                { path: '', component: FirmwarePage },
            ] },
            { path: 'device-statuses', children: [
                { path: 'create', component: DeviceStatusCreatePage },
                { path: ':id', component: DeviceStatusDetailPage },
                { path: ':id/edit', component: DeviceStatusEditPage },
                { path: '', component: DeviceStatusesPage },
            ] },
            { path: 'sensor-types', children: [
                { path: 'create', component: SensorTypeCreatePage },
                { path: ':id', component: SensorTypeDetailPage },
                { path: ':id/edit', component: SensorTypeEditPage },
                { path: '', component: SensorTypesPage },
            ] },
            { path: 'algorithms', children: [
                { path: 'create', component: AlgorithmCreatePage },
                { path: ':id', component: AlgorithmDetailPage },
                { path: ':id/edit', component: AlgorithmEditPage },
                { path: '', component: AlgorithmsPage },
            ] },
            { path: 'docs', children: [
                { path: 'v1', children: [
                    { path: '', component: DocsV1Page },
                ] },
                { path: '**', redirectTo: 'v1' }
            ] },
            // redirect to home if no route is found
            { path: '**', redirectTo: 'dashboard' }
        ]
    }
];