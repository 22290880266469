

<div class="overflow-hidden bg-white shadow sm:rounded-lg mb-4">
    <div class="border-t border-gray-100">
        <form [formGroup]="userRoleForm" (input)="onChange()" (change)="onChange()">
            <div class="divide-y divide-gray-100">
                <div *ngFor="let option of this.options; let i=index">
                    <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <label for="role-{{ i }}" class="text-sm font-medium text-gray-900 h-12 py-2">{{option.name}}</label>
                        <div class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            <div class="h-12">
                                <input id="role-{{ i }}" 
                                    formArrayName="role_ids" 
                                    (change)="this.onCheck($event,option.id)" 
                                    type="checkbox" 
                                    class="checkbox checkbox-primary rounded-full" 
                                    [checked]="option.enabled ? 'checked' : ''" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>