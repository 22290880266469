import { Injectable } from '@angular/core';
import { EventDefaultParams as DefaultParams } from '@app/models/event-default-params.class';
import { Event, EventCreate } from '@app/models/event.interface';
import { EventsService } from '@lib/core';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventHelperService {

  constructor(
    private service: EventsService,
  ) { }

  getAll(): Observable<Event[]> {
    let params = new DefaultParams();
    return this.service.eventsGet(params)
      .pipe(map(x => <Event[]>x));
  }
  getById(id: number): Observable<Event> {
    let params = <any>new DefaultParams();
    params.id = 'eq.' + id;
    return this.service.eventsGet(params)
      .pipe(map(x => <Event>x[0]));
  }
  create(input: EventCreate) {
    return this.service.eventsPost({
      events: <any>input,
      prefer: "return=representation"
    }, 'response')
    .pipe(map(response => response.body[0]))
    .pipe(map(body => {
      //console.log(body)
      return (<Event>body).id;
    }));
  }
  update(id: number, input: EventCreate) {
    return this.service.eventsPatch({
      id: 'eq.'+id,
      events: <any>input
    }, 'response')
    .pipe(map(response => response.ok));
  }
}
