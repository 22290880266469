import { Component } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { ProcessedSequenceItem } from '@app/models/processed-sequence-item.interface';
import { ProcessedSequenceItemHelperService } from '@app/services/helpers/processed-sequence-item-helper.service';
import { Observable } from 'rxjs';
import { HeadMetaComponent } from "@app/components/head-meta/head-meta.component";
import { LoadingErrorBlockComponent } from "@app/components/loading-error-block/loading-error-block.component";
import { LetDirective } from '@ngrx/component';
import { CreatedUpdatedDisplayComponent } from "@app/components/created-updated-display/created-updated-display.component";
import { DetailDisplayComponent } from "@app/components/detail-display/detail-display.component";
import { faCheck, faPenToSquare, faXmark, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AuthService } from '@app/services/auth.service';

@Component({
  selector: 'app-processed-sequence-item-detail',
  standalone: true,
  templateUrl: './detail.page.html',
  styleUrl: './detail.page.scss',
  imports: [
    HeadMetaComponent,
    LoadingErrorBlockComponent,
    LetDirective,
    CreatedUpdatedDisplayComponent,
    DetailDisplayComponent,
    FontAwesomeModule,
    RouterModule,
    DatePipe,
  ]
})
export class ProcessedSequenceItemDetailPage {
  title = "Processed Sequence Item Detail";
  id: number;
  processedSequenceItem$: Observable<ProcessedSequenceItem>;

  faCheck = faCheck;
  faXmark = faXmark;
  faPenToSquare = faPenToSquare;
  faArrowLeft = faArrowLeft;

  constructor(
    private route: ActivatedRoute,
    private processedSequenceItemHelper: ProcessedSequenceItemHelperService,
    public auth: AuthService,
  ) {
    this.id = this.route.snapshot.params['id'];
    this.processedSequenceItem$ = this.processedSequenceItemHelper.getById(this.id);
  }
}
