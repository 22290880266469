

<div class="overflow-hidden bg-white shadow sm:rounded-lg mb-4">
    <div class="border-t border-gray-100">
        <form [formGroup]="webhookForm" (input)="onChange()" (change)="onChange()">
            <div class="divide-y divide-gray-100">
                <div>
                    <app-input-field
                        controlName="url"
                        [form]="webhookForm"
                        label="URL"
                        [errorMessages]="{ required: 'URL is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="sign_secret"
                        [form]="webhookForm"
                        label="Sign Secret"
                        [errorMessages]="{ 
                            required: 'Sign Secret is required',
                            minlength: '32 character minimum',
                            maxlength: '64 character maximum'
                        }"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="provider_id"
                        type="select|providers"
                        [form]="webhookForm"
                        label="Provider"
                        [errorMessages]="{ required: 'Provider is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="processed_sequence_items"
                        type="toggle"
                        [form]="webhookForm"
                        label="Send processed sequence items?"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="peak_events"
                        type="toggle"
                        [form]="webhookForm"
                        label="Send peak events?"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="tamper_events"
                        type="toggle"
                        [form]="webhookForm"
                        label="Send tamper events?"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="sequence_events"
                        type="toggle"
                        [form]="webhookForm"
                        label="Send sequence events?"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="sequences"
                        type="toggle"
                        [form]="webhookForm"
                        label="Send sequence updates?"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="devices"
                        type="toggle"
                        [form]="webhookForm"
                        label="Send device updates?"
                    />
                </div>
            </div>
        </form>
    </div>
</div>