import { Component } from '@angular/core';
import { HeadMetaComponent } from '@app/components/ui.component';
import { HelpButtonComponent } from '@app/components/help-button/help-button.component'
import { Device } from '@app/models/device.interface';
import { DeviceHelperService } from '@app/services/helpers/device-helper.service';
import { LetDirective } from '@ngrx/component';
import { Observable } from 'rxjs';
import { LoadingErrorBlockComponent } from "@app/components/loading-error-block/loading-error-block.component";
import { InputFieldComponent } from '@app/components/input-field/input-field.component';
import { DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AuthService } from '@app/services/auth.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { 
  faCirclePlus,
  faArrowLeft, 
  faSquareXmark,
  faSquareCheck
 } from '@fortawesome/free-solid-svg-icons';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Provider } from '@app/models/provider.interface';
import { ProviderHelperService } from '@app/services/helpers/provider-helper.service';
import { DeviceStatus } from '@app/models/device-status.interface';
import { DeviceStatusHelperService } from '@app/services/helpers/device-status-helper.service';
@Component({
    selector: 'app-devices',
    standalone: true,
    templateUrl: './listing.page.html',
    styleUrl: './listing.page.scss',
    imports: [
      HeadMetaComponent,
      LetDirective,
      LoadingErrorBlockComponent,
      DatePipe,
      FontAwesomeModule,
      RouterModule,
      ReactiveFormsModule,
      InputFieldComponent,
      HelpButtonComponent,
    ]
})
export class DevicesPage {
  public title = 'Devices';
  searchForm: FormGroup = new FormGroup({
    serial_number: new FormControl<string>(''),
    provider_id: new FormControl<number>(0),
    device_status_id: new FormControl<number>(0),
  });
  devices$: Observable<Device[]>;
  providers$: Observable<Provider[]>;
  deviceStatuses$: Observable<DeviceStatus[]>;
  
  faCirclePlus = faCirclePlus;
  faArrowLeft = faArrowLeft;
  faSquareXmark = faSquareXmark;
  faSquareCheck = faSquareCheck;

  constructor(
    private deviceHelper: DeviceHelperService,
    private providerHelper: ProviderHelperService,
    private deviceStatusHelper: DeviceStatusHelperService,
    public auth: AuthService,
  ) {
    this.devices$ = this.deviceHelper.getAll();
    this.providers$ = this.providerHelper.getAll();
    this.deviceStatuses$ = this.deviceStatusHelper.getAll();
  }

  public submit(){
    const form = this.searchForm.value;
    if(form) {
      this.devices$ = this.deviceHelper.search(form.serial_number, form.provider_id, form.device_status_id);
    }
  }

  showProvider(devices: Device[]) {
    return devices.some((x) => x.provider != null);
  }
}
