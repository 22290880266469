import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { Observable, map } from 'rxjs';
import { AuthToken, AuthUser, AuthUserMetadata, AuthVerifyResponse, AuthVerifyResult } from '@app/interfaces/auth-models.interface';
import { jwtDecode, JwtPayload, JwtHeader } from "jwt-decode";
import { LocalStorage } from '@app/services/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private accessToken?:string;
  private redirectUrl?:string;
  private permissions?: string[];

  constructor(
    private router: Router,
    private http: HttpClient,
    private localStorage: LocalStorage,
  ) {
    let storedToken = this.localStorage.getItem('jwt');
    this.accessToken = storedToken ? 'Bearer ' + storedToken : undefined;

    if(this.isLoggedIn()) {
      this.refreshPermissions();
    }
  }

  isLoggedIn(): boolean {
    if(this.accessToken) {
      let token = (jwtDecode<JwtPayload>(this.accessToken));
      if(token.exp) {
        return token.exp > Math.round(Date.now() / 1000);
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  logout(): void {
    this.accessToken = undefined;
    this.localStorage.clear();
    this.navigateToRedirectUrl();
  }
  getAccessToken(): string | undefined {
    return this.isLoggedIn() ? this.accessToken : undefined;
  }
  setTokenFromVerify(response: AuthVerifyResponse) {
    this.setAccessToken(response.access_token);
  }
  setAccessToken(token: string) {
    this.accessToken = 'Bearer ' + token;
    this.localStorage.setItem('jwt', token);
  }
  getUserId(): string | undefined {
    let token = this.getAccessToken();
    if(token) {
      return (jwtDecode<JwtPayload>(token)).sub;
    } else {
      return undefined;
    }
  }
  refreshPage() {
    this.hardLoadUrl(this.router.url);
  }
  navigateToRedirectUrl() {
    console.log("Redirect URL: " + this.redirectUrl)
    if(this.redirectUrl) {
      this.hardLoadUrl(this.redirectUrl)
        .then(() => {
          this.redirectUrl = undefined;
        });
    } else {
      this.hardLoadUrl('');
    }
  }
  private hardLoadUrl(url: string) {
    this.router.routeReuseStrategy.shouldReuseRoute = function() { return false; };
    return this.router.navigateByUrl(url, {onSameUrlNavigation: 'reload'});
  }
  setRedirectUrl(url: string) {
    this.redirectUrl = url;
  }
  setRedirectUrlFragments(urlParts: string[]) {
    this.redirectUrl = urlParts.join('/');
  }

  login(email: string, password: string): Observable<boolean> {
    return this.http.post(environment.authUrl + '/token',{
      email: email,
      password: password
    },{
      observe: "response",
      responseType: "json",
      params: {
        grant_type: "password"
      }
    }).pipe(
    map(resp => {
      if(resp.ok) {
        this.setAccessToken((<AuthToken>resp.body).access_token);
        this.refreshPermissions();
        this.navigateToRedirectUrl();
      }
      return resp.ok;
    }));
  }

  signup(email: string, password: string, data: any): Observable<boolean> {
    return this.http.post(environment.authUrl + '/signup',{
      email: email,
      password: password,
      data: data
    },{
      observe: "response",
      responseType: "json"
    }).pipe(
    map(resp => {
      return resp.ok;
    }));
  }

  resendConfirmationEmail(email: string): Observable<boolean> {
    // https://github.com/supabase/gotrue/commit/a50b5a711b9c1df902a85edc71cc10314dcf8100
    return this.http.post(environment.authUrl + '/resend',{
      email: email,
      type: "signup"
    },{
      observe: "response",
      responseType: "json"
    }).pipe(
    map(resp => {
      return resp.ok;
    }));
  }

  recoverPassword(email: string): Observable<boolean> {
    return this.http.post(environment.authUrl + '/recover',{
      email: email,
    },{
      observe: "response",
      responseType: "json"
    }).pipe(
    map(resp => {
      return resp.ok;
    }));    
  }

  updatePassword(password: string) {
    return this.updateUser({ password: password });
  }
  updateAuthData(email: string, data: AuthUserMetadata) {
    return this.updateUser({ 
      email: email,
      data: data 
    });
  }

  updateUser(args: any): Observable<boolean> {
    return this.http.put(environment.authUrl + '/user', args,{
      observe: "response",
      responseType: "json",
      headers: {
        'Authorization': this.accessToken ?? '',
      }
    })
    .pipe(
    map(resp => {
      return resp.ok;
    }));  
  }

  getUser() {
    return this.http.get<AuthUser>(environment.authUrl + '/user', {
      headers: {
        'Authorization': this.accessToken ?? '',
      }
    })
  }

  refreshPermissions() {
    this.http.get(environment.apiUrl + '/rpc/my_permissions', {
      headers: {
        'Authorization': this.accessToken ?? '',
      }
    }).subscribe({
      next: (permissions) => {this.permissions = <string[]>permissions;}
    })
  }
  has(permission: string): boolean {
    if(this.permissions) {
      return this.permissions?.includes(permission);
    } else {
      return false;
    }
  }

  checkAuthCallback(url: string): AuthVerifyResult | undefined {
    if(url.includes('#')) {
      let params = url.split('#')[1].split('&');
      if (url.includes('access_token') && url.includes('refresh_token') && url.includes('expires_in')) {
        let ret:AuthVerifyResponse = {
          access_token: this.findParam(params, 'access_token'),
          refresh_token: this.findParam(params, 'refresh_token'),
          expires_in: Number(this.findParam(params, 'expires_in')),
          type: this.findParam(params, 'type'),
          token_type: this.findParam(params, 'token_type'),
        };
        this.setTokenFromVerify(ret);

        return { success: true, message: 'Successfully logged in.' };
      } else if (url.includes('message')) {
        return { success: true, message: decodeURIComponent(this.findParam(params, 'message').replace(/\+/g, '%20')) };
      } else if (url.includes('error')) {
        return { success: false, message: decodeURIComponent(this.findParam(params, 'error_description').replace(/\+/g, '%20')) };
      }
    }
    return;
  }
  private findParam(list: string[], param: string) {
    return list.find(x => x.startsWith(param))?.split('=')[1] ?? '';
  }
}
