import { Injectable } from '@angular/core';
import { SequenceDefaultParams as DefaultParams, SequenceDetailParams as DetailParams } from '@app/models/sequence-default-params.class';
import { Sequence, SequenceCreate } from '@app/models/sequence.interface';
import { RpcEndSequenceService, SequencesService } from '@lib/core';
import { Observable, map } from 'rxjs';
import { PostgrestQueryHelper } from './postgrest-query-helper.service';

@Injectable({
  providedIn: 'root'
})
export class SequenceHelperService {

  constructor(
    private service: SequencesService,
    private pg: PostgrestQueryHelper,
    private endSequence: RpcEndSequenceService,
  ) { }

  getAll(): Observable<Sequence[]> {
    let params = new DefaultParams();
    return this.service.sequencesGet(params)
      .pipe(map(x => <Sequence[]>x));
  }
  search(sequence_id: number | null, provider_id: number | null, startDate: Date | null, endDate: Date | null): Observable<Sequence[]> {
    const start = startDate?.toISOString();
    const end = endDate?.toISOString();
    var params = this.pg.defaultParams();
    if(sequence_id != null){
      if (!isNaN(sequence_id)) {
        params = this.pg.addToHttpParams(params, 'eq.'+sequence_id, 'id');
      }
    }
    if(provider_id != null){
      if(provider_id != 0){
        params = this.pg.addToHttpParams(params, 'eq.'+provider_id, 'provider_id');
      }
    }
    params = this.pg.addToHttpParams(params, '*,\
    device:devices(*),\
    sequence_events(*,event:events(*)),\
    peak_events(*,event:events(*)),\
    tamper_events(*,event:events(*),sensor_type:sensor_types(*)),\
    provider:providers(*)', 'select');
    if(startDate != null && endDate != null) {
      params = this.pg.addToHttpParams(params, '(last_activity_at.gte.'+start+',last_activity_at.lte.'+end+')', 'and');
    }
    params = this.pg.addToHttpParams(params, 'last_activity_at.desc', 'order');
    return this.pg.get<Array<Sequence>>('sequences', params)
      .pipe(map(x => <Sequence[]>x));
  }
  getById(id: number): Observable<Sequence> {
    let params = <any>new DetailParams();
    params.id = 'eq.' + id;
    return this.service.sequencesGet(params)
      .pipe(map(x => <Sequence>x[0]));
  }
  getByIdFiltered(id: number, startDate: Date | null, endDate: Date | null) {
    const start = startDate?.toISOString();
    const end = endDate?.toISOString();
    var params = this.pg.defaultParams();
    params = this.pg.addToHttpParams(params, 'eq.'+id, 'id');
    params = this.pg.addToHttpParams(params, 1, 'limit');
    params = this.pg.addToHttpParams(params, '*,\
      device:devices(*),\
      peak_events!left(*,event:events(*)),\
      sequence_events!left(*,event:events(*)),\
      tamper_events!left(*,event:events(*),sensor_type:sensor_types(*)),\
      raw_sequence_items!left(*),\
      processed_sequence_items!left(*),\
      provider:providers(*)', 'select');
    if(startDate != null && endDate != null) {
      params = this.pg.addToHttpParams(params, '(started_at.lte.'+end+',or(ended_at.is.null,ended_at.gte.'+start+'))', 'peak_events.and');
      params = this.pg.addToHttpParams(params, '(started_at.lte.'+end+',or(ended_at.is.null,ended_at.gte.'+start+'))', 'tamper_events.and');
      params = this.pg.addToHttpParams(params, '(activity_at.gte.'+start+',activity_at.lte.'+end+')', 'raw_sequence_items.and');
      params = this.pg.addToHttpParams(params, '(activity_at.gte.'+start+',activity_at.lte.'+end+')', 'processed_sequence_items.and');
      params = this.pg.addToHttpParams(params, '(created_at.gte.'+start+',created_at.lte.'+end+')', 'sequence_events.and');
    }

    return this.pg.get<Array<Sequence>>('sequences', params)
      .pipe(map(x => <Sequence>x[0]));
  }
  create(input: SequenceCreate) {
    return this.service.sequencesPost({
      sequences: <any>input,
      prefer: "return=representation"
    }, 'response')
    .pipe(map(response => response.body[0]))
    .pipe(map(body => {
      //console.log(body)
      return (<Sequence>body).id;
    }));
  }
  update(id: number, input: SequenceCreate) {
    return this.service.sequencesPatch({
      id: 'eq.'+id,
      sequences: <any>input
    }, 'response')
    .pipe(map(response => response.ok));
  }
  end(id: number) {
    return this.endSequence.rpcEndSequencePost({
      args: {
        sequence_id_in: id,
      },
    })
    .pipe(map((x) => null));
  }
}
