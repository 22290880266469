<head-meta [title]="this.title"></head-meta>
<app-help-button src="/assets/help/events/listing.md" />
<article>
    <div class="card w-full bg-base-100 shadow-xl">
        <div class="card-body">
            <div class="navbar bg-base-100">
                <div class="flex-none">
                </div>
                <div class="flex-1">
                  <h1 class="h1">{{this.title}}</h1>
                </div>
                <div class="flex-none">
                    @if(this.auth.has('event_create')) {
                        <button routerLink="/events/create" routerLinkActive="active" class="btn btn-ghost text-primary"><fa-icon [icon]="faCirclePlus" class="w-5"></fa-icon> Add</button>
                    }
                </div>
            </div>
            <hr class="hr" />
            <table class="table table-zebra table-pin-rows">
                <ng-container *ngrxLet="events$ as events; error as e; complete as c">
                    <app-loading-error-block [error]="e?.error" [complete]="c" />
                    @if(c) {
                        <thead>
                            <th>Name</th>
                            <th>Id</th>
                        </thead>
                        <tbody>
                            @if(events.length > 0) {
                                @for (event of events; track $index) {
                                    <tr [routerLink]="'/events/' + event.id" class="cursor-pointer">
                                        <td>{{ event.name }}</td>
                                        <td>{{ event.id }}</td>
                                    </tr>
                                }
                            } @else {
                                <tr>
                                    <td class="text-center" colspan="2">
                                        <span class="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center">
                                            <span class="mt-2 block text-sm text-gray-400">No Events</span>
                                        </span>
                                    </td>
                                </tr>
                            }
                        </tbody>
                    }
                </ng-container>
            </table>
        </div>
    </div>
</article>