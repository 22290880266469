import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LoadingErrorBlockComponent } from '@app/components/loading-error-block/loading-error-block.component';
import { HeadMetaComponent } from '@app/components/ui.component';
import { ProcessedSequenceItem } from '@app/models/processed-sequence-item.interface';
import { AuthService } from '@app/services/auth.service';
import { ProcessedSequenceItemHelperService } from '@app/services/helpers/processed-sequence-item-helper.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { 
  faCirclePlus,
  faArrowLeft 
 } from '@fortawesome/free-solid-svg-icons';
import { LetDirective } from '@ngrx/component';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-processed-sequence-items',
  standalone: true,
  imports: [
    HeadMetaComponent,
    LetDirective,
    LoadingErrorBlockComponent,
    DatePipe,
    FontAwesomeModule,
    RouterModule,
  ],
  templateUrl: './listing.page.html',
  styleUrl: './listing.page.scss'
})
export class ProcessedSequenceItemsPage {
  public title = 'Processed Sequence Items';
  processedSequenceItems$: Observable<ProcessedSequenceItem[]>;

  faCirclePlus = faCirclePlus;
  faArrowLeft = faArrowLeft;

  constructor(
    private processedSequenceItemHelper: ProcessedSequenceItemHelperService,
    public auth: AuthService,
  ) {
    this.processedSequenceItems$ = this.processedSequenceItemHelper.getAll();
  }
}
