import { DatePipe } from '@angular/common';
import { Component, input } from '@angular/core';

@Component({
  selector: 'app-detail-date-display',
  standalone: true,
  imports: [
    DatePipe
  ],
  templateUrl: './detail-date-display.component.html',
  styleUrl: './detail-date-display.component.scss'
})
export class DetailDateDisplayComponent {
  title = input('title');
  value = input('value');

  public getRelativeTimeString(
    date: Date | number | string | undefined,
    lang = navigator.language
  ): string {
    if(date == undefined) return '';
    // Allow dates or times to be passed

    const timeMs = typeof date === "number" ? date : 
      typeof date === "string" ? (new Date(date)).getTime() :
      date.getTime();
  
    // Get the amount of seconds between the given date and now
    const deltaSeconds = Math.round((timeMs - Date.now()) / 1000);
  
    // Array reprsenting one minute, hour, day, week, month, etc in seconds
    const cutoffs = [60, 3600, 86400, 86400 * 7, 86400 * 30, 86400 * 365, Infinity];
  
    // Array equivalent to the above but in the string representation of the units
    const units: Intl.RelativeTimeFormatUnit[] = ["second", "minute", "hour", "day", "week", "month", "year"];

    if(Math.abs(deltaSeconds) < 60) return "moments ago";
  
    // Grab the ideal cutoff unit
    const unitIndex = cutoffs.findIndex(cutoff => cutoff > Math.abs(deltaSeconds));
  
    // Get the divisor to divide from the seconds. E.g. if our unit is "day" our divisor
    // is one day in seconds, so we can divide our seconds by this to get the # of days
    const divisor = unitIndex ? cutoffs[unitIndex - 1] : 1;
  
    // Intl.RelativeTimeFormat do its magic
    const rtf = new Intl.RelativeTimeFormat(lang, { numeric: "auto" });
    return rtf.format(Math.floor(deltaSeconds / divisor), units[unitIndex]);
  }
}
