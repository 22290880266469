import { Component, OnInit } from '@angular/core';
import { HeadMetaComponent } from '@app/components/ui.component';
import { ApiVersionComponent } from '@app/components/api-version/api-version.component';
import  SwaggerUI from 'swagger-ui';
import { environment } from '@env/environment';
import { AuthService } from '@app/services/auth.service';

@Component({
  selector: 'app-docs-v1',
  standalone: true,
  imports: [
    HeadMetaComponent,
    ApiVersionComponent,
  ],
  templateUrl: './page.page.html',
  styleUrl: './page.page.scss'
})
export class DocsV1Page implements OnInit {
  public apiUrl = '';
  public authUrl = '';
  public utilityUrl = '';

  public now = new Date();
  public nowNext = new Date();

  constructor(
    public auth: AuthService
  ){
    
  }
  
  ngOnInit(): void {
    this.apiUrl = environment.apiUrl;
    this.authUrl = environment.authUrl;
    this.utilityUrl = environment.utilityUrl;
    this.swaggerInit();
    this.now.setHours(this.now.getHours() + 6);
    this.nowNext.setHours(this.nowNext.getHours() + 12);
  }

  private swaggerInit(){
    SwaggerUI({
      url: environment.apiUrl,
      domNode: document.getElementById('swagger-ui'),
      deepLinking: false,
    });
  }
}
