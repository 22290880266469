

<div class="overflow-hidden bg-white shadow sm:rounded-lg mb-4">
    <div class="border-t border-gray-100">
        <form [formGroup]="sequenceEventForm" (input)="onChange()" (change)="onChange()">
            <div class="divide-y divide-gray-100">
                <div>
                    <app-input-field
                        controlName="sequence_id"
                        type="select|sequences"
                        [form]="sequenceEventForm"
                        label="Sequence"
                        [errorMessages]="{ required: 'Sequence is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="event_id"
                        type="select|events"
                        [form]="sequenceEventForm"
                        label="Event"
                        [errorMessages]="{ required: 'Event is required'}"
                    />
                </div>
            </div>
        </form>
    </div>
</div>