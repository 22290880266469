import { Injectable } from '@angular/core';
import { DeviceDefaultParams as DefaultParams } from '@app/models/device-default-params.class';
import { Device, DeviceCreate } from '@app/models/device.interface';
import { DevicesService } from '@lib/core';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DeviceHelperService {

  constructor(
    private service: DevicesService,
  ) { }

  getAll(): Observable<Device[]> {
    let params = new DefaultParams();
    // params.id = 'eq.' + id;
    return this.service.devicesGet(params)
      .pipe(map(x => <Device[]>x));
  }
  search(serial_number: string, provider_id: number, device_status_id: number): Observable<Device[]> {
    let params = new DefaultParams();
    params.serialNumber = 'like.' + serial_number +'*';
    if(provider_id != 0){
      params.providerId = 'eq.' + provider_id;
    }
    if(device_status_id != 0){
      params.deviceStatusId = 'eq.' + device_status_id;
    }
    return this.service.devicesGet(params)
      .pipe(map(x => <Device[]>x));
  }
  getById(id: number): Observable<Device> {
    let params = <any>new DefaultParams();
    params.id = 'eq.' + id;
    return this.service.devicesGet(params)
      .pipe(map(x => <Device>x[0]));
  }
  create(input: DeviceCreate) {
    return this.service.devicesPost({
      devices: <any>input,
      prefer: "return=representation"
    }, 'response')
    .pipe(map(response => response.body[0]))
    .pipe(map(body => {
      //console.log(body)
      return (<Device>body).id;
    }));
  }
  update(id: number, input: DeviceCreate) {
    return this.service.devicesPatch({
      id: 'eq.'+id,
      devices: <any>input
    }, 'response')
    .pipe(map(response => response.ok));
  }
}
