import { Component, ViewChild, ViewChildren, effect, viewChild } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ActivatedRoute, Router, RouterModule, RouterOutlet } from '@angular/router';
import { 
  faBars, 
  faMobileAlt, 
  faHome,
  faBell,
  faBuilding,
  faUsers,
  faUser,
  faCodeMerge,
  faCodeBranch,
  faCodeFork,
  faRing,
  faHourglass,
  faCog,
  faCheck,
  faCheckDouble,
  faCalendarCheck,
  faUserSecret,
  faChartSimple,
  faCircleExclamation,
  faLayerGroup,
  faSliders,
  faSignOut,
  faCircleCheck,
  faSquarePollHorizontal,
  faFileCode,
} from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '@app/services/auth.service';
import { timer } from 'rxjs';
import { AuthVerifyResponse } from '@app/interfaces/auth-models.interface';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ToastComponent } from '@app/components/toast/toast.component';
import { ToastService } from '@app/services/toast.service';
import { VersionService } from '@app/services/version.service';

@Component({
    selector: 'app-nav',
    standalone: true,
    templateUrl: './nav.component.html',
    styleUrl: './nav.component.scss',
    imports: [
        RouterOutlet,
        RouterModule,
        FontAwesomeModule,
        ReactiveFormsModule,
        CommonModule,
        ToastComponent
    ]
})
export class NavComponent {
  faBars = faBars;
  faHome = faHome;
  faMobileAlt = faMobileAlt;
  faBell = faBell;
  faBuilding = faBuilding;
  faUsers = faUsers;
  faUser = faUser;
  faCodeMerge = faCodeMerge;
  faCodeBranch = faCodeBranch;
  faCodeFork = faCodeFork;
  faRing = faRing;
  faHourglass = faHourglass;
  faCog = faCog;
  faCheck = faCheck;
  faCheckDouble = faCheckDouble;
  faCalendarCheck = faCalendarCheck;
  faUserSecret = faUserSecret;
  faChartSimple = faChartSimple;
  faCircleExclamation = faCircleExclamation;
  faLayerGroup = faLayerGroup;
  faSliders = faSliders;
  faSignOut = faSignOut;
  faCircleCheck = faCircleCheck;
  faSquarePollHorizontal = faSquarePollHorizontal;
  faFileCode = faFileCode;

  messageToast = viewChild<ToastComponent>('messageToast');
  public version:string = '';

  constructor(
    public auth: AuthService,
    private route: ActivatedRoute,
    private toastService: ToastService,
    private versionService: VersionService,
  ) {
    this.version = this.versionService.getVersion();
    this.route.queryParams.subscribe(p => {
      if(p['message']) {
        this.toastService.message({ type: p['messageType'] ?? 'success', message: p['message']});
      }
    });
    effect(() => {
      this.toastService.init(this.messageToast());
    })
  }

  ngAfterViewInit() {
  }

  getUserId(){
    return this.auth.getUserId();
  }

  logout() {
    this.auth.setRedirectUrl('/login');
    this.auth.logout();
  }
}
