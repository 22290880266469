import { Component, signal } from '@angular/core';
import { ProcessedSequenceItemFormComponent } from "@app/components/forms/processed-sequence-item/form.component";
import { ProcessedSequenceItemHelperService } from '@app/services/helpers/processed-sequence-item-helper.service';
import { Router, RouterModule } from '@angular/router';
import { AppHttpError } from '@app/interfaces/pg-models.interface';
import { ProcessedSequenceItemCreate } from '@app/models/processed-sequence-item.interface';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { HeadMetaComponent } from '@app/components/ui.component';
import { ToastService } from '@app/services/toast.service';

@Component({
  selector: 'app-processed-sequence-item-create',
  standalone: true,
  templateUrl: './create.page.html',
  styleUrl: './create.page.scss',
  imports: [
    HeadMetaComponent,
    ProcessedSequenceItemFormComponent,
    RouterModule,
    FontAwesomeModule,
  ]
})
export class ProcessedSequenceItemCreatePage {
  public title = 'New Processed Sequence Item';
  formValid = signal(false);
  formValue = signal<ProcessedSequenceItemCreate | null>(null);

  faArrowLeft = faArrowLeft;

  constructor(
    private processedSequenceItemService: ProcessedSequenceItemHelperService,
    private router: Router,
    private toast: ToastService,
  ) {
    
  }

  create() {
    let val = this.formValue();
    if(val) {
      this.processedSequenceItemService.create(val)
        .subscribe({
          next: id => {
            console.log(id)
            if(id) {
              this.router.navigateByUrl('/processed-sequence-items/' + id);
            }
          },
          error: (err: AppHttpError) => {
            console.log(err.error)
            this.toast.message({ message: err.error.code + ": " + err.error.message, type: 'error'});
          }
        });
    }
  }
}
