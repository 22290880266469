

<div class="overflow-hidden bg-white shadow sm:rounded-lg mb-4">
    <div class="border-t border-gray-100">
        <form [formGroup]="passwordForm" (input)="onChange()" (change)="onChange()">
            <div class="divide-y divide-gray-100">
                <div>
                    <app-input-field
                        type="password"
                        controlName="password"
                        [form]="passwordForm"
                        label="Password"
                        [errorMessages]="{ required: 'Password is required', minlength: 'Password must be at least 6 characters' }"
                    />
                    <app-input-field
                        type="password"
                        controlName="passwordConfirm"
                        [form]="passwordForm"
                        label="Confirm Password"
                        [errorMessages]="{ required: 'Confirmation is required', notSame: 'Passwords must match' }"
                    />
                </div>
            </div>
        </form>
    </div>
</div>