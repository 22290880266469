import { Component, input } from '@angular/core';

@Component({
  selector: 'app-detail-display',
  standalone: true,
  templateUrl: './detail-display.component.html',
  styleUrl: './detail-display.component.scss'
})
export class DetailDisplayComponent {
  title = input('title');
  value = input('value');
}
