

<div class="overflow-hidden bg-white shadow sm:rounded-lg mb-4">
    <div class="border-t border-gray-100">
        <form [formGroup]="rawSequenceItemForm" (input)="onChange()" (change)="onChange()">
            <div class="divide-y divide-gray-100">
                <div>
                    <app-input-field
                        controlName="sequence_id"
                        type="select|sequences"
                        [form]="rawSequenceItemForm"
                        label="Sequence"
                        [errorMessages]="{ required: 'Sequence is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="activity_at"
                        [form]="rawSequenceItemForm"
                        type="datetime-local"
                        label="Activity at (UTC)"
                        [errorMessages]="{ required: 'Activity at is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="time_of_reading"
                        [form]="rawSequenceItemForm"
                        type="datetime-local"
                        label="Time of reading (UTC)"
                        [errorMessages]="{ required: 'time of reading at is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="readable_timestamp"
                        [form]="rawSequenceItemForm"
                        type="datetime-local"
                        label="Readable timestamp (UTC)"
                        [errorMessages]="{ required: 'Readable timestamp at is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="flash_timestamp"
                        [form]="rawSequenceItemForm"
                        type="datetime-local"
                        label="Flash timestamp (UTC)"
                        [errorMessages]="{ required: 'Flash timestamp at is required'}"
                    />
                </div>
                <!--
                <div>
                    <app-input-field
                        controlName="delivered_at"
                        [form]="rawSequenceItemForm"
                        type="datetime-local"
                        label="Delivered at (UTC)"
                        [errorMessages]="{ required: 'Delivered at is required'}"
                    />
                </div>
                -->
                <div>
                    <app-input-field
                        controlName="vapor_concentration"
                        [form]="rawSequenceItemForm"
                        type="number"
                        step="any"
                        label="Vapor concentration"
                        [errorMessages]="{ required: 'Vapor concentration is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="temperature_1"
                        [form]="rawSequenceItemForm"
                        type="number"
                        step="any"
                        label="Temperature 1"
                        [errorMessages]="{ required: 'Temperature 1 is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="temperature_2"
                        [form]="rawSequenceItemForm"
                        type="number"
                        step="any"
                        label="Temperature 2"
                        [errorMessages]="{ required: 'Temperature 2 is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="relative_humidity_1"
                        [form]="rawSequenceItemForm"
                        type="number"
                        step="any"
                        label="Relative humidity 1"
                        [errorMessages]="{ required: 'Relative humidity 1 is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="relative_humidity_2"
                        [form]="rawSequenceItemForm"
                        type="number"
                        step="any"
                        label="Relative humidity 2"
                        [errorMessages]="{ required: 'Relative humidity 2 is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="ambient_raw_1"
                        [form]="rawSequenceItemForm"
                        type="number"
                        step="any"
                        label="Ambient raw 1"
                        [errorMessages]="{ required: 'Ambient raw 1 is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="ambient_raw_2"
                        [form]="rawSequenceItemForm"
                        type="number"
                        step="any"
                        label="Ambient raw 2"
                        [errorMessages]="{ required: 'Ambient raw 2 is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="bio_raw_1"
                        [form]="rawSequenceItemForm"
                        type="number"
                        step="any"
                        label="Bio raw 1"
                        [errorMessages]="{ required: 'Bio raw 1 is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="bio_raw_2"
                        [form]="rawSequenceItemForm"
                        type="number"
                        step="any"
                        label="Bio raw 2"
                        [errorMessages]="{ required: 'Bio raw 2 is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="is_tamper"
                        type="toggle"
                        [form]="rawSequenceItemForm"
                        label="Is tamper?"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="battery_level"
                        [form]="rawSequenceItemForm"
                        type="number"
                        step="any"
                        label="Battery level"
                        [errorMessages]="{ required: 'Battery level is required'}"
                    />
                </div>
                <div>
                    <app-input-field
                        controlName="raw_payload"
                        type="textarea"
                        [form]="rawSequenceItemForm"
                        label="Raw Payload"
                        [errorMessages]="{ required: 'Raw payload is required'}"
                    />
                </div>
            </div>
        </form>
    </div>
</div>