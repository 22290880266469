<head-meta [title]="this.title" />
<article>
    <div class="card w-full bg-base-100 shadow-xl">
        <div class="card-body">
            <ng-container *ngrxLet="peakEvent$ as peakEvent; error as e; complete as c">
                @if(peakEvent) {
                    <div class="navbar bg-base-100">
                        <div class="flex-none">
                          <a routerLink="/peak-events" routerLinkActive="active" class="btn btn-square btn-ghost text-primary"><fa-icon [icon]="faArrowLeft" class="w-5"></fa-icon></a>
                        </div>
                        <div class="flex-1">
                          <h1 class="h1 ml-4"> Peak Event Detail</h1>
                        </div>
                        <div class="flex-none">
                            @if(this.auth.has('device_status_update')) {
                                <button [routerLink]="'/peak-events/' + this.id + '/edit'" routerLinkActive="active" class="btn btn-ghost text-primary">
                                    <fa-icon [icon]="faPenToSquare" class="w-5"></fa-icon> Edit
                                </button>
                            }
                        </div>
                    </div>
                    <hr class="hr" />
                    <div class="overflow-hidden bg-white shadow sm:rounded-lg">
                        <div class="border-t border-gray-100">
                            <dl class="divide-y divide-gray-100">
                                <div>
                                    <app-detail-display title="Sequence Id" [value]="peakEvent.sequence_id.toString()" />
                                </div>
                                <!--
                                    event_id
                                -->
                                <div>
                                    <app-detail-display title="Event number" [value]="peakEvent.event_number?.toString() ?? ''" />
                                </div>
                                <div>
                                    <app-detail-display title="Started at" [value]="(peakEvent.started_at | date: 'medium') ?? ''" />
                                </div>
                                <div>
                                    @if(peakEvent.ended_at != null){
                                        <app-detail-display title="Ended at" [value]="(peakEvent.ended_at | date: 'medium') ?? ''" />
                                    } @else {
                                        <app-detail-display title="Ended at" value="" />
                                    }
                                </div>
                                <div>
                                    @if(peakEvent.peak_tac_at != null){
                                        <app-detail-display title="Peak TAC at" [value]="(peakEvent.peak_tac_at | date: 'medium') ?? ''" />
                                    } @else {
                                        <app-detail-display title="Peak TAC at" value="" />
                                    }
                                </div>
                                <div>
                                    <app-detail-display title="Peak TACvalue" [value]="peakEvent.peak_tac_value?.toString() ?? ''" />
                                </div>
                                <div>
                                    <app-detail-display title="Peak prominence value" [value]="peakEvent.peak_prominence_value?.toString() ?? ''" />
                                </div>
                                <div>
                                    <app-detail-display title="Duration minutes" [value]="peakEvent.duration_minutes?.toString() ?? ''" />
                                </div>
                                <div>
                                    <app-detail-display title="Hour threshold duration" [value]="peakEvent.hour_threshold_duration?.toString() ?? ''" />
                                </div>
                                <div>
                                    <app-detail-display title="Half hour threshold duration" [value]="peakEvent.half_hour_threshold_duration?.toString() ?? ''" />
                                </div>
                                <div>
                                    <app-detail-display title="Event rise slope" [value]="peakEvent.event_rise_slope?.toString() ?? ''" />
                                </div>
                                <div>
                                    <app-detail-display title="Event fall slope" [value]="peakEvent.event_fall_slope?.toString() ?? ''" />
                                </div>
                                <div>
                                    <app-detail-display title="Area under curve" [value]="peakEvent.area_under_curve?.toString() ?? ''" />
                                </div>
                                <div>
                                    <app-created-updated-display [entity]="peakEvent" />
                                </div>
                            </dl>
                        </div>
                    </div>
                }
                <app-loading-error-block [error]="e?.error" [complete]="c" />
            </ng-container>
        </div>
    </div>
</article>