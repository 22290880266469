import { SequencesGetRequestParams } from "@lib/core";

export interface SequenceDefaultParams extends SequencesGetRequestParams { }
export interface SequenceDetailParams extends SequencesGetRequestParams { }
export interface SequenceBaseParams extends SequencesGetRequestParams { }
export class SequenceDefaultParams {
  constructor() {
    this.select = "*,\
    device:devices(*),\
    provider:providers(*)";
    this.order = "id.desc";
  }
}
export class SequenceDetailParams {
  constructor() {
    this.select = "*,\
    device:devices(*),\
    sequence_events(*,event:events(*)),\
    peak_events(*,event:events(*)),\
    tamper_events(*,event:events(*),sensor_type:sensor_types(*)),\
    processed_sequence_items(*),\
    provider:providers(*)";
    this.order = "id.asc";
  }
}
export class SequenceBaseParams {
  constructor() {
    this.select = "*,\
    device:devices(*),\
    provider:providers(*)";
    this.order = "id.asc";
  }
}