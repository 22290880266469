import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LoadingErrorBlockComponent } from '@app/components/loading-error-block/loading-error-block.component';
import { HeadMetaComponent } from '@app/components/ui.component';
import { Version } from '@app/models/version.interface';
import { AuthService } from '@app/services/auth.service';
import { VersionHelperService } from '@app/services/helpers/version-helper.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { 
  faCirclePlus,
  faArrowLeft 
 } from '@fortawesome/free-solid-svg-icons';
import { LetDirective } from '@ngrx/component';
import { Observable } from 'rxjs';
import { HelpButtonComponent } from "@app/components/help-button/help-button.component";

@Component({
  selector: 'app-versions',
  standalone: true,
  imports: [
    HeadMetaComponent,
    LetDirective,
    LoadingErrorBlockComponent,
    DatePipe,
    FontAwesomeModule,
    RouterModule,
    HelpButtonComponent
],
  templateUrl: './listing.page.html',
  styleUrl: './listing.page.scss'
})
export class VersionsPage {
  public title = 'Versions';
  versions$: Observable<Version[]>;

  faCirclePlus = faCirclePlus;
  faArrowLeft = faArrowLeft;

  constructor(
    private versionHelper: VersionHelperService,
    public auth: AuthService,
  ) {
    this.versions$ = this.versionHelper.getAll();
  }
}
