<head-meta [title]="this.title" />
<app-help-button src="/assets/help/sequences/detail.md" />
<article>
    <div class="card w-full bg-base-100 shadow-xl">
        <div class="card-body">
            <ng-container *ngrxLet="sequence$ as sequence; error as e; complete as c">
                @if(sequence) {
                    <div class="navbar bg-base-100">
                        <div class="flex-none">
                          <a routerLink="/sequences" routerLinkActive="active" class="btn btn-square btn-ghost text-primary"><fa-icon [icon]="faArrowLeft" class="w-5"></fa-icon></a>
                        </div>
                        <div class="flex-1">
                          <h1 class="h1 ml-4"><span class="hidden xl:inline">Sequence</span> #{{sequence.id}}</h1>
                          <form [formGroup]="this.searchForm">
                            <div class="form-control hidden lg:flex">
                                <div class="ml-4 input-group flex items-center gap-2 w-full max-w-md">
                                    <select class="input input-bordered input-md " formControlName="date_range" name="date_range">
                                        <option [value]="0">All</option>
                                        <option [value]="1">Past 24 Hours</option>
                                        <option [value]="2">Past 2 Days</option>
                                        <option [value]="3">Past 7 Days</option>
                                        <option [value]="4">Past 30 Days</option>
                                        <option [value]="5">Custom</option>
                                    </select>
                                    @if(this.searchForm.get('date_range')?.value == 5){
                                        <app-date-range
                                            (formValid)="formValid.set($event)"
                                            (formChange)="formValue.set($event)"
                                            [initialValue]="dateRanges"
                                        />
                                    }
                                    <button class="btn btn-square btn-md" [disabled]="!this.searchForm.valid" (click)="this.submit()">
                                        <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="h-6 w-6"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor">
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                          </form>
                        </div>
                        <div class="flex-none">
                            @if(this.auth.has('sequence_end') && sequence.ended_at == null) {
                                <button (click)="endSequence()" class="btn btn-ghost text-primary mr-1 tooltip" data-tip="End Sequence">
                                    <fa-icon [icon]="faCircleStop" class="w-5"></fa-icon> 
                                </button>
                            }
                            @if(
                                this.auth.has('raw_sequence_item_read') || 
                                this.auth.has('processed_sequence_item_read') || 
                                this.auth.has('peak_event_read') || 
                                this.auth.has('tamper_event_read') || 
                                this.auth.has('sequence_event_read') 
                            ) {
                                <div class="dropdown">
                                    <div tabindex="0" role="button" class="btn btn-ghost text-primary mr-1"><fa-icon [icon]="faFileCsv" class="w-5"></fa-icon></div>
                                    <ul tabindex="0" class="dropdown-content menu bg-base-100 rounded-box z-[1] w-52 p-2 shadow">
                                        @if(this.auth.has('raw_sequence_item_read')) {
                                            <li><a (click)="this.exportToCsv(sequenceExportType.RawSequenceItem)">Raw Data</a></li>
                                        }
                                        @if(this.auth.has('processed_sequence_item_read')) {
                                            <li><a (click)="this.exportToCsv(sequenceExportType.ProcessedSequenceItem)">Processed Data</a></li>
                                        }
                                        @if(this.auth.has('peak_event_read')) {
                                            <li><a (click)="this.exportToCsv(sequenceExportType.PeakEvent)">Peak Events</a></li>
                                        }
                                        @if(this.auth.has('tamper_event_read')) {
                                            <li><a (click)="this.exportToCsv(sequenceExportType.TamperEvent)">Tamper Events</a></li>
                                        }
                                        @if(this.auth.has('sequence_event_read')) {
                                            <li><a (click)="this.exportToCsv(sequenceExportType.SequenceEvent)">Sequence Events</a></li>
                                        }
                                    </ul>
                                </div>
                            }
                            @if(this.auth.has('sequence_update')) {
                                <button [routerLink]="'/sequences/' + this.id + '/edit'" routerLinkActive="active" class="btn btn-ghost text-primary">
                                    <fa-icon [icon]="faPenToSquare" class="w-5"></fa-icon> Edit
                                </button>
                            }
                        </div>
                    </div>
                    <hr class="hr" />
                    <canvas baseChart [data]="this.chartData" [type]="'line'"> </canvas>
                    <div class="join join-vertical w-full">
                        <div class="collapse collapse-arrow join-item border-base-300 border">
                            <input type="radio" name="data_tables" checked="checked" />
                            <div class="collapse-title text-xl font-medium">Alcohol Events</div>
                            <div class="collapse-content">
                                <table class="table table-zebra table-pin-rows">
                                    <thead>
                                        <th>Event Type</th>
                                        <th>Started at</th>
                                        <th>Ended at</th>
                                        <th>Peak TaC at</th>
                                        <th>Peak TaC Value</th>
                                    </thead>
                                    <tbody>
                                        @if(sequence.peak_events?.length ?? 0 > 0) {
                                            @for (peak of sequence.peak_events; track $index) {
                                                <tr>
                                                    <td>{{ peak.event?.name }}</td>
                                                    <td>{{ peak.started_at | date: 'short' }}</td>
                                                    <td>{{ peak.ended_at | date: 'short' }}</td>
                                                    <td>{{ peak.peak_tac_at | date: 'short' }}</td>
                                                    <td>{{ peak.peak_tac_value?.toFixed(3) }}</td>
                                                </tr>
                                            }
                                        } @else {
                                            <tr>
                                                <td class="text-center" colspan="6">
                                                    <span class="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center">
                                                        <span class="mt-2 block text-sm text-gray-400">No Alcohol Events</span>
                                                    </span>
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="collapse collapse-arrow join-item border-base-300 border">
                            <input type="radio" name="data_tables" />
                            <div class="collapse-title text-xl font-medium">Tamper Events</div>
                            <div class="collapse-content">
                                <table class="table table-zebra table-pin-rows">
                                    <thead>
                                        <th>Event Type</th>
                                        <th>Sensor Type</th>
                                        <th>Started at</th>
                                        <th>Ended at</th>
                                    </thead>
                                    <tbody>
                                        @if(sequence.peak_events?.length ?? 0 > 0) {
                                            @for (tamper of sequence.tamper_events; track $index) {
                                                <tr>
                                                    <td>{{ tamper.event?.name }}</td>
                                                    <td>{{ tamper.sensor_type?.name }}</td>
                                                    <td>{{ tamper.started_at | date: 'short' }}</td>
                                                    <td>{{ tamper.ended_at | date: 'short' }}</td>
                                                </tr>
                                            }
                                        } @else {
                                            <tr>
                                                <td class="text-center" colspan="6">
                                                    <span class="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center">
                                                        <span class="mt-2 block text-sm text-gray-400">No Tamper Events</span>
                                                    </span>
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="collapse collapse-arrow join-item border-base-300 border">
                            <input type="radio" name="data_tables" />
                            <div class="collapse-title text-xl font-medium">Sequence Events</div>
                            <div class="collapse-content">
                                <table class="table table-zebra table-pin-rows">
                                    <thead>
                                        <th>Event Type</th>
                                        <th>Event Time</th>
                                    </thead>
                                    <tbody>
                                        @if(sequence.sequence_events?.length ?? 0 > 0) {
                                            @for (event of sequence.sequence_events; track $index) {
                                                <tr>
                                                    <td>{{ event.event.name }}</td>
                                                    <td>{{ event.created_at | date: 'short' }}</td>
                                                </tr>
                                            }
                                        } @else {
                                            <tr>
                                                <td class="text-center" colspan="6">
                                                    <span class="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center">
                                                        <span class="mt-2 block text-sm text-gray-400">No Sequence Events</span>
                                                    </span>
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="collapse collapse-arrow join-item border-base-300 border">
                            <input type="radio" name="data_tables" />
                            <div class="collapse-title text-xl font-medium">Details</div>
                            <div class="collapse-content">
                                <div class="overflow-hidden bg-white shadow sm:rounded-lg">
                                    <div class="border-t border-gray-100">
                                        <dl class="divide-y divide-gray-100">
                                            <div>
                                                <app-detail-display title="Provider" [value]="sequence.provider.name" />
                                            </div>
                                            <div>
                                                <app-detail-display title="Device" [value]="sequence.device.serial_number" />
                                            </div>
                                            <div>
                                                <app-detail-display title="Started at" [value]="(sequence.started_at | date: 'medium') ?? ''" />
                                            </div>
                                            <div>
                                                @if(sequence.last_activity_at != null){
                                                    <app-detail-display title="Last activity at" [value]="(sequence.last_activity_at | date: 'medium') ?? ''" />
                                                } @else {
                                                    <app-detail-display title="Last activity at" value="" />
                                                }
                                            </div>
                                            <div>
                                                @if(sequence.ended_at != null){
                                                    <app-detail-display title="Ended at" [value]="(sequence.ended_at | date: 'medium') ?? ''" />
                                                } @else {
                                                    <app-detail-display title="Ended at" value="" />
                                                }
                                            </div>
                                            <div>
                                                <app-created-updated-display [entity]="sequence" />
                                            </div>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <app-loading-error-block [error]="e?.error" [complete]="c" />
            </ng-container>
        </div>
    </div>
</article>