import { HttpClient, HttpContext, HttpHeaders, HttpParameterCodec, HttpParams } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { PostgrestServiceParams } from '@app/interfaces/postgrest-service-params';
import { BASE_PATH, Configuration } from '@lib/core';
import { CustomHttpParameterCodec } from '@lib/core/encoder';

@Injectable({
  providedIn: 'root'
})
export class PostgrestQueryHelper {

  protected basePath = 'http://0.0.0.0:3000';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
    if (configuration) {
        this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
        if (Array.isArray(basePath) && basePath.length > 0) {
            basePath = basePath[0];
        }

        if (typeof basePath !== 'string') {
            basePath = this.basePath;
        }
        this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  public get<T>(table: string, queryParameters: HttpParams) {
    return this.query<T>('get', table, queryParameters, {});
  }

  private query<T>(verb: string, table: string, queryParameters: HttpParams, headerParams: PostgrestServiceParams, requestBody?: any, options?: {httpHeaderAccept?: 'application/json' | 'application/vnd.pgrst.object+json;nulls=stripped' | 'application/vnd.pgrst.object+json' | 'text/csv', context?: HttpContext, transferCache?: boolean}) {
    if(options == undefined || options == null) {
      options = {
        httpHeaderAccept: 'application/json',
        transferCache: false
      }
    }
    const range = headerParams.range;
    const rangeUnit = headerParams.rangeUnit;
    const prefer = headerParams.prefer;
    
    let localVarHeaders = this.defaultHeaders;
    if (range !== undefined && range !== null) {
        localVarHeaders = localVarHeaders.set('Range', String(range));
    }
    if (rangeUnit !== undefined && rangeUnit !== null) {
        localVarHeaders = localVarHeaders.set('Range-Unit', String(rangeUnit));
    }
    if (prefer !== undefined && prefer !== null) {
        localVarHeaders = localVarHeaders.set('Prefer', String(prefer));
    }

    let localVarCredential: string | undefined;
    // authentication (JWT) required
    localVarCredential = this.configuration.lookupCredential('JWT');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
      ];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }


    // to determine the Content-Type header
    const consumes: string[] = [
        'application/json',
        'application/vnd.pgrst.object+json;nulls=stripped',
        'application/vnd.pgrst.object+json',
        'text/csv'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
        localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
        if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
            responseType_ = 'json';
        } else {
            responseType_ = 'blob';
        }
    }

    return this.httpClient.request<T>(verb, `${this.configuration.basePath}/${table}`,
      {
        context: localVarHttpContext,
        body: requestBody,
        params: queryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: 'body',
        transferCache: localVarTransferCache
      }
    );
  }

  public defaultParams() {
    return new HttpParams({encoder: this.encoder});
  }

  // @ts-ignore
  public addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    if (typeof value === "object" && value instanceof Date === false) {
        httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
        httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
        return httpParams;
    }

    if (typeof value === "object") {
        if (Array.isArray(value)) {
            (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
        } else if (value instanceof Date) {
            if (key != null) {
                httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
            } else {
               throw Error("key may not be null if value is Date");
            }
        } else {
            Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                httpParams, value[k], key != null ? `${key}.${k}` : k));
        }
    } else if (key != null) {
        httpParams = httpParams.append(key, value);
    } else {
        throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }
}
